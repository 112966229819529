<template>
  <div class="mt-2 pt-2">
    <div class="row g-4">
      <div class="col-md-4">
        <div class="testimonial-card">
          <div class="testimonial-content">
            <div class="d-flex align-items-center mb-2">
              <img
                src="/media/Testamonial_Josh.webp"
                alt="Josh M."
                class="rounded-circle me-2"
                width="32"
                height="32"
              />
              <div>
                <h6 class="mb-0">Josh M.</h6>
                <small class="text-muted">Recent Graduate</small>
              </div>
            </div>
            <div class="rating text-warning mb-2">★★★★★</div>
            <p class="mb-2">
              This is <span class="highlight">amazing all around</span>! You have done a fantastic
              job! I haven't found a decent cover letter AI yet -
              <span class="highlight">Yours is just perfect</span>. Getting me 80-90% there and
              getting more applications out the door! And the interview prep is just the cherry on
              the sundae.
            </p>
          </div>
        </div>

        <div class="testimonial-card mt-4">
          <div class="testimonial-content">
            <div class="d-flex align-items-center mb-2">
              <img
                src="/media/Testamonial_Alice.webp"
                alt="Alice M."
                class="rounded-circle me-2"
                width="32"
                height="32"
              />
              <div>
                <h6 class="mb-0">Alice M.</h6>
                <small class="text-muted">Product Manager</small>
              </div>
            </div>
            <div class="rating text-warning mb-2">★★★★★</div>
            <p class="mb-2">
              This is my <span class="highlight">secret weapon</span>. These tools were instrumental
              in landing me my first <span class="highlight">six-figure role</span>. Thanks so much!
            </p>
          </div>
        </div>

        <div class="testimonial-card mt-4">
          <div class="testimonial-content">
            <div class="d-flex align-items-center mb-2">
              <img
                src="/media/Testamonial_Michael.webp"
                alt="Michael R."
                class="rounded-circle me-2"
                width="32"
                height="32"
              />
              <div>
                <h6 class="mb-0">Michael R.</h6>
                <small class="text-muted">Recent Graduate</small>
              </div>
            </div>
            <div class="rating text-warning mb-2">★★★★★</div>
            <p class="mb-2">
              <span class="highlight">Three interviews</span> lined up in my first week using
              JobBuddy! 🎯
            </p>
          </div>
        </div>

        <div class="testimonial-card mt-4">
          <div class="testimonial-content">
            <div class="d-flex align-items-center mb-2">
              <img
                src="/media/Testamonial_Ava.webp"
                alt="Ava R."
                class="rounded-circle me-2"
                width="32"
                height="32"
              />
              <div>
                <h6 class="mb-0">Ava R.</h6>
                <small class="text-muted">Software Engineer</small>
              </div>
            </div>
            <div class="rating text-warning mb-2">★★★★★</div>
            <p class="mb-2">
              A friend told me about JobBuddy. I was a bit skeptical at first, but it has
              <span class="highlight">consistently exceeded my expectations</span>.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="testimonial-card">
          <div class="testimonial-content">
            <div class="d-flex align-items-center mb-2">
              <img
                src="/media/Testamonial_James.webp"
                alt="Copy Writer"
                class="rounded-circle me-2"
                width="32"
                height="32"
              />
              <div>
                <h6 class="mb-0">James C.</h6>
                <small class="text-muted">Data Analyst</small>
              </div>
            </div>
            <div class="rating text-warning mb-2">★★★★★</div>
            <p class="mb-2">
              Very impressed with how JobBuddy
              <span class="highlight">tailored my cover letter</span> and gave
              <span class="highlight">important insights</span>. Thank you JobBuddy!
            </p>
          </div>
        </div>

        <div class="testimonial-card mt-4">
          <div class="testimonial-content">
            <div class="d-flex align-items-center mb-2">
              <img
                src="/media/Testamonial_Sophie.webp"
                alt="Sophie L."
                class="rounded-circle me-2"
                width="32"
                height="32"
              />
              <div>
                <h6 class="mb-0">Sophie L.</h6>
                <small class="text-muted">Data Scientist</small>
              </div>
            </div>
            <div class="rating text-warning mb-2">★★★★★</div>
            <p class="mb-2">
              As a busy student, JobBuddy was a
              <span class="highlight">lifesaver</span>, making it way easier to juggle internship
              applications and exams. It's been a <span class="highlight">game-changer</span>.
            </p>
          </div>
        </div>

        <div class="testimonial-card mt-4">
          <div class="testimonial-content">
            <div class="d-flex align-items-center mb-2">
              <img
                src="/media/Testamonial_David.webp"
                alt="David W."
                class="rounded-circle me-2"
                width="32"
                height="32"
              />
              <div>
                <h6 class="mb-0">David W.</h6>
                <small class="text-muted">Tech Lead</small>
              </div>
            </div>
            <div class="rating text-warning mb-2">★★★★★</div>
            <p class="mb-2">
              The <span class="highlight">ATS optimization</span> is spot on. Every application gets
              noticed now.
            </p>
          </div>
        </div>

        <div class="testimonial-card mt-4">
          <div class="testimonial-content">
            <div class="d-flex align-items-center mb-2">
              <img
                src="/media/Testamonial_Abe.webp"
                alt="Abe K."
                class="rounded-circle me-2"
                width="32"
                height="32"
              />
              <div>
                <h6 class="mb-0">Abe K.</h6>
                <small class="text-muted">UX Designer</small>
              </div>
            </div>
            <div class="rating text-warning mb-2">★★★★★</div>
            <p class="mb-2">
              I can't stand writing cover letters, but JobBuddy
              <span class="highlight">made life so much easier</span> for me. I feel confident when
              sending cover letters with JobBuddy.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="testimonial-card">
          <div class="testimonial-content">
            <div class="d-flex align-items-center mb-2">
              <img
                src="/media/Testamonial_Sarah.webp"
                alt="Sarah P."
                class="rounded-circle me-2"
                width="32"
                height="32"
              />
              <div>
                <h6 class="mb-0">Sarah P.</h6>
                <small class="text-muted">Marketing Manager</small>
              </div>
            </div>
            <div class="rating text-warning mb-2">★★★★★</div>
            <p class="mb-2">
              The <span class="highlight">live interview practice</span> is incredible! The AI gave
              me <span class="highlight">so much more confidence</span> in interviews. Helped me
              land my dream job after just a week of practice.
            </p>
          </div>
        </div>

        <div class="testimonial-card mt-4">
          <div class="testimonial-content">
            <div class="d-flex align-items-center mb-2">
              <img
                src="/media/Testamonial_Katarlina.webp"
                alt="Katarlina K."
                class="rounded-circle me-2"
                width="32"
                height="32"
              />
              <div>
                <h6 class="mb-0">Katarlina K.</h6>
                <small class="text-muted">QA Engineer</small>
              </div>
            </div>
            <div class="rating text-warning mb-2">★★★★★</div>
            <p class="mb-2">
              It was amazing I <span class="highlight">got hired within a month</span>! 🌞
            </p>
          </div>
        </div>

        <div class="testimonial-card mt-4">
          <div class="testimonial-content">
            <div class="d-flex align-items-center mb-2">
              <img
                src="/media/Testamonial_Robert.webp"
                alt="Robert T."
                class="rounded-circle me-2"
                width="32"
                height="32"
              />
              <div>
                <h6 class="mb-0">Robert T.</h6>
                <small class="text-muted">Change Manager</small>
              </div>
            </div>
            <div class="rating text-warning mb-2">★★★★★</div>
            <p class="mb-2">
              <span class="highlight">Tailoring my resume</span> for every job application was so
              much more manageable with JobBuddy. Highly recommended.
            </p>
          </div>
        </div>

        <div class="testimonial-card mt-4">
          <div class="testimonial-content">
            <div class="d-flex align-items-center mb-2">
              <img
                src="/media/Testamonial_Emma.webp"
                alt="Emma L."
                class="rounded-circle me-2"
                width="32"
                height="32"
              />
              <div>
                <h6 class="mb-0">Emma L.</h6>
                <small class="text-muted">Career Changer</small>
              </div>
            </div>
            <div class="rating text-warning mb-2">★★★★★</div>
            <p class="mb-2">
              <span class="highlight">Perfect for career transitions</span>! Helped me highlight
              transferable skills I didn't even know I had.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Testimonials",
  props: {
    title: {
      type: String,
      required: false,
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.testimonial-card {
  background: #fff;
  border-radius: 0.75rem;
  padding: 1.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease-in-out;
}

.testimonial-card:hover {
  transform: translateY(-2px);
}

.highlight {
  background: linear-gradient(
    120deg,
    rgba(var(--bs-primary-rgb), 0.1) 0%,
    rgba(var(--bs-primary-rgb), 0.1) 100%
  );
  padding: 0.1rem 0.3rem;
  border-radius: 0.25rem;
  font-weight: 500;
}
</style>
