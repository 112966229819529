<template>
  <nav aria-label="breadcrumb" class="bg-light py-2 px-3 border-bottom">
    <ol class="breadcrumb m-0">
      <li class="breadcrumb-item">
        <router-link :to="`/application/${applicationId}`">{{ applicationName }}</router-link>
      </li>
      <li class="breadcrumb-item active" aria-current="page">{{ currentPage }}</li>
    </ol>
  </nav>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { ApplicationApiService } from "@/services/ApplicationApiService";

const route = useRoute();
const applicationId = ref(route.params.id);
const applicationName = ref("");
const currentPage = ref("Document Generator");

onMounted(async () => {
  try {
    const response = await ApplicationApiService.getApplicationById(applicationId.value as string);
    applicationName.value = response.data.jobTitle || "Application";
  } catch (error) {
    console.error("Error fetching application:", error);
    applicationName.value = "Application";
  }
});
</script>

<style scoped>
.breadcrumb {
  font-size: 0.9rem;
}
</style>
