<template>
  <div class="container-fluid m-0 p-0">
    <BreadcrumbNav class="" />

    <div class="interview-interface">
      <div class="row g-4 m-1">
        <!-- Left Column -->
        <div class="col-md-7">
          <div class="card h-100 visualization-card">
            <div class="card-body d-flex flex-column align-items-center justify-content-center">
              <div v-if="isSessionActive">
                <div class="video-feeds">
                  <div class="video-feed ai-feed">
                    <div class="feed-label">AI Interviewer</div>
                    <div class="placeholder-video">
                      <div class="orb-container">
                        <div
                          class="orb"
                          :class="{ 'orb-speaking': isPlaying, 'orb-idle': !isPlaying }"
                          :style="{ '--glow-intensity': glowIntensity }"
                        >
                          <div class="orb-glow"></div>
                        </div>
                      </div>
                      <div v-if="showStartPrompt" class="floating-prompt">
                        <div class="prompt-content">
                          <i class="bi bi-mic-fill me-2"></i>
                          Say hi to get started
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="video-feed user-feed">
                    <div class="feed-label">You</div>
                    <video ref="videoElement" autoplay playsinline muted></video>
                  </div>
                </div>
              </div>
              <div v-else-if="isInterviewEnded" class="text-center mt-4">
                <div class="text-center">
                  <i class="bi bi-graph-up" style="font-size: 3rem; color: #0d6efd"></i>
                  <h3 class="mb-3">Analyze Your Interview</h3>
                  <p class="text-muted mb-4">
                    Analyze your interview transcript and identify areas for improvement.
                  </p>
                  <button
                    class="btn btn-primary btn-lg action-button"
                    @click="analyzeInterviewTranscript"
                    :disabled="loading"
                  >
                    <span class="button-content">
                      <span v-if="loading">
                        <i class="bi bi-arrow-clockwise rotating me-2"></i>
                        Analyzing Interview...
                      </span>
                      <span v-else>
                        Analyze Interview
                        <i class="bi bi-arrow-right-circle-fill ms-2"></i>
                      </span>
                    </span>
                  </button>
                  <div class="mt-3">
                    <a href="#" class="text-muted small" @click.prevent="startNewSession">
                      <i class="bi bi-arrow-repeat me-1"></i>
                      Start a new interview
                    </a>
                  </div>
                </div>
              </div>
              <div v-else>
                <!-- Show start button when not in session -->
                <div class="text-center">
                  <img
                    src="/media/jobbuddymascot.webp"
                    alt="JobBuddy Mascot"
                    class="mascot mb-4"
                    style="width: 80px"
                  />
                  <h3 class="mb-3">Ready to Practice?</h3>
                  <p class="text-muted mb-4">
                    Practice interviewing for your
                    <span class="highlight">{{ application?.roleTitle ?? "role" }}</span> role at
                    <span class="highlight">{{ application?.companyName ?? "company" }}</span
                    >.
                  </p>
                  <button
                    class="btn btn-primary btn-lg action-button"
                    @click="toggleSession"
                    :disabled="loading"
                  >
                    <span class="button-content">
                      {{ loading ? "Starting Session..." : "Start Interview Practice" }}
                      <i class="bi bi-arrow-right-circle-fill ms-2"></i>
                    </span>
                  </button>
                  <!-- Add link to previous analysis if it exists -->
                  <div v-if="previousAnalysis" class="mt-3">
                    <router-link
                      :to="`/application/${route.params.id}/interview-analysis`"
                      class="text-muted small"
                    >
                      <i class="bi bi-clock-history me-1"></i>
                      View Previous Interview Analysis
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isSessionActive" class="controls mt-4">
              <div class="text-center w-100">
                <div class="timer mb-2 text-muted">{{ formattedTime }}</div>
                <button class="btn btn-danger btn-lg mb-4" @click="endSession">
                  <i class="bi bi-stop-circle me-2"></i>
                  End Interview
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Right Column - Transcript -->
        <div class="col-md-5">
          <div class="card h-100">
            <div class="card-body d-flex flex-column">
              <h3 class="card-title mb-3 text-muted">
                <i class="bi bi-chat-square-text me-2"></i>
                Conversation Transcript
              </h3>
              <div class="chat-container" ref="chatContainer">
                <div class="messages-wrapper">
                  <div
                    v-for="(msg, index) in messages"
                    :key="index"
                    class="message"
                    :class="msg.role"
                  >
                    <div class="message-content">{{ msg.content }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <InterviewTypeModal
        v-if="showInterviewTypeModal"
        :show-modal="showInterviewTypeModal"
        @close="showInterviewTypeModal = false"
        @select="handleInterviewTypeSelect"
      />

      <div
        v-if="showSubscribeModal"
        class="modal fade show d-block"
        tabindex="-1"
        style="background: rgba(0, 0, 0, 0.5)"
      >
        <Subscribe :is-modal="true" @close="showSubscribeModal = false" />
        <button
          type="button"
          class="btn-close position-absolute top-0 end-0 m-4"
          @click="showSubscribeModal = false"
          style="z-index: 1050"
        ></button>
      </div>
    </Teleport>

    <div v-if="analysisResults" class="analysis-results mt-4">
      <div class="analysis-header text-center mb-5">
        <h2 class="display-6 mb-3">Interview Analysis</h2>
        <p class="text-muted lead">Here's a detailed breakdown of your interview performance</p>
      </div>

      <!-- Overall Performance Card -->
      <div class="row g-4 mb-4">
        <div class="col-md-12">
          <div class="card overall-performance-card">
            <div class="card-body">
              <h4 class="card-title mb-4">Overall Performance</h4>
              <div class="row">
                <div class="col-md-8">
                  <v-chart class="chart" :option="overallMetricsChartOption" autoresize />
                </div>
                <div class="col-md-4">
                  <div class="overall-stats">
                    <div class="stat-card">
                      <div class="stat-icon">
                        <i
                          class="bi bi-emoji-smile-fill"
                          :class="
                            getSentimentClass(analysisResults.overallMetrics.overallSentiment)
                          "
                        ></i>
                      </div>
                      <div class="stat-content">
                        <div class="stat-label">Overall Sentiment</div>
                        <div class="stat-value">
                          {{ analysisResults.overallMetrics.overallSentiment }}
                        </div>
                      </div>
                    </div>
                    <div class="stat-card">
                      <div class="stat-icon">
                        <i class="bi bi-chat-dots-fill text-primary"></i>
                      </div>
                      <div class="stat-content">
                        <div class="stat-label">Filler Words Used</div>
                        <div class="stat-value">
                          {{ analysisResults.overallMetrics.fillerWordCount }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Question Analysis Cards -->
      <div class="row g-4">
        <div class="col-md-6" v-for="(question, index) in analysisResults.questions" :key="index">
          <div class="card h-100 question-card">
            <div class="card-body">
              <div class="question-header mb-4">
                <span class="question-number">Q{{ index + 1 }}</span>
                <div
                  class="question-type-badge"
                  :class="getQuestionTypeClass(question.questionAnalysis.type)"
                >
                  {{ question.questionAnalysis.type }}
                </div>
                <div
                  class="complexity-badge"
                  :class="getComplexityClass(question.questionAnalysis.complexity)"
                >
                  {{ question.questionAnalysis.complexity }}
                </div>
              </div>

              <div class="question-content mb-4">
                <p class="question-text">{{ question.questionAnalysis.question }}</p>
                <p class="answer-text mt-2">{{ question.answerAnalysis.answer }}</p>
              </div>

              <div class="metrics-section mb-4">
                <v-chart class="chart" :option="getQuestionChartOption(question)" autoresize />
              </div>

              <div class="feedback-section">
                <div class="feedback-column">
                  <h6 class="feedback-title text-success">
                    <i class="bi bi-check-circle-fill me-2"></i>Strengths
                  </h6>
                  <ul class="feedback-list strengths">
                    <li v-for="(strength, idx) in question.feedback.strengths" :key="idx">
                      {{ strength }}
                    </li>
                  </ul>
                </div>
                <div class="feedback-column">
                  <h6 class="feedback-title text-warning">
                    <i class="bi bi-lightbulb-fill me-2"></i>Areas for Improvement
                  </h6>
                  <ul class="feedback-list improvements">
                    <li v-for="(improvement, idx) in question.feedback.improvements" :key="idx">
                      {{ improvement }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch, nextTick, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import BreadcrumbNav from "@/components/BreadcrumbNav.vue";
import {
  Hume,
  HumeClient,
  convertBlobToBase64,
  convertBase64ToBlob,
  ensureSingleValidAudioTrack,
  getAudioStream,
  getBrowserSupportedMimeType,
  MimeType,
} from "hume";
import { HumeApiService } from "../services/HumeApiService";
import { ApplicationApiService } from "../services/ApplicationApiService";
import Application from "@models/Application";
import User from "@models/User";
import store from "@/store";
import { SubscriptionsApiService } from "../services/SubscriptionsApiService";
import { SubscriptionControlledActions, SubscriptionPlan } from "@models/SystemEnums";
import { toast } from "vue3-toastify";
import Subscribe from "@/views/Subscribe.vue";
import VChart from "vue-echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import InterviewTypeModal from "@/components/InterviewTypeModal.vue";

// Register ECharts components
use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

const router = useRouter();

interface Message {
  role: "user" | "assistant";
  content: string;
}

// Add interface for analysis results
interface AnalysisResults {
  applicationId: string;
  userId: string;
  questions: Array<{
    questionAnalysis: {
      question: string;
      type: string;
      complexity: string;
      keywords: string[];
    };
    answerAnalysis: {
      answer: string;
      wordCount: number;
      clarityScore: number;
      usesSTAR: boolean;
    };
    feedback: {
      strengths: string[];
      improvements: string[];
    };
  }>;
  overallMetrics: {
    totalTalkTime: {
      interviewer: number;
      interviewee: number;
    };
    overallSentiment: string;
    fillerWordCount: number;
  };
}

interface InterviewTypeSelection {
  type: string;
  config: {
    questionTypes: string[];
    focusAreas: string;
  };
}

interface QuestionAnalysis {
  question: string;
  type: "Technical" | "Behavioral" | "General";
}

const route = useRoute();
const messages = ref<Message[]>([]);
const isSessionActive = ref(false);
const loading = ref(false);
const chatContainer = ref<HTMLElement | null>(null);
const socket = ref<ReturnType<typeof HumeClient.prototype.empathicVoice.chat.connect> | null>(null);
const recorder = ref<MediaRecorder | null>(null);
const audioStream = ref<MediaStream | null>(null);
const videoElement = ref<HTMLVideoElement | null>(null);
const audioQueue = ref<Blob[]>([]);
const isPlaying = ref(false);
const currentAudio = ref<HTMLAudioElement | null>(null);
const application = ref<Application | null>(null);
const user = ref<User | null>(null);
const isInterviewEnded = ref(false);
const scrollInterval = ref<number | null>(null);

// Add timer ref
const interviewTime = ref(0);
const timerInterval = ref<number | null>(null);

// Add audio analysis for AI speech
const aiAudioContext = ref<AudioContext | null>(null);
const aiAnalyser = ref<AnalyserNode | null>(null);
const aiDataArray = ref<Uint8Array | null>(null);
const aiAnimationFrame = ref<number | null>(null);
const glowIntensity = ref(0);

// Add a new ref for tracking if we're at the start of an AI response
const isStartingNewResponse = ref(true);
const MIN_AUDIO_CHUNKS = 2; // Minimum number of chunks to buffer before starting playback

// Get supported mime type
const mimeType = (() => {
  const result = getBrowserSupportedMimeType();
  return result.success ? result.mimeType : MimeType.WEBM;
})();

const playAudio = () => {
  if (!audioQueue.value.length || isPlaying.value) return;

  isPlaying.value = true;
  const audioBlob = audioQueue.value.shift();
  if (!audioBlob) return;

  const audioUrl = URL.createObjectURL(audioBlob);
  currentAudio.value = new Audio(audioUrl);

  // Set up audio analysis for AI speech
  if (!aiAudioContext.value) {
    aiAudioContext.value = new AudioContext();
    aiAnalyser.value = aiAudioContext.value.createAnalyser();
    aiAnalyser.value.fftSize = 128;
    aiDataArray.value = new Uint8Array(aiAnalyser.value.frequencyBinCount);
  }

  // Wait for audio to be loaded before connecting and playing
  currentAudio.value.addEventListener("loadeddata", () => {
    const source = aiAudioContext.value!.createMediaElementSource(currentAudio.value!);
    source.connect(aiAnalyser.value!);
    aiAnalyser.value!.connect(aiAudioContext.value!.destination);

    // Start analyzing AI audio
    analyzeAiAudio();

    // Small delay before playing to ensure everything is ready
    setTimeout(() => {
      currentAudio.value!.play();
    }, 100);
  });

  currentAudio.value.onended = () => {
    isPlaying.value = false;
    glowIntensity.value = 0;
    URL.revokeObjectURL(audioUrl); // Clean up the URL
    if (audioQueue.value.length) playAudio();
  };
};

// Add AI audio analysis function
const analyzeAiAudio = () => {
  if (!aiAnalyser.value || !aiDataArray.value || !isPlaying.value) return;

  const updateGlow = () => {
    aiAnalyser.value!.getByteFrequencyData(aiDataArray.value!);

    // Calculate average frequency intensity
    const average =
      Array.from(aiDataArray.value!).reduce((a, b) => a + b, 0) / aiDataArray.value!.length;
    glowIntensity.value = Math.min(1, average / 128); // Normalize to 0-1

    if (isPlaying.value) {
      aiAnimationFrame.value = requestAnimationFrame(updateGlow);
    }
  };

  updateGlow();
};

// Update the stopAudio function to clean up AI audio analysis
const stopAudio = () => {
  if (aiAnimationFrame.value) {
    cancelAnimationFrame(aiAnimationFrame.value);
    aiAnimationFrame.value = null;
  }

  currentAudio.value?.pause();
  currentAudio.value = null;
  isPlaying.value = false;
  glowIntensity.value = 0;
  audioQueue.value = [];
  isStartingNewResponse.value = true; // Reset the flag when audio is stopped
};

const handleWebSocketMessageEvent = async (message: Hume.empathicVoice.SubscribeEvent) => {
  if (!socket.value || socket.value.readyState !== WebSocket.OPEN) {
    console.log("WebSocket is not open, ignoring message");
    return;
  }

  switch (message.type) {
    case "audio_output": {
      const blob = convertBase64ToBlob(message.data, mimeType);
      audioQueue.value.push(blob);
      showStartPrompt.value = false; // Hide prompt when AI responds

      // Only start playing if we have enough chunks or we're already playing
      if (
        !isPlaying.value &&
        (audioQueue.value.length >= MIN_AUDIO_CHUNKS || !isStartingNewResponse.value)
      ) {
        isStartingNewResponse.value = false;
        playAudio();
      }
      break;
    }
    case "user_message": {
      if (message.message.content) {
        messages.value.push({
          role: "user",
          content: message.message.content,
        });
        showStartPrompt.value = false; // Hide prompt when user message is detected
      }
      break;
    }
    case "assistant_message": {
      if (message.message.content) {
        messages.value.push({
          role: "assistant",
          content: message.message.content,
        });
        // Reset the flag when a new assistant message starts
        isStartingNewResponse.value = true;
      }
      break;
    }
    case "user_interruption": {
      stopAudio();
      break;
    }
  }
};

const captureAudio = async () => {
  if (!socket.value || socket.value.readyState !== WebSocket.OPEN) {
    console.error("WebSocket is not open, cannot capture audio");
    toast.error("Connection lost. Please try again.");
    endSession();
    return;
  }

  try {
    // Get both audio and video streams
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        facingMode: "user",
      },
    });

    // Set up video element
    if (videoElement.value) {
      videoElement.value.srcObject = stream;
    }

    // Get audio track for processing
    audioStream.value = new MediaStream(stream.getAudioTracks());
    ensureSingleValidAudioTrack(audioStream.value);

    // Create recorder but don't start recording immediately
    recorder.value = new MediaRecorder(audioStream.value, { mimeType });
    recorder.value.ondataavailable = async ({ data }) => {
      if (data.size < 1 || !socket.value || socket.value.readyState !== WebSocket.OPEN) return;
      const encodedAudioData = await convertBlobToBase64(data);
      const audioInput = {
        data: encodedAudioData,
      };
      socket.value?.sendAudioInput(audioInput);
    };

    // Warm up period - wait a moment before starting to record
    await new Promise((resolve) => setTimeout(resolve, 500));

    // Start recording
    const timeSlice = 100; // 100ms chunks
    recorder.value.start(timeSlice);
  } catch (error) {
    console.error("Error accessing media devices:", error);
    toast.error("Failed to access camera or microphone. Please check your permissions.");
    endSession();
  }
};

const endSession = () => {
  if (recorder.value) {
    recorder.value.stop();
    recorder.value = null;
  }
  if (audioStream.value) {
    audioStream.value.getTracks().forEach((track) => track.stop());
    audioStream.value = null;
  }
  if (socket.value) {
    if (socket.value.readyState === WebSocket.OPEN) {
      socket.value.close();
    }
    socket.value = null;
  }
  stopAudio();
  isSessionActive.value = false;
  isInterviewEnded.value = true;

  // Clear all intervals
  if (scrollInterval.value) {
    clearInterval(scrollInterval.value);
    scrollInterval.value = null;
  }
  if (timeCheckInterval.value) {
    clearInterval(timeCheckInterval.value);
    timeCheckInterval.value = null;
  }
  if (timerInterval.value) {
    clearInterval(timerInterval.value);
    timerInterval.value = null;
  }

  // Record the interview time used
  const minutes = Math.ceil(interviewTime.value / 60);
  recordInterviewTime(minutes);
};

const recordInterviewTime = async (minutes: number) => {
  try {
    // TODO: Implement API call to record interview time usage
    await HumeApiService.recordInterviewTime(minutes);
  } catch (error) {
    console.error("Error recording interview time:", error);
  }
};

const startNewSession = () => {
  messages.value = [];
  isInterviewEnded.value = false;
  toggleSession();
};

// Add timer functions
const startTimer = () => {
  interviewTime.value = 0;
  timerInterval.value = window.setInterval(() => {
    interviewTime.value++;
  }, 1000);
};

const stopTimer = () => {
  if (timerInterval.value) {
    clearInterval(timerInterval.value);
    timerInterval.value = null;
  }
};

// Add formatted time computed property
const formattedTime = computed(() => {
  const minutes = Math.floor(interviewTime.value / 60);
  const seconds = interviewTime.value % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
});

// Add new ref for controlling prompt visibility
const showStartPrompt = ref(false);

// Add quota and usage refs
const quota = ref(0);
const usage = ref(0);

// Add these near the top of the script setup, after imports
const showInterviewTypeModal = ref(false);
const selectedInterviewType = ref("comprehensive");
const selectedInterviewConfig = ref(null);

// Update the toggleSession function
const toggleSession = async () => {
  if (!isSessionActive.value) {
    try {
      // Check if user has access to interview practice
      const quotaResponse = await SubscriptionsApiService.getUserQuota(
        SubscriptionControlledActions.INTERVIEW_PRACTICE
      );
      quota.value = quotaResponse.data.quota;
      usage.value = quotaResponse.data.usage;

      if (quota.value === 0 || usage.value >= quota.value) {
        showSubscribeModal.value = true;
        toast.warning("You have reached your interview practice time limit for this month.");
        return;
      }

      // Show interview type selection modal
      showInterviewTypeModal.value = true;
    } catch (error) {
      console.error("Error checking quota:", error);
      toast.error("Failed to check interview quota");
    }
  }
};

// Update the handleInterviewTypeSelect function
const handleInterviewTypeSelect = async ({ type, config }: InterviewTypeSelection) => {
  selectedInterviewType.value = type;
  selectedInterviewConfig.value = config;
  showInterviewTypeModal.value = false;

  // Start the interview session with the selected type
  try {
    loading.value = true;

    const tokenResponse = await HumeApiService.getAccessToken();
    if (!tokenResponse) {
      throw new Error("Failed to get access token");
    }

    const client = new HumeClient({
      accessToken: tokenResponse,
    });

    console.log("Hume config ID:", process.env.VUE_APP_HUME_CONFIG_ID);
    const args: Hume.empathicVoice.chat.Chat.ConnectArgs = {
      configId: process.env.VUE_APP_HUME_CONFIG_ID,
    };

    socket.value = await client.empathicVoice.chat.connect(args);

    socket.value.on("open", async () => {
      console.log("WebSocket connection opened");

      try {
        const variables = {
          interviewee: user.value?.name ?? "Candidate",
          role: application.value?.roleTitle ?? "Software Engineer",
          company_name: application.value?.companyName ?? "Company",
          company_description: application.value?.companyDescription ?? "A company",
          job_description: application.value?.jobDescription ?? "A role",
          interview_type: selectedInterviewType.value,
          interview_config:
            selectedInterviewConfig.value ??
            "A comprehensive interview covering all relevant aspects like technical, behavioral, competency, and cultural questions.",
        };
        console.log("Variables:", variables);
        // Send session settings with interview type
        await socket.value!.sendSessionSettings({
          variables,
        });

        // Add a small delay to ensure settings are processed
        await new Promise((resolve) => setTimeout(resolve, 1500));

        // Now start the session and audio capture
        isSessionActive.value = true;
        startTimer();
        scrollInterval.value = window.setInterval(scrollToBottom, 1000);
        await captureAudio();

        // Show the floating prompt
        showStartPrompt.value = true;
        setTimeout(() => {
          showStartPrompt.value = false;
        }, 5000); // Hide after 5 seconds

        loading.value = false;
      } catch (error) {
        console.error("Error initializing session settings:", error);
        toast.error("Failed to initialize interview settings. Please try again.");
        endSession();
      }
    });

    socket.value.on("message", handleWebSocketMessageEvent);
    socket.value.on("error", (error: Error) => {
      console.error("WebSocket error:", error);
      toast.error("Connection error occurred. Please try again.");
      endSession();
    });
    socket.value.on("close", (event: import("hume").empathicVoice.CloseEvent) => {
      console.log("WebSocket connection closed", event);
      stopAudio();
      isSessionActive.value = false;
      isInterviewEnded.value = true;

      // Clear auto-scroll interval
      if (scrollInterval.value) {
        clearInterval(scrollInterval.value);
        scrollInterval.value = null;
      }

      // Clear time check interval
      if (timeCheckInterval.value) {
        clearInterval(timeCheckInterval.value);
        timeCheckInterval.value = null;
      }

      // Stop timer and record time
      stopTimer();
      const minutes = Math.ceil(interviewTime.value / 60);
      recordInterviewTime(minutes);

      // Clean up audio resources
      if (recorder.value) {
        recorder.value.stop();
        recorder.value = null;
      }
      if (audioStream.value) {
        audioStream.value.getTracks().forEach((track) => track.stop());
        audioStream.value = null;
      }

      // Show toast message for unexpected closures
      if (!isInterviewEnded.value) {
        toast.info("The interview session has ended.");
      }
    });

    // Check remaining time every minute
    timeCheckInterval.value = window.setInterval(async () => {
      const currentQuota = await SubscriptionsApiService.getUserQuota(
        SubscriptionControlledActions.INTERVIEW_PRACTICE
      );
      const remainingMinutes = currentQuota.data.quota - currentQuota.data.usage;

      if (remainingMinutes <= 0) {
        showSubscribeModal.value = true;
        toast.warning("You have reached your interview practice time limit for this month.");
        endSession();
      } else if (remainingMinutes <= 5) {
        toast.info(
          `You have ${remainingMinutes} minutes of interview practice remaining this month.`
        );
      }
    }, 60000); // Check every minute
  } catch (error) {
    console.error("Error connecting to Hume:", error);
    toast.error("Failed to connect to the interview service. Please try again.");
    loading.value = false;
  }
};

const analyzeInterviewTranscript = async () => {
  if (
    user.value.subscriptionPlan === null ||
    user.value.subscriptionPlan === SubscriptionPlan.BASIC
  ) {
    showSubscribeModal.value = true;
    toast.warning(
      "This feature is available for premium users only. Please subscribe to access it."
    );
  } else {
    try {
      loading.value = true;
      // Format the transcript with speaker labels
      const formattedTranscript = messages.value
        .map((msg) => {
          const speaker = msg.role === "assistant" ? "INTERVIEWER" : "INTERVIEWEE";
          return `${speaker}: ${msg.content}`;
        })
        .join("\n\n");

      const applicationId = route.params.id as string;
      const response = await HumeApiService.analyzeInterviewTranscript(
        formattedTranscript,
        applicationId
      );
      if (response.status === 200) {
        // Store analysis in both generic and application-specific storage
        localStorage.setItem("interviewAnalysis", JSON.stringify(response.data));
        const storageKey = `interviewAnalysis_${applicationId}`;
        localStorage.setItem(storageKey, JSON.stringify(response.data));
        // Navigate to analysis view
        router.push(`/application/${applicationId}/interview-analysis`);
      }
    } catch (error) {
      console.error("Error analyzing interview transcript:", error);
      toast.error("Failed to analyze interview transcript. Please try again.");
    } finally {
      loading.value = false;
    }
  }
};

onMounted(async () => {
  if (chatContainer.value) {
    const container = chatContainer.value as HTMLElement;
    container.scrollTop = container.scrollHeight;
  }

  // Load application data
  const applicationId = route.params.id as string;
  try {
    const response = await ApplicationApiService.getApplicationById(applicationId);
    if (response.status === 200) {
      application.value = response.data;
    }
  } catch (error) {
    console.error("Error loading application:", error);
  }
  user.value = await store.getters["user/getLoggedInUser"];
});

onUnmounted(() => {
  if (recorder.value) {
    recorder.value.stop();
  }
  if (audioStream.value) {
    audioStream.value.getTracks().forEach((track) => track.stop());
  }
  if (socket.value) {
    socket.value.close();
  }
  stopAudio();
  if (scrollInterval.value) {
    clearInterval(scrollInterval.value);
    scrollInterval.value = null;
  }
});

// Define scroll function first
const scrollToBottom = () => {
  nextTick(() => {
    if (chatContainer.value) {
      chatContainer.value.scrollTo({
        top: chatContainer.value.scrollHeight,
        behavior: "smooth",
      });
    }
  });
};

// Then use it in the updateTranscript function
const updateTranscript = (newMessage: Message) => {
  messages.value.push(newMessage);
  scrollToBottom();
};

// Now we can use it in watchers
watch(
  [messages, isPlaying],
  () => {
    scrollToBottom();
  },
  { immediate: true }
);

// Add new ref for time check interval
const timeCheckInterval = ref<number | null>(null);

// Add refs
const showSubscribeModal = ref(false);

// Update the ref with the correct type
const analysisResults = ref<AnalysisResults | null>(null);

const getQuestionChartOption = (question: {
  answerAnalysis: { wordCount: number; clarityScore: number };
}) => ({
  tooltip: {
    trigger: "item",
    formatter: "{b}: {c}",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderWidth: 0,
    textStyle: { color: "#333" },
    extraCssText: "box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);",
  },
  legend: {
    orient: "vertical",
    right: "5%",
    top: "center",
    textStyle: { color: "#666" },
  },
  series: [
    {
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: true,
      itemStyle: {
        borderRadius: 10,
        borderColor: "#fff",
        borderWidth: 2,
      },
      label: {
        show: false,
      },
      emphasis: {
        label: {
          show: true,
          fontSize: "14",
          fontWeight: "bold",
        },
      },
      data: [
        {
          value: question.answerAnalysis.wordCount,
          name: "Word Count",
          itemStyle: { color: "#87CEEB" },
        },
        {
          value: question.answerAnalysis.clarityScore,
          name: "Clarity Score",
          itemStyle: { color: "#4682B4" },
        },
      ],
    },
  ],
});

const overallMetricsChartOption = computed(() => ({
  tooltip: {
    trigger: "axis",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderWidth: 0,
    textStyle: { color: "#333" },
    extraCssText: "box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);",
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    data: ["Interviewer Talk Time", "Interviewee Talk Time"],
    axisLine: { lineStyle: { color: "#eee" } },
    axisTick: { show: false },
  },
  yAxis: {
    type: "value",
    splitLine: { lineStyle: { color: "#eee" } },
    axisLine: { show: false },
    axisTick: { show: false },
  },
  series: [
    {
      type: "bar",
      data: [
        {
          value: analysisResults.value?.overallMetrics?.totalTalkTime?.interviewer || 0,
          itemStyle: { color: "#87CEEB" },
        },
        {
          value: analysisResults.value?.overallMetrics?.totalTalkTime?.interviewee || 0,
          itemStyle: { color: "#4682B4" },
        },
      ],
      itemStyle: {
        borderRadius: [4, 4, 0, 0],
      },
    },
  ],
}));

// Add these utility functions
const getSentimentClass = (sentiment: string): string => {
  const validSentiments = ["Positive", "Neutral", "Negative"] as const;
  if (validSentiments.includes(sentiment as any)) {
    return {
      Positive: "text-success",
      Neutral: "text-warning",
      Negative: "text-danger",
    }[sentiment as "Positive" | "Neutral" | "Negative"];
  }
  return "text-primary";
};

const getQuestionTypeClass = (type: string): string => {
  const validTypes = ["Technical", "Behavioral", "General"] as const;
  if (validTypes.includes(type as any)) {
    return {
      Technical: "type-technical",
      Behavioral: "type-behavioral",
      General: "type-general",
    }[type as "Technical" | "Behavioral" | "General"];
  }
  return "type-general";
};

const getComplexityClass = (complexity: string): string => {
  const validComplexities = ["Easy", "Moderate", "Hard"] as const;
  if (validComplexities.includes(complexity as any)) {
    return {
      Easy: "complexity-easy",
      Moderate: "complexity-moderate",
      Hard: "complexity-hard",
    }[complexity as "Easy" | "Moderate" | "Hard"];
  }
  return "complexity-moderate";
};

// Add computed property to check for previous analysis
const previousAnalysis = computed(() => {
  const key = `interviewAnalysis_${route.params.id}`;
  const stored = localStorage.getItem(key);
  return stored ? JSON.parse(stored) : null;
});
</script>

<style scoped>
.interview-interface {
  height: calc(100vh - 48px);
  padding: 1rem;
}

.row {
  height: calc(100vh - 48px - 2rem) !important;
  margin: 0 !important;
}

.visualization-card {
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  padding-bottom: 48px;
}

.highlight {
  color: #0d6efd;
  font-weight: 600;
  font-style: normal;
}

.action-button {
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 12px;
  background: linear-gradient(to right, #0d6efd, #0a58ca);
  border: none;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(13, 110, 253, 0.25);
  background: linear-gradient(to right, #0a58ca, #084298);
}

.action-button:active {
  transform: translateY(0);
}

.button-content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orb-container {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orb {
  width: 120px;
  height: 120px;
  background: linear-gradient(145deg, #0d6efd 0%, #0a58ca 100%);
  border-radius: 50%;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 0 0 40px rgba(13, 110, 253, calc(0.15 + var(--glow-intensity) * 0.35));
  --glow-intensity: 0;
}

.loom-container {
  width: 100%;
  height: 75%;
}
.orb::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 60%);
  z-index: 1;
}

.orb-idle {
  transform: scale(1);
  opacity: 0.9;
}

.orb-speaking {
  transform: scale(1.05);
  opacity: 1;
  box-shadow: 0 0 60px rgba(13, 110, 253, calc(0.4 + var(--glow-intensity) * 0.6));
}

.orb-glow {
  position: absolute;
  top: -30px;
  left: -30px;
  right: -30px;
  bottom: -30px;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    rgba(13, 110, 253, calc(0.2 + var(--glow-intensity) * 0.4)),
    transparent 70%
  );
  opacity: 0;
  filter: blur(calc(20px + var(--glow-intensity) * 10px));
  transition: all 0.1s ease;
}

.orb-speaking .orb-glow {
  opacity: calc(0.6 + var(--glow-intensity) * 0.4);
  transform: scale(calc(1 + var(--glow-intensity) * 0.2));
  background: radial-gradient(
    circle at center,
    rgba(13, 110, 253, calc(0.2 + var(--glow-intensity) * 0.4)),
    transparent 70%
  );
}

.waveform-container {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 1rem;
}

.sound-wave {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  transition: opacity 0.3s ease;
  gap: 2px;
}

.sound-wave.active {
  opacity: 1;
}

.sound-wave .bar {
  background: #0d6efd;
  margin: 0;
  width: 3px;
  height: 2px;
  transition: height 0.05s ease;
}

.chat-container {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  padding: 1.5rem;
  border-radius: 1rem;
  background: #ffffff;
  height: 60vh;
  scroll-behavior: smooth;
}

.messages-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: flex-end;
}

.message {
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;
  border-radius: 1rem;
  max-width: 85%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.message.user {
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  margin-left: auto;
}

.message.assistant {
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  margin-right: auto;
}

.controls {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 20;
}

.status-indicator {
  display: flex;
  align-items: center;
}

.status-indicator .badge {
  font-size: 0.9rem;
  font-weight: 500;
}

.card {
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  overflow: hidden;
}

.card-body {
  padding: 1.5rem;
}

.card-title {
  color: #344767;
  font-size: 1.25rem;
  font-weight: 600;
}

.timer {
  font-size: 0.9rem;
  font-weight: 500;
  color: #6c757d;
  font-family: monospace;
  margin-bottom: 4px;
}

@keyframes pulse-orb {
  0% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(calc(1.08 + var(--glow-intensity) * 0.04));
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes glow-intensity {
  0% {
    opacity: 0.6;
    filter: blur(20px);
  }
  50% {
    opacity: 1;
    filter: blur(25px);
  }
  100% {
    opacity: 0.6;
    filter: blur(20px);
  }
}

.floating-prompt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.95);
  padding: 1rem 2rem;
  border-radius: 2rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  animation: float-in 0.3s ease-out;
  z-index: 100;
}

.prompt-content {
  font-size: 1.2rem;
  color: #333;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@keyframes float-in {
  from {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.modal-mascot {
  width: 80px;
  filter: drop-shadow(0 0 0.75rem rgba(13, 110, 253, 0.4));
}

.feature-list {
  list-style: none;
  padding-left: 0;
}

.feature-list li {
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
}

.video-feeds {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -58%);
  width: min(90%, 520px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 10;
}

.video-feed {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background: #000;
  position: relative;
}

.feed-label {
  position: absolute;
  top: 12px;
  left: 12px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 0.9rem;
  z-index: 2;
}

.video-feed video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scaleX(-1); /* Mirror the video */
}

.placeholder-video {
  width: 100%;
  height: 100%;
  background: linear-gradient(145deg, #1a1a1a 0%, #2a2a2a 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ai-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background: white;
  padding: 8px;
}

.ai-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Adjust button size for smaller screens */
@media (max-height: 800px) {
  .btn-lg {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
  }

  .video-feeds {
    width: min(90%, 460px);
    gap: 6px;
  }

  .controls {
    bottom: 4px;
  }

  .visualization-card {
    padding-bottom: 40px;
  }
}

/* Adjust for smaller screens */
@media (max-height: 800px) {
  .video-feeds {
    transform: translate(-50%, -60%);
  }
}

.chart-container {
  height: 300px;
  width: 100%;
  position: relative;
}

.chart {
  height: 100%;
  width: 100%;
}

:deep(.echarts) {
  width: 100% !important;
  height: 100% !important;
}

.feedback-section {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
}

.feedback-section h6 {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.feedback-section ul {
  margin-bottom: 1rem;
}

.feedback-section li {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: start;
}

.feedback-section i {
  margin-top: 0.25rem;
}

.overall-stats {
  padding: 1rem;
}

.stat-item {
  margin-bottom: 1rem;
  padding: 0.75rem;
  background: #f8f9fa;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.analysis-results {
  padding: 2rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.analysis-header {
  padding: 2rem 0;
}

.display-6 {
  font-weight: 600;
  color: #1e293b;
}

.overall-performance-card {
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.stat-card {
  display: flex;
  align-items: center;
  padding: 1.25rem;
  background: white;
  border-radius: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
}

.stat-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
  padding: 0.75rem;
  background: #f8f9fa;
  border-radius: 0.75rem;
}

.stat-content {
  flex: 1;
}

.stat-label {
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.25rem;
}

.stat-value {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
}

.question-card {
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
}

.question-card:hover {
  transform: translateY(-2px);
}

.question-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.question-number {
  font-size: 1.25rem;
  font-weight: 600;
  color: #0d6efd;
  background: #e6f0ff;
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
}

.question-type-badge,
.complexity-badge {
  padding: 0.35rem 0.75rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.type-technical {
  background: #e6f0ff;
  color: #0d6efd;
}

.type-behavioral {
  background: #f0fdf4;
  color: #16a34a;
}

.type-general {
  background: #f1f5f9;
  color: #64748b;
}

.complexity-easy {
  background: #f0fdf4;
  color: #16a34a;
}

.complexity-moderate {
  background: #fef3c7;
  color: #d97706;
}

.complexity-hard {
  background: #fee2e2;
  color: #dc2626;
}

.question-content {
  padding: 1.25rem;
  background: #f8fafc;
  border-radius: 0.75rem;
  margin-top: 1rem;
}

.question-text {
  font-weight: 500;
  color: #1e293b;
  margin-bottom: 0.75rem;
}

.answer-text {
  color: #64748b;
  font-style: italic;
}

.feedback-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.75rem;
  margin-top: 2rem;
}

.feedback-title {
  font-size: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.feedback-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feedback-list li {
  margin-bottom: 0.75rem;
  padding: 0.75rem;
  background: white;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.strengths li {
  border-left: 3px solid #16a34a;
}

.improvements li {
  border-left: 3px solid #ca8a04;
}

@media (max-width: 768px) {
  .feedback-section {
    grid-template-columns: 1fr;
  }

  .stat-card {
    margin-bottom: 0.75rem;
  }
}

.rotating {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
