<template>
  <div>
    <Modal title="There's been an error!" ref="errorModal">
      <template #body>There was an error processing this request. Please try again later!</template>
    </Modal>
    <Teleport to="body">
      <div
        v-if="showSubscribeModal"
        class="modal fade show d-block"
        tabindex="-1"
        style="background: rgba(0, 0, 0, 0.5)"
      >
        <Subscribe :is-modal="true" @close="showSubscribeModal = false" />
        <button
          type="button"
          class="btn-close position-absolute top-0 end-0 m-4"
          @click="showSubscribeModal = false"
          style="z-index: 1050"
        ></button>
      </div>
    </Teleport>
    <div class="container py-5">
      <div class="card shadow border-0 rounded-4 p-5 mx-auto" style="max-width: 900px">
        <h2 v-if="!showDetails" class="h3 text-center mb-5">Start A New Application</h2>

        <div class="progress-container d-flex justify-content-between align-items-center mb-2">
          <div
            v-for="step in 3"
            :key="step"
            class="progress-step"
            :class="{
              active: applicationPhase >= step,
              completed: applicationPhase > step,
            }"
          >
            <div class="progress-indicator">
              <div class="progress-icon">
                <i v-if="applicationPhase > step" class="bi bi-check-lg"></i>
                <span v-else>{{ step }}</span>
              </div>
              <span class="progress-label">{{ getStepLabel(step) }}</span>
            </div>
            <div v-if="step < 3" class="progress-line" />
          </div>
        </div>

        <form class="mt-4">
          <div v-if="applicationPhase == 1" class="fade-in">
            <div class="url-input-container" v-if="!showDetails">
              <div class="text-center mb-4">
                <p class="text-muted">
                  Paste a URL to the job listing, or enter the details manually
                </p>
              </div>

              <div v-if="!loading" class="url-input-group mb-4">
                <input
                  v-model="jobListingURL"
                  placeholder="Job listing URL"
                  class="form-control form-control-lg"
                />
                <button
                  @click="loadDetailsFromURL"
                  class="btn btn-primary btn-lg text-nowrap"
                  :disabled="loading"
                >
                  <i class="bi bi-arrow-right-circle me-2"></i>
                  <span v-show="!loading">Load from URL</span>
                  <div v-if="loading" class="d-flex align-items-center">
                    <div class="spinner-grow spinner-grow-sm text-light" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </button>
              </div>

              <div v-if="loading" class="text-center mt-4">
                <div class="loading-pulse mb-3"></div>
                <p class="text-muted mb-0">{{ currentLoadingMessage }}</p>
              </div>

              <div class="text-center" v-if="!loading">
                <button type="button" class="btn btn-link text-muted" @click="toggleManualEntry">
                  Enter details manually
                </button>
              </div>
            </div>

            <div v-if="showDetails" class="manual-entry-form">
              <div class="d-flex justify-content-between align-items-center mb-4">
                <h4 class="h5 mb-0">Job Details</h4>
                <button type="button" class="btn btn-link" @click="toggleManualEntry">
                  <i class="bi bi-link-45deg me-1"></i>
                  Load from URL instead
                </button>
              </div>

              <div class="row g-4">
                <div class="col-8">
                  <div class="form-floating">
                    <input
                      v-model="application.roleTitle"
                      class="form-control rounded-3"
                      id="roleTitle"
                      placeholder="Role Title"
                    />
                    <label for="roleTitle">Role Title</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-floating">
                    <input
                      type="date"
                      v-model="application.postedDate"
                      class="form-control rounded-3"
                      id="postedDate"
                      placeholder="Date Posted"
                    />
                    <label for="postedDate">Date Posted</label>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-floating">
                    <input
                      v-model="application.salaryCurrency"
                      class="form-control rounded-3"
                      id="salaryCurrency"
                      placeholder="Currency"
                    />
                    <label for="salaryCurrency">Currency</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-floating">
                    <input
                      v-model="application.salaryMin"
                      class="form-control rounded-3"
                      id="salaryMin"
                      placeholder="Minimum Salary"
                    />
                    <label for="salaryMin">Minimum Salary</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-floating">
                    <input
                      v-model="application.salaryMax"
                      class="form-control rounded-3"
                      id="salaryMax"
                      placeholder="Maximum Salary"
                    />
                    <label for="salaryMax">Maximum Salary</label>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-floating">
                    <input
                      v-model="application.salaryInterval"
                      class="form-control rounded-3"
                      placeholder="Interval"
                      id="salaryInterval"
                    />
                    <label for="salaryInterval">Interval</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-floating">
                    <textarea
                      v-model="application.jobDescription"
                      class="form-control rounded-3"
                      id="jobDescription"
                      placeholder="Job Description"
                      style="height: 150px"
                    />
                    <label for="jobDescription">Job Description</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="applicationPhase == 2" class="fade-in">
            <h4 class="h5 mb-4">Company & Role Details</h4>
            <div class="row g-4">
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    v-model="application.companyName"
                    class="form-control rounded-3"
                    id="companyName"
                    placeholder="Company Name"
                  />
                  <label for="companyName">Company Name</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    v-model="application.location"
                    class="form-control rounded-3"
                    id="location"
                    placeholder="Location"
                  />
                  <label for="location">Location</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <textarea
                    v-model="application.companyDescription"
                    class="form-control rounded-3"
                    style="height: 115px"
                    placeholder="Company Description"
                  />
                  <label>What does the company do? What's their mission?</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <textarea
                    v-model="application.roleComments"
                    class="form-control rounded-3"
                    style="height: 115px"
                    placeholder="Your Interest"
                  />
                  <label>Why are you interested in this role? What makes you a good fit?</label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="applicationPhase == 3" class="fade-in">
            <h4 class="h5 mb-4">Review Your Application</h4>

            <div class="review-section">
              <div class="card bg-light border-0 rounded-3 p-4 mb-4">
                <h5 class="card-title h6 mb-3">Role Details</h5>
                <div class="row g-3">
                  <div class="col-md-6">
                    <div class="review-item">
                      <span class="text-muted">Role Title:</span>
                      <strong>{{ application.roleTitle }}</strong>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="review-item">
                      <span class="text-muted">Company:</span>
                      <strong>{{ application.companyName }}</strong>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="review-item">
                      <span class="text-muted">Location:</span>
                      <strong>{{ application.location }}</strong>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="review-item">
                      <span class="text-muted">Salary Range:</span>
                      <strong
                        >{{ application.salaryCurrency }} {{ application.salaryMin }} -
                        {{ application.salaryMax }} / {{ application.salaryInterval }}</strong
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="review-item">
                      <span class="text-muted">Date Posted:</span>
                      <strong>{{ formatDisplayDate(application.postedDate) }}</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card bg-light border-0 rounded-3 p-4">
                <h5 class="card-title h6 mb-3">Your Comments</h5>
                <p class="mb-0" style="white-space: pre-line">
                  {{ application.roleComments || "No comments added." }}
                </p>
              </div>
            </div>
          </div>

          <div class="d-flex gap-3 mt-5">
            <button
              v-if="applicationPhase > 1"
              type="button"
              class="btn btn-light flex-grow-1"
              @click="applicationPhase--"
            >
              <i class="bi bi-arrow-left me-2"></i>
              Back
            </button>
            <button
              type="button"
              class="btn btn-primary flex-grow-1"
              :disabled="loading"
              @click="buttonClicked"
            >
              <span v-show="!loading">
                {{ applicationPhase === 3 ? "Create Application" : "Continue" }}
                <i class="bi bi-arrow-right ms-2"></i>
              </span>
              <div v-show="loading" class="spinner-border spinner-border-sm" />
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CreateOrUpdateApplication from "@models/dtos/CreateOrUpdateApplication";
import { ref, onMounted } from "vue";
import router from "../router";
import { ApplicationApiService } from "@/services/ApplicationApiService";
import Modal from "@/components/Modal.vue";
import { toast } from "vue3-toastify";
import { ApplicationStatus } from "@models/SystemEnums";
import { WebscrapeApiService } from "@/services/WebscrapeApiService";
import Subscribe from "@/views/Subscribe.vue";

const loading = ref(false);
const applicationPhase = ref(1);
const buttonText = ref("Next");
const application = ref<CreateOrUpdateApplication | any>({
  id: null,
  userId: "",
  companyName: "",
  roleTitle: "",
  jobDescription: "",
  companyDescription: "",
  roleComments: "",
  applicationStatus: ApplicationStatus.APPLIED,
  index: 0,
  isDeleted: false,
  location: "",
  postedDate: "",
  salaryCurrency: "$",
  salaryMin: "",
  salaryMax: "",
  salaryInterval: "P/A",
  sourcedFrom: "",
  jobUrl: "",
  isRemote: false,
});
const jobListingURL = ref("");
const errorModal = ref(null);
const showDetails = ref(false);
const showUrlLoadError = ref(false);
const currentLoadingMessage = ref("");
const loadingMessages = [
  "Loading...",
  "Downloading webpage...",
  "Parsing webpage content...",
  "Extracting job description...",
  "Extracting company name...",
  "Extracting location...",
  "Extracting salary details...",
  "Extracting date posted...",
  "Analyzing job requirements...",
  "Extracting benefits information...",
  "Importing data...",
  "Validating data integrity...",
  "Organizing extracted information...",
  "Matching data to fields...",
  "Cleaning up extracted text...",
  "Formatting output...",
  "Preparing final data...",
  "Almost done...",
  "Finalizing details...",
  "Ready to display!",
];

let loadingInterval: number | null = null;

const startLoadingMessages = () => {
  let index = 0;
  currentLoadingMessage.value = loadingMessages[0];

  loadingInterval = window.setInterval(() => {
    index = (index + 1) % loadingMessages.length;
    currentLoadingMessage.value = loadingMessages[index];
  }, 2500);
};

const stopLoadingMessages = () => {
  if (loadingInterval) {
    clearInterval(loadingInterval);
    loadingInterval = null;
  }
};

async function loadDetailsFromURL() {
  if (!jobListingURL.value) return;

  loading.value = true;
  startLoadingMessages();

  try {
    const result = await WebscrapeApiService.getApplicationDetailsFromUrl(jobListingURL.value);
    if (result.status == 200) {
      application.value.companyName = result?.data.companyName;
      application.value.companyDescription = result?.data.companyDescription;
      application.value.jobDescription = result?.data.jobDescription;
      application.value.roleTitle = result?.data.roleTitle;
      application.value.location = result?.data.location;
      // Convert MM/DD/YYYY to ISO format
      const postedDate = result?.data.postedDate;
      if (postedDate) {
        const [month, day, year] = postedDate.split("/");
        application.value.postedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      } else {
        application.value.postedDate = new Date().toISOString().split("T")[0];
      }
      application.value.salaryCurrency = result?.data.salaryCurrency || "$";
      application.value.salaryMin = result?.data.salaryMin;
      application.value.salaryMax = result?.data.salaryMax;
      application.value.salaryInterval = result?.data.salaryInterval || "P/A";
      application.value.sourcedFrom = result?.data.sourcedFrom;
      application.value.isRemote = result?.data.isRemote || false;
      application.value.jobUrl = result?.data.jobUrl;
      showDetails.value = true;
    }
  } catch (err: any) {
    showUrlLoadError.value = true;
    if (err.response.data.error == "Quota exceeded") {
      toast.error("Quota exceeded, please upgrade your account");
      loading.value = false;
      showSubscribeModal.value = true;
    }
  } finally {
    loading.value = false;
    stopLoadingMessages();
  }
}

// Add a function to format the date for display
function formatDisplayDate(dateString: string) {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

async function buttonClicked() {
  if (applicationPhase.value === 2) {
    applicationPhase.value++;
    buttonText.value = "Create Application";
  } else if (applicationPhase.value === 3) {
    await createApplication();
  } else {
    applicationPhase.value++;
  }
}

async function createApplication() {
  try {
    loading.value = true;
    const result = await ApplicationApiService.createOrUpdateApplication(application.value);
    window.dispatchEvent(new CustomEvent("application-created"));
    await router.push("/application/" + result.data?.id);
  } catch (err: any) {
    await handleApplicationCreationError(err);
  } finally {
    loading.value = false;
  }
}

function handleApplicationCreationError(err: any) {
  if (err.status != 200) {
    if (err.response.data.error == "Quota exceeded") {
      toast.error("Quota exceeded, please upgrade your account");
      loading.value = false;
      showSubscribeModal.value = true;
    } else {
      toast.error("There has been a problem creating this application");
      loading.value = false;
      errorModal.value.show();
    }
  }
}

function getStepLabel(step: number) {
  switch (step) {
    case 1:
      return "Job Details";
    case 2:
      return "Company & Role";
    case 3:
      return "Review";
    default:
      return "";
  }
}

function toggleManualEntry() {
  showDetails.value = !showDetails.value;
  showUrlLoadError.value = false;
}

const showSubscribeModal = ref(false);
</script>

<style scoped>
.progress-container {
  padding: 0;
  margin-bottom: 0;
}

.progress-step {
  position: relative;
  flex: 1;
  text-align: center;
}

.progress-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.progress-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f8f9fa;
  border: 2px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  transition: all 0.3s ease;
}

.progress-label {
  font-size: 0.875rem;
  color: #6c757d;
  font-weight: 500;
}

.progress-line {
  position: absolute;
  top: 20px;
  left: 60%;
  right: -60%;
  height: 2px;
  background-color: #dee2e6;
  z-index: -1;
}

.progress-step.active .progress-icon {
  background-color: #0d6efd;
  border-color: #0d6efd;
  color: white;
}

.progress-step.completed .progress-icon {
  background-color: #198754;
  border-color: #198754;
  color: white;
}

.url-input-group {
  position: relative;
  display: flex;
  gap: 1rem;
}

.url-input-group .form-control {
  border-radius: 12px;
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  transition: all 0.2s ease;
}

.url-input-group .btn {
  border-radius: 12px;
  padding: 0.75rem 1.5rem;
}

.form-control:focus {
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.1);
}

.fade-in {
  animation: fadeIn 0.4s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-link {
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: underline;
}

.review-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.review-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading-pulse {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.loading-pulse::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--bs-primary);
  animation: pulse 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
