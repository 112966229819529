import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";
import Stripe from "stripe";
import { SubscriptionControlledActions } from "@models/SystemEnums";

export class SubscriptionsApiService {
  public static async createSubscription(subscriptionInfo: {
    paymentMethodId: string;
    priceId: string;
  }): Promise<AxiosResponse<Stripe.Subscription>> {
    try {
      const axios = await instance();
      const response = await axios.post("createSubscription", subscriptionInfo);

      if (response.status === 200) {
        return response;
      } else {
        console.error("Subscription creation failed:", response.data.error);
        return response;
      }
    } catch (error: any) {
      console.error("Error creating subscription:", error.message);
      throw error;
    }
  }

  public static async cancelSubscription(): Promise<AxiosResponse> {
    try {
      const axios = await instance();
      const response = await axios.post("cancelSubscription");

      if (response.status === 200) {
        return response;
      } else {
        console.error("Subscription cancellation failed:", response.data.error);
        throw new Error("Subscription cancellation failed");
      }
    } catch (error: any) {
      console.error("Error cancelling subscription:", error.message);
      throw new Error("Something went wrong during subscription cancellation");
    }
  }

  public static async getUserQuota(actionType: SubscriptionControlledActions): Promise<AxiosResponse<{ quota: number; usage: number }>> {
    try {
      const axios = await instance();
      const response = await axios.get(`getUserQuota?actionType=${actionType}`);
      return response;
    } catch (error: any) {
      console.error("Error getting user quota:", error.message);
      throw new Error("Something went wrong during user quota retrieval");
    }
  }
}
