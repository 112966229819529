<template>
  <div class="application-container" :class="{ editing: isEditing }">
    <button v-if="isEditing" class="editing-button" @click="toggleEditMode">
      <i class="bi bi-check-lg"></i>
      Finished Editing
    </button>
    <div class="content-wrapper">
      <!-- Left Panel -->
      <div class="panel-container">
        <div class="main-panel">
          <!-- Skeleton loader for job header -->
          <div v-if="loading" class="job-header">
            <div class="title-section">
              <div class="skeleton-title" style="width: 320px; height: 32px"></div>
              <div
                class="skeleton-subtitle"
                style="width: 140px; height: 24px; margin-top: 8px"
              ></div>
            </div>
            <div class="header-actions">
              <div class="skeleton-status" style="width: 120px"></div>
              <div class="skeleton-menu" style="width: 32px; height: 32px; margin-left: 12px"></div>
            </div>
          </div>

          <!-- Skeleton loader for meta info -->
          <div v-if="loading" class="meta-info" style="margin: 24px 0; display: flex; gap: 32px">
            <div
              v-for="i in 4"
              :key="i"
              class="meta-item"
              style="display: flex; align-items: center; gap: 8px"
            >
              <div class="skeleton-icon" style="width: 20px; height: 20px"></div>
              <div class="skeleton-text" style="width: 120px; height: 20px"></div>
            </div>
          </div>

          <!-- Skeleton loader for descriptions -->
          <div v-if="loading" class="job-description">
            <div class="skeleton-heading"></div>
            <div class="skeleton-description"></div>
          </div>
          <div v-if="loading" class="job-description">
            <div class="skeleton-heading"></div>
            <div class="skeleton-description"></div>
          </div>

          <!-- Actual content (wrapped in v-else) -->
          <template v-else>
            <div class="job-header">
              <div class="title-section">
                <template v-if="isEditing">
                  <input
                    v-model="application.roleTitle"
                    class="editable-title"
                    @change="saveChanges"
                  />
                  <input
                    v-model="application.companyName"
                    class="editable-subtitle"
                    @change="saveChanges"
                  />
                </template>
                <template v-else>
                  <span class="job-title-container">
                    <h1 class="job-title">{{ application.roleTitle }}</h1>
                    <div
                      @click="openUrl(application.jobUrl)"
                      style="cursor: pointer"
                      class="flex justify-center items-center"
                    >
                      <i class="bi bi-link m-2"></i>
                    </div>
                  </span>
                  <span class="company-name">{{ application.companyName }}</span>
                </template>
              </div>

              <div class="header-actions">
                <div
                  :class="[
                    'status-badge',
                    getStatusClass(application.applicationStatus),
                    isEditing ? 'm-3' : '',
                  ]"
                >
                  <div class="status-display" @click="showStatusDropdown = !showStatusDropdown">
                    {{ formatApplicationStatus(application.applicationStatus) }}
                  </div>

                  <!-- Dropdown Menu -->
                  <div v-if="showStatusDropdown" class="status-dropdown">
                    <div
                      v-for="status in applicationStatuses"
                      :key="status"
                      class="status-option"
                      :class="{ active: status === application.applicationStatus }"
                      @click="updateStatus(status)"
                    >
                      {{ formatApplicationStatus(status) }}
                    </div>
                  </div>
                </div>

                <div v-if="!isEditing" class="actions-menu">
                  <button class="action-button" @click="showActionsMenu = !showActionsMenu">
                    <i class="bi bi-three-dots-vertical"></i>
                  </button>

                  <div v-if="showActionsMenu" class="actions-dropdown">
                    <div class="action-option" @click="toggleEditMode">
                      <i class="bi" :class="isEditing ? 'bi-check-lg' : 'bi-pencil'"></i>
                      {{ isEditing ? "Save Changes" : "Edit" }}
                    </div>
                    <div class="action-option delete" @click="deleteApplication">
                      <i class="bi bi-trash"></i>
                      Delete
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="meta-info">
              <div class="meta-item">
                <i class="bi bi-geo-alt"></i>
                <template v-if="isEditing">
                  <input
                    v-model="application.location"
                    placeholder="Location not specified"
                    @change="saveChanges"
                  />
                </template>
                <span v-else>{{
                  application.location ? getShortLocation(application.location) : "N/A"
                }}</span>
              </div>

              <div class="meta-item">
                <i class="bi bi-calendar"></i>
                <template v-if="isEditing">
                  <input v-model="application.postedDate" placeholder="N/A" @change="saveChanges" />
                </template>
                <span v-else>{{
                  application.postedDate ? getFormattedDate(application.postedDate) : "N/A"
                }}</span>
              </div>

              <div class="meta-item">
                <i class="bi bi-cash"></i>
                <template v-if="isEditing">
                  <div class="salary-input">
                    <input
                      v-model="application.salaryCurrency"
                      placeholder="USD"
                      class="currency-input"
                      @change="saveChanges"
                    />
                    <input
                      v-model="application.salaryMin"
                      placeholder="Min"
                      type="number"
                      class="number-input"
                      @change="saveChanges"
                    />
                    -
                    <input
                      v-model="application.salaryMax"
                      placeholder="Max"
                      type="number"
                      class="number-input"
                      @change="saveChanges"
                    />
                    <input
                      v-model="application.salaryInterval"
                      placeholder="per year"
                      class="interval-input"
                      @change="saveChanges"
                    />
                  </div>
                </template>
                <span v-else>{{
                  formatSalary(
                    application.salaryCurrency,
                    application.salaryMin,
                    application.salaryMax,
                    application.salaryInterval
                  )
                }}</span>
              </div>

              <div class="meta-item">
                <i class="bi bi-building"></i>
                <template v-if="isEditing">
                  <select v-model="application.isRemote" @change="saveChanges">
                    <option :value="true">Remote</option>
                    <option :value="false">On-site</option>
                  </select>
                </template>
                <span v-else>{{ application.isRemote ? "Remote" : "On-site" }}</span>
              </div>
            </div>
            <div class="job-description">
              <h5>Company Description</h5>
              <div class="company-description-content">
                <template v-if="isEditing">
                  <textarea
                    v-model="application.companyDescription"
                    class="editable-description"
                    @change="saveChanges"
                  ></textarea>
                </template>
                <template v-else>
                  <div v-if="application.companyDescription" class="description-text">
                    {{ application.companyDescription }}
                  </div>
                  <div v-else class="empty-description">No company description provided</div>
                </template>
              </div>
            </div>
            <div class="job-description">
              <h5>Job Description</h5>
              <div class="description-content job-description-content">
                <template v-if="isEditing">
                  <textarea
                    v-model="application.jobDescription"
                    class="editable-description"
                    @change="saveChanges"
                  ></textarea>
                </template>
                <template v-else>
                  <div v-if="application.jobDescription" class="description-text">
                    {{ application.jobDescription }}
                  </div>
                  <div v-else class="empty-description">No job description provided</div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>

      <!-- Right Panel -->
      <div class="tools-and-analysis-panel">
        <!-- Skeleton loader for tools panel -->
        <div v-if="loading" class="tools-panel">
          <div
            class="tools-grid"
            style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 16px"
          >
            <div v-for="i in 4" :key="i" class="skeleton-tool" style="height: 100px; padding: 16px">
              <div
                class="skeleton-icon"
                style="width: 24px; height: 24px; margin-bottom: 8px"
              ></div>
              <div class="skeleton-text" style="width: 80%"></div>
            </div>
          </div>
        </div>

        <!-- Skeleton loader for analysis panel -->
        <div v-if="loading" class="match-analysis" style="margin-top: 32px">
          <div
            class="match-content"
            style="display: flex; justify-content: space-between; margin-top: 24px"
          >
            <div class="match-details">
              <div
                v-for="(item, i) in 3"
                :key="i"
                class="skeleton-match-item"
                :style="{ width: i === 0 ? '180px' : '160px' }"
              ></div>
            </div>
            <div class="skeleton-score-circle" style="width: 140px; height: 140px"></div>
          </div>
        </div>

        <!-- Actual content (wrapped in v-else) -->
        <template v-else>
          <div class="tools-panel">
            <div class="tools-grid">
              <div
                v-for="tool in aiTools"
                :key="tool.name"
                class="tool-card"
                :class="{ disabled: tool.disabled }"
                @click="tool.action"
              >
                <span v-if="tool.comingSoon" class="coming-soon">Coming Soon</span>
                <span v-if="tool.isNew" class="new-tag">New</span>
                <i :class="tool.icon"></i>
                <span>{{ tool.name }}</span>
              </div>
            </div>
          </div>
          <div class="analysis-section">
            <template v-if="matchAnalysis">
              <div class="match-analysis">
                <JobMatchRadarChart :data="radarChartData" :compact="true" style="height: 200px" />
                <div class="analysis-actions">
                  <button class="analysis-btn" @click="openAdvancedAnalysis">
                    View Full Analysis
                    <i class="bi bi-arrow-right"></i>
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="splash-screen">
                <div class="splash-content">
                  <div class="icon-container">
                    <i class="bi bi-graph-up-arrow text-primary"></i>
                  </div>
                  <div class="text-content">
                    <h3>Analyze Your Match</h3>
                    <p>See how well your profile aligns with this role's requirements</p>
                  </div>
                  <button class="analysis-btn" @click="openAdvancedAnalysis">
                    View Analysis
                    <i class="bi bi-arrow-right"></i>
                  </button>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Base styles with mobile-first approach */
.application-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.content-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  max-width: 1400px;
  min-height: 100vh;
  padding: 1rem;
  overflow-y: auto;
  margin: 0 auto;
}

.main-panel {
  background: white;
  border-radius: 16px;
  padding: 1rem;
  height: auto;
  min-height: calc(100vh - 2rem);
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  overflow-x: hidden;
}

.job-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.title-section {
  flex: 1;
  min-width: 0;
}

.job-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
  line-height: 1.3;
}

.company-name {
  font-size: 1.1rem;
  color: #666;
  font-weight: normal;
  display: block;
  margin-top: 0.25rem;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
  font-size: 0.95rem;
  padding: 0.5rem;
}

.meta-item i {
  font-size: 1rem;
  color: #666;
  opacity: 0.8;
}

.tools-and-analysis-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;
  border-radius: 16px;
  padding: 1.25rem;
}

.tools-panel {
  flex-shrink: 0;
  margin-bottom: 1.5rem;
}

.tools-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.tool-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1.25rem;
  background: #f8f9fa;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.15s ease;
}

.tool-card:hover {
  background: #f1f3f5;
}

.tool-card i {
  font-size: 1.5rem;
  color: #0d6efd;
}

.tool-card span {
  font-size: 0.875rem;
  color: #495057;
}

.analysis-section {
  margin-top: auto;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.splash-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.5rem;
}

.splash-content {
  max-width: 280px;
}

.icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.icon-container i {
  font-size: 2rem;
  color: white;
}

.splash-content h3 {
  font-size: 1.125rem;
  font-weight: 500;
  color: #212529;
  margin-bottom: 0.5rem;
}

.splash-content p {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #6c757d;
  margin-bottom: 1rem;
}

.analysis-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #6b7280;
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.15s ease;
}

.analysis-btn:hover {
  color: #374151;
  background: #f3f4f6;
  border-color: #d1d5db;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.analysis-btn i {
  font-size: 1rem;
  color: inherit;
}

.content-wrapper {
  padding: 1rem;
}

/* Tablet breakpoint */
@media (min-width: 768px) {
  .job-header {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
  }

  .title-section {
    max-width: 70%;
  }

  .job-title {
    font-size: 1.75rem;
  }

  .company-name {
    font-size: 1.2rem;
  }
}

/* Desktop breakpoint */
@media (min-width: 1024px) {
  .content-wrapper {
    grid-template-columns: minmax(600px, 2fr) minmax(350px, 1fr);
    gap: 1.5rem;
    height: calc(100vh - 2rem);
    padding: 1rem 2rem;
  }

  .main-panel {
    height: 100%;
    max-width: 1000px;
  }

  .tools-and-analysis-panel {
    height: 100%;
    min-width: 350px;
  }
}

/* Status badge styles */
.status-badge {
  min-width: 150px;
  border-radius: 20px;
  position: relative;
  flex-shrink: 0;
  margin: 5px;
}

.status-display {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
}

.status-display:hover {
  opacity: 0.9;
}

.status-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
  z-index: 1000;
  overflow: hidden;
}

.status-option {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #495057;
}

.status-option:hover {
  background-color: #f8f9fa;
  transform: translateX(4px);
}

.status-option.to-apply {
  color: #6a5acd;
}

.status-option.applied {
  color: #40808c;
}

.status-option.interviewing {
  color: #cd5c5c;
}

.status-option.rejected {
  color: #db7093;
}

.status-option.offered {
  color: #3cb371;
}

.status-option.active {
  background-color: #f8f9fa;
  font-weight: 500;
}

.status-badge {
  position: relative;
  display: inline-block;
}

.status-badge.to-apply {
  background: #e6e6fa;
  color: #6a5acd;
}

.status-badge.applied {
  background: #e0ffff;
  color: #40808c;
}

.status-badge.interviewing {
  background: #ffe4e1;
  color: #cd5c5c;
}

.status-badge.rejected {
  background: #ffe4e8;
  color: #db7093;
}

.status-badge.offered {
  background: #e0ffe0;
  color: #3cb371;
}

.meta-info {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 12px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
  min-height: 50px;
  max-height: 50px;
}

.meta-info::-webkit-scrollbar {
  height: 6px;
}

.meta-info::-webkit-scrollbar-track {
  background: transparent;
}

.meta-info::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
  flex: 1;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  min-width: fit-content;
}

.meta-item i {
  color: #1976d2;
  font-size: 1.25rem;
  flex-shrink: 0;
}

.meta-item span {
  white-space: nowrap;
}

.match-analysis {
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 0;
}

.match-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
}

.match-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.match-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.match-item i {
  color: #2e7d32;
}

.score-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: conic-gradient(#2e7d32 80%, #e0e0e0 0);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.score-circle::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 80%;
  background: white;
  border-radius: 50%;
}

.score-circle span {
  position: relative;
  font-size: 1.5rem;
  font-weight: bold;
  color: #2e7d32;
}

.analysis-btn {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.tools-panel {
  background: white;
  border-radius: 16px;
  padding: 0rem;
  height: 50%;
}

.tools-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 0rem;
}

.tool-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.25rem;
  background: #f8f9fa;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 120px;
}

.tool-card:hover {
  background: #e3f2fd;
  transform: translateY(-2px);
}

.tool-card i {
  font-size: 1.75rem;
  color: #1976d2;
}

.tool-card.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.tool-card .coming-soon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #ff9800;
  color: white;
  font-size: 0.6rem;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: 500;
}

.tool-card .new-tag {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #28a745;
  color: white;
  font-size: 0.6rem;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: 500;
}

.job-description {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.description-content,
.company-description-content,
.job-description-content {
  line-height: 1.6;
  color: #444;
  overflow-y: scroll;
  padding-right: 8px;
  min-height: 50px;
  text-align: left;
  position: relative;
}

.company-description-content {
  max-height: 150px;
}

.job-description-content {
  max-height: 40vh;
}

/* Scrollbar styles */
.description-content::-webkit-scrollbar,
.company-description-content::-webkit-scrollbar,
.job-description-content::-webkit-scrollbar {
  background: transparent;
}

.description-content::-webkit-scrollbar-thumb,
.company-description-content::-webkit-scrollbar-thumb,
.job-description-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.editable-title {
  font-size: 2rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
  border: 1px solid transparent;
  background: transparent;
  width: 100%;
  padding: 0.25rem;
}

.editable-subtitle {
  font-size: 1.25rem;
  color: #666;
  font-weight: normal;
  border: 1px solid transparent;
  background: transparent;
  width: 100%;
  padding: 0.25rem;
}

.editable-title:hover,
.editable-subtitle:hover,
.meta-item input:hover,
.meta-item select:hover {
  border-color: #ddd;
}

.editable-title:focus,
.editable-subtitle:focus,
.meta-item input:focus,
.meta-item select:focus {
  border-color: #1976d2;
  outline: none;
}

.meta-item input,
.meta-item select {
  width: auto;
  min-width: 80px;
  max-width: 150px;
}

.salary-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}

.salary-input input {
  padding: 0.25rem 0.5rem;
}

.salary-input .currency-input {
  width: 50px;
}

.salary-input .number-input {
  width: 70px;
}

.salary-input .interval-input {
  width: 80px;
}

.editable-description {
  width: 100%;
  min-height: 150px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  resize: vertical;
}

.editable-description:hover {
  border-color: #ddd;
}

.editable-description:focus {
  border-color: #1976d2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.header-actions {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  margin-left: auto;
  flex-shrink: 0;
}

.actions-menu {
  position: relative;
}

.action-button {
  background: none;
  border: none;
  padding: 1rem;
  cursor: pointer;
  color: #666;
}

.action-button:hover {
  color: #1976d2;
}

.actions-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  min-width: 150px;
}

.action-option {
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.action-option:hover {
  background-color: #f8f9fa;
}

.action-option.delete {
  color: #dc3545;
}

.action-option i {
  font-size: 1.1rem;
}

/* Edit mode indicator */
.application-container.editing {
  background: #f8f9fa;
}

.application-container.editing .main-panel {
  border: 2px solid #1976d2;
  padding-top: 0;
}

.editing-button {
  position: sticky;
  top: 1rem;
  left: 1rem;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #1976d2;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: background-color 0.2s;
  width: 250px;
  justify-content: center;
  position: absolute;
  left: 35%;
  transform: translateX(-50%);
}

.editing-button i {
  font-size: 1.1rem;
}

.editing-button:hover {
  background-color: #1565c0;
}

/* Input styles */
input,
select,
textarea {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  background: white;
}

.editing input:hover,
.editing select:hover,
.editing textarea:hover {
  border-color: #1976d2;
}

.editing input:focus,
.editing select:focus,
.editing textarea:focus {
  border-color: #1976d2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

/* Update the container for the button and panel */
.panel-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the button */
  width: 100%;
  min-width: 0;
  overflow: hidden;
}

/* Ensure the main panel takes full width */
.main-panel {
  width: 100%;
  min-width: 0;
  overflow-x: hidden;
}

/* Ensure inputs don't overflow */
.editable-title,
.editable-subtitle,
.editable-description,
.meta-item input,
.meta-item select {
  max-width: 100%;
  box-sizing: border-box;
}

/* Add a specific class for the second description to control its spacing */
.job-description + .job-description {
  margin-top: 1rem;
}

.job-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  vertical-align: middle;
  gap: 0.5rem;
  font-size: 2.25rem;
  margin-bottom: 0;
}

.job-title {
  text-align: left;
}

.company-name {
  text-align: left;
}

/* Add these new skeleton loader styles */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton-base {
  background: linear-gradient(90deg, #f5f6f8 25%, #ebedf0 50%, #f5f6f8 75%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
  border-radius: 8px;
}

.skeleton-title {
  composes: skeleton-base;
  height: 2.5rem;
  width: 80%;
  margin-bottom: 0.5rem;
  border-radius: 4px;
}

.skeleton-subtitle {
  composes: skeleton-base;
  height: 1.5rem;
  width: 60%;
  border-radius: 4px;
}

.skeleton-status {
  composes: skeleton-base;
  height: 2rem;
  width: 150px;
  border-radius: 20px;
}

.skeleton-icon {
  composes: skeleton-base;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
}

.skeleton-text {
  composes: skeleton-base;
  height: 1rem;
  width: 100px;
  border-radius: 4px;
}

.skeleton-heading {
  composes: skeleton-base;
  height: 1.5rem;
  width: 200px;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.skeleton-description {
  composes: skeleton-base;
  height: 150px;
  width: 100%;
  border-radius: 4px;
}

.skeleton-tool {
  composes: skeleton-base;
  height: 80px;
  border-radius: 12px;
}

.skeleton-match-item {
  composes: skeleton-base;
  height: 1.5rem;
  width: 150px;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.skeleton-score-circle {
  composes: skeleton-base;
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

/* Ensure consistent spacing during loading */
.main-panel {
  min-height: calc(100vh - 4rem);
}

.tools-and-analysis-panel {
  min-height: calc(100vh - 4rem);
  padding: 0.8rem;
}

.analysis-section {
  background: white;
  border-radius: 12px;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.analysis-actions {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  padding-bottom: 0rem;
}

.analysis-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #6b7280;
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.15s ease;
}

.analysis-btn:hover {
  color: #374151;
  background: #f3f4f6;
  border-color: #d1d5db;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.analysis-btn i {
  font-size: 1rem;
  color: inherit;
}

.radar-chart-container {
  flex: 1;
  min-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.match-analysis {
  padding: 1rem;
}

.match-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.match-details {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.match-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #495057;
  font-size: 0.875rem;
}

.match-item i {
  color: #198754;
  font-size: 1rem;
}

.score-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #f8f9fa;
  border: 2px solid #198754;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.score-circle span {
  font-size: 1.25rem;
  font-weight: 500;
  color: #198754;
}

.analysis-actions {
  display: flex;
  justify-content: center;
}

.analysis-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #0d6efd;
  background: white;
  border: 1px solid #0d6efd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.15s ease;
}

.analysis-btn:hover {
  background: #0d6efd;
  color: white;
}

.analysis-btn i {
  font-size: 0.875rem;
}

.empty-description {
  color: #6c757d;
  font-style: italic;
  padding: 1rem 0;
}
</style>

<script setup lang="ts">
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  ArcElement,
} from "chart.js";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";
import { ref, computed, onMounted } from "vue";
import { ApplicationApiService } from "@/services/ApplicationApiService";
import CreateOrUpdateApplication from "@models/dtos/CreateOrUpdateApplication";
import { ApplicationStatus } from "@models/SystemEnums";
import { ResponseApiService } from "@/services/ResponseApiService";
import { ResponseDto } from "@models/dtos/ResponseDtos";
import { ResponseType } from "@models/Response";
import { ResumeFeedbackOutput, MatchData } from "../models/dtos/ResumeFeedbackOutput";
import JobMatchRadarChart from "@/components/JobMatchRadarChart.vue";
import confetti from "canvas-confetti";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend, ArcElement);
ChartJS.defaults.font.size = 16;

const showFullDescription = ref(false);
const toggleDescription = () => {
  showFullDescription.value = !showFullDescription.value;
};
const application = ref<CreateOrUpdateApplication>({
  id: null,
  userId: "",
  companyName: "",
  roleTitle: "",
  jobDescription: "",
  companyDescription: "",
  roleComments: "",
  applicationStatus: ApplicationStatus.APPLIED,
  index: 0,
  location: "",
  postedDate: "",
  salary: "",
  maxSalary: "",
  sourcedFrom: "",
  jobUrl: "",
  isRemote: false,
});

const route = useRoute();
const router = useRouter();
const showResumeAnalysis = ref(false);
const applicationResponses = ref<ResponseDto[] | null>(null);

// Add loading state
const loading = ref(true);

// Update the onMounted hook
onMounted(async () => {
  store.dispatch("toggleLoader", true);
  loading.value = true;
  await loadApplication();
  loading.value = false;
  store.dispatch("toggleLoader", false);
});

async function loadApplication() {
  const applicationId = route.params.id as string;
  const response = await ApplicationApiService.getApplicationById(applicationId);
  if (response.status == 200) {
    application.value = await response.data;
    application.value.id = applicationId;
    applicationResponses.value = (
      await ResponseApiService.getApplicationResponses(applicationId)
    ).data;
  }
}

function getFormattedDate(dateString: string) {
  if (!dateString) return "N/A";
  // Handle MM/DD/YYYY format
  if (dateString.includes("/")) {
    const [month, day, year] = dateString.split("/");
    dateString = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
}

function getShortLocation(location: string) {
  return location?.split(",")[0].trim();
}

function openCoverLetterGenerator(): void {
  const coverLetterResponse = applicationResponses.value?.find(
    (r) => r.type === ResponseType.CoverLetter
  );
  if (coverLetterResponse?.id) {
    router.push(`/application/${route.params.id}/documents/${coverLetterResponse.id}`);
  } else {
    router.push(`/application/${route.params.id}/documents?type=coverLetter`);
  }
}

function openResumeGenerator(): void {
  const resumeResponse = applicationResponses.value?.find(
    (r) => r.type === ResponseType.CreateResume
  );
  if (resumeResponse?.id) {
    router.push(`/application/${route.params.id}/documents/${resumeResponse.id}`);
  } else {
    router.push(`/application/${route.params.id}/documents?type=resume`);
  }
}

function getIconForJobSite(jobSite: string) {
  jobSite = jobSite.toLowerCase();
  switch (jobSite) {
    case "indeed":
      return require("../../public/media/icons/indeed.svg");
    case "linkedin":
      return require("../../public/media/icons/linkedin.svg");
    case "glassdoor":
      return require("../../public/media/icons/glassdoor.svg");
    case "zip_recruiter":
      return require("../../public/media/icons/zip.svg");
    default:
      return "";
  }
}

function openUrl(url: string) {
  window.open(url, "_blank");
}

function createDocument(type: string) {
  if (type === "coverLetter") {
    router.push(`/application/${route.params.id}/documents?newCoverLetter=true`);
  }
  // ... handle other document types
}

const aiTools = [
  {
    name: "Cover Letter",
    icon: "bi bi-file-text",
    action: openCoverLetterGenerator,
  },
  {
    name: "Email Generator",
    icon: "bi bi-envelope",
    action: () => {
      router.push({
        name: "EmailGenerator",
      });
    },
  },
  {
    name: "Resume",
    icon: "bi bi-file-earmark-text",
    action: openResumeGenerator,
  },
  {
    name: "Interview Prep",
    icon: "bi bi-person-video",
    action: () => {
      router.push(`/application/${route.params.id}/interview-prep`);
    },
  },
  {
    name: "Interview Practice",
    icon: "bi bi-camera-video",
    action: () => {
      router.push(`/application/${route.params.id}/interview-practice`);
    },
    isNew: true,
  },
  {
    name: "LinkedIn Message",
    icon: "bi bi-linkedin",
    action: () => {
      console.log("");
    },
    disabled: true,
    comingSoon: true,
  },
];

// Add this function to format the enum value
function formatApplicationStatus(status: ApplicationStatus | string): string {
  if (typeof status === "string") {
    return status
      .split("_")
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(" ");
  }
  return ApplicationStatus[status]
    .split("_")
    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(" ");
}

const showStatusDropdown = ref(false);

// Create a computed array of unique statuses
const applicationStatuses = computed(() => [
  ApplicationStatus.TO_APPLY,
  ApplicationStatus.APPLIED,
  ApplicationStatus.INTERVIEWING,
  ApplicationStatus.REJECTED,
  ApplicationStatus.OFFERED,
]);

const updateStatus = async (newStatus: ApplicationStatus) => {
  application.value.applicationStatus = newStatus;
  showStatusDropdown.value = false;

  if (newStatus === ApplicationStatus.OFFERED) {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 1 },
    });
  }
  try {
    await ApplicationApiService.createOrUpdateApplication(application.value);
  } catch (error) {
    console.error("Failed to update application status:", error);
    await loadApplication();
  }
};

// Click outside to close dropdown
onMounted(() => {
  document.addEventListener("click", (e) => {
    if (!e.target.closest(".status-badge")) {
      showStatusDropdown.value = false;
    }
  });
});

const getStatusClass = (status: ApplicationStatus) => {
  const statusClasses = {
    [ApplicationStatus.TO_APPLY]: "to-apply",
    [ApplicationStatus.APPLIED]: "applied",
    [ApplicationStatus.INTERVIEWING]: "interviewing",
    [ApplicationStatus.REJECTED]: "rejected",
    [ApplicationStatus.OFFERED]: "offered",
  };
  return statusClasses[status];
};

// Add this new function to handle saving changes
const saveChanges = async () => {
  try {
    await ApplicationApiService.createOrUpdateApplication(application.value);
  } catch (error) {
    console.error("Failed to save changes:", error);
    await loadApplication(); // Reload original data if save fails
  }
};

const isEditing = ref(false);
const showActionsMenu = ref(false);

const toggleEditMode = () => {
  if (isEditing.value) {
    saveChanges();
  }
  isEditing.value = !isEditing.value;
  showActionsMenu.value = false;
};

const deleteApplication = async () => {
  if (confirm("Are you sure you want to delete this application?")) {
    try {
      await ApplicationApiService.deleteApplication(application.value.id);
      router.push("/applications");
    } catch (error) {
      console.error("Failed to delete application:", error);
    }
  }
};

const formatSalary = (
  currency: string,
  min: number | string,
  max: number | string,
  interval: string
) => {
  const getCurrencySymbol = (currency: string) => {
    return currency === "USD"
      ? "$"
      : currency === "EUR"
      ? "€"
      : currency === "GBP"
      ? "£"
      : currency;
  };
  if (!min && !max) {
    return "N/A";
  }

  const formattedNumber = (num: number | string) => {
    num = parseFloat(num.toString().replace(/[^\d.]/g, "")).toLocaleString();
    return num;
  };

  if (!max) {
    return `< ${getCurrencySymbol(currency)}${formattedNumber(min)} ${interval || "y"}`;
  } else if (!min) {
    return `> ${getCurrencySymbol(currency)}${formattedNumber(max)} ${interval || "y"}`;
  }
  return `${getCurrencySymbol(currency)}${formattedNumber(min)}-${formattedNumber(max)} ${
    interval || "y"
  }`;
};

const matchAnalysis = ref<ResumeFeedbackOutput | null>(null);
// Modify the existing click outside handler
onMounted(async () => {
  document.addEventListener("click", (e) => {
    const target = e.target as HTMLElement;
    if (!target.closest(".status-badge")) {
      showStatusDropdown.value = false;
    }
    if (!target.closest(".actions-menu")) {
      showActionsMenu.value = false;
    }
  });
  const applicationId = route.params.id as string;
  matchAnalysis.value = (
    await ApplicationApiService.getApplicationAnalysisById(applicationId)
  ).data;
});

const openAdvancedAnalysis = () => {
  router.push(`/application/${route.params.id}/match-analysis`);
};

const getRadarChartData = (analysis: ResumeFeedbackOutput) => {
  // Calculate percentages
  const calculatePercentage = (data: { term: string; included: boolean }[] | undefined) => {
    if (!data || !Array.isArray(data) || data.length === 0) return null;
    const includedCount = data.filter((item) => item.included).length;
    return Math.round((includedCount / data.length) * 100);
  };

  // Calculate all matches
  const culturalMatch = calculatePercentage(analysis.culturalMatchData);
  const skillsMatch = calculatePercentage(analysis.skillMatchData);
  const experienceMatch = calculatePercentage(analysis.experienceMatchData);
  const keywordMatch = calculatePercentage(analysis.keywordMatchData);
  const educationMatch = analysis.educationMatchData?.term
    ? analysis.educationMatchData.included
      ? 100
      : 0
    : null;

  // Create data array in same order as labels
  const data = [
    culturalMatch ?? 0, // If null (N/A), show as 0
    educationMatch ?? 0, // If null (N/A), show as 0
    experienceMatch ?? 0,
    keywordMatch ?? 0, // If null (N/A), show as 0
    skillsMatch ?? 0,
  ];

  return {
    labels: ["Cultural", "Education", "Experience", "Keyword", "Skills"],
    datasets: [
      {
        data,
        backgroundColor: "rgba(13, 110, 253, 0.2)",
        borderColor: "#0d6efd",
        borderWidth: 2,
        pointBackgroundColor: "#0d6efd",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#0d6efd",
      },
    ],
  };
};

// Update the onMounted hook to transform the data
onMounted(async () => {
  document.addEventListener("click", (e) => {
    const target = e.target as HTMLElement;
    if (!target.closest(".status-badge")) {
      showStatusDropdown.value = false;
    }
    if (!target.closest(".actions-menu")) {
      showActionsMenu.value = false;
    }
  });
  const applicationId = route.params.id as string;
  const analysisResponse = await ApplicationApiService.getApplicationAnalysisById(applicationId);
  if (analysisResponse.data) {
    matchAnalysis.value = analysisResponse.data;
    radarChartData.value = getRadarChartData(analysisResponse.data);
  }
});

interface RadarChartData {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    pointBackgroundColor: string;
    pointBorderColor: string;
    pointHoverBackgroundColor: string;
    pointHoverBorderColor: string;
  }[];
}

// Add this with the other refs
const radarChartData = ref<RadarChartData | null>(null);
</script>
