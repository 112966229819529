<template class="feedback-view">
  <div data-canny class="canny-container" />
</template>

<script>
import { useStore } from "vuex";
import { UserApiService } from "@/services/UserApiService";
const BoardToken = "1e6835ab-8984-c1a7-7fae-7bd7d6fc3e0c";

export default {
  setup() {
    const store = useStore();
    return { store };
  },
  async mounted() {
    this.store.dispatch("toggleLoader", true);

    const token = (await UserApiService.getCannyToken()).data.token;

    // eslint-disable-next-line no-var, prefer-rest-params
    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          const f = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          (e.type = "text/javascript"),
            (e.async = !0),
            (e.src = "https://canny.io/sdk.js"),
            f.parentNode.insertBefore(e, f);
        }
      }
      if ("function" != typeof w.Canny) {
        const c = function () {
          // eslint-disable-next-line prefer-rest-params
          c.q.push(arguments);
        };
        (c.q = []),
          (w.Canny = c),
          "complete" === d.readyState
            ? l()
            : w.attachEvent
            ? w.attachEvent("onload", l)
            : w.addEventListener("load", l, !1);
      }
    })(window, document, "canny-jssdk", "script");

    const checkCannyLoaded = setInterval(() => {
      if (document.querySelector('iframe[title="Canny"]')) {
        this.store.dispatch("toggleLoader", false);
        clearInterval(checkCannyLoaded);

        // Identify user after Canny is loaded
      }
    }, 100);

    // Clear interval after 2 seconds as a safety measure
    setTimeout(() => {
      clearInterval(checkCannyLoaded);
      this.store.dispatch("toggleLoader", false);
    }, 1500);

    Canny("render", {
      boardToken: BoardToken,
      basePath: "/feedback",
      ssoToken: token || null,
      theme: "light",
    });
  },
};
</script>

<style scoped>
.canny-container {
  padding: 25px;
}
</style>
