<template>
  <div class="country-selector">
    <div class="form-floating">
      <input
        type="text"
        class="form-control"
        id="countrySearch"
        placeholder="Search countries..."
        v-model="searchQuery"
        @focus="showDropdown = true"
        @blur="handleBlur"
      />
      <label for="countrySearch">{{ selectedCountry ? selectedCountry.name : 'Search Country' }}</label>
    </div>
    
    <div v-if="showDropdown" class="country-dropdown">
      <div 
        v-for="country in filteredCountries" 
        :key="country.code"
        class="country-option"
        @mousedown="selectCountry(country)"
      >
        <span class="country-flag">{{ getCountryFlag(country.code) }}</span>
        <span class="country-name">{{ country.name }}</span>
      </div>
      <div v-if="filteredCountries.length === 0" class="no-results">
        No countries found
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { countries, type Country } from './CountriesList';
import countryFlagEmoji from 'country-flag-emoji';

const props = defineProps<{
  modelValue: string | null;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void;
}>();

const searchQuery = ref('');
const showDropdown = ref(false);
const selectedCountry = ref<Country | null>(
  props.modelValue ? countries.find(c => c.name === props.modelValue) || null : null
);

const filteredCountries = computed(() => {
  const query = searchQuery.value.toLowerCase();
  return countries.filter(country => 
    country.name.toLowerCase().includes(query) ||
    country.code.toLowerCase().includes(query)
  );
});

function getCountryFlag(code: string) {
  const flagEmoji = countryFlagEmoji.get(code);
  return flagEmoji ? flagEmoji.emoji : '🌐';
}

function selectCountry(country: Country) {
  selectedCountry.value = country;
  searchQuery.value = '';
  showDropdown.value = false;
  emit('update:modelValue', country.name);
}

function handleBlur() {
  // Delay hiding dropdown to allow click events to register
  setTimeout(() => {
    showDropdown.value = false;
  }, 200);
}
</script>

<style scoped>
.country-selector {
  position: relative;
  width: 100%;
}

.country-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 1000;
  margin-top: 4px;
}

.country-option {
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.country-option:hover {
  background-color: #f8f9fa;
}

.country-flag {
  font-size: 1.2em;
}

.country-name {
  flex: 1;
}

.no-results {
  padding: 1rem;
  text-align: center;
  color: #6c757d;
}

.form-control {
  padding-right: 2.5rem;
}

/* Ensure the dropdown appears above other elements */
.country-selector {
  z-index: 100;
}
</style>
