<template>
  <div class="radar-chart-container">
    <canvas ref="radarChart"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  RadarController,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { defineComponent, onMounted, ref, watch } from "vue";

// Register required Chart.js components
Chart.register(RadarController, RadialLinearScale, PointElement, LineElement, Filler);

export default defineComponent({
  name: "JobMatchRadarChart",
  props: {
    data: {
      type: Object,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const radarChart = ref(null);
    let chartInstance = null;

    const createChart = () => {
      const ctx = radarChart.value.getContext("2d");

      if (chartInstance) {
        chartInstance.destroy();
      }

      chartInstance = new Chart(ctx, {
        type: "radar",
        data: props.data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            r: {
              min: 0,
              max: 100,
              beginAtZero: true,
              ticks: {
                stepSize: 20,
                font: {
                  family: "Arial, sans-serif",
                  size: props.compact ? 10 : 14,
                },
                display: false,
              },
              pointLabels: {
                font: {
                  family: "Arial, sans-serif",
                  size: props.compact ? 10 : 14,
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    };

    watch(
      () => props.data,
      () => {
        createChart();
      },
      { deep: true }
    );

    onMounted(() => {
      if (props.data) {
        createChart();
      }
    });

    return {
      radarChart,
    };
  },
});
</script>

<style scoped>
.radar-chart-container {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 230px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analysis-section.radar-chart-container {
  padding: 0;
  margin: 0;
}

div .radar-chart-container {
  padding: 0;
  margin: 0;
}
.radar-chart-container.radar-chart-container {
  padding: 0px;
  margin: 0px;
}

.radar-chart-container canvas {
  box-shadow: none;
  padding: 0;
}
</style>
