<template>
  <div class="dashboard-container">
    <!-- Search and Filters -->
    <div class="d-flex gap-2 mb-4">
      <div class="search-box flex-grow-1">
        <i class="bi bi-search"></i>
        <input
          type="text"
          class="form-control"
          placeholder="Search courses..."
          v-model="searchQuery"
        />
      </div>
      <div class="dropdown">
        <button
          class="btn btn-outline-secondary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          <i class="bi bi-funnel"></i> {{ activeCategory || "All Categories" }}
        </button>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" href="#" @click="setCategory('')">All Categories</a>
          </li>
          <li v-for="category in uniqueCategories" :key="category">
            <a class="dropdown-item" href="#" @click="setCategory(category)">
              {{ category }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Courses Table -->
    <div class="card border-0 shadow-sm courses-table">
      <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead>
            <tr>
              <th class="sortable-header" @click="sortBy('Technology')">
                Course Title
                <i :class="getSortIcon('Technology')" class="sort-icon"></i>
              </th>
              <th class="sortable-header" @click="sortBy('Provider')">
                Provider
                <i :class="getSortIcon('Provider')" class="sort-icon"></i>
              </th>
              <th class="sortable-header" @click="sortBy('Category')">
                Category
                <i :class="getSortIcon('Category')" class="sort-icon"></i>
              </th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="course in filteredCourses"
              :key="course.ID"
              @click="showCourseDetails(course)"
              style="cursor: pointer"
            >
              <td>{{ course.Technology }}</td>
              <td>{{ course.Provider }}</td>
              <td>
                <span
                  class="category-badge"
                  :style="{ backgroundColor: getCategoryColor(course.Category) }"
                >
                  {{ course.Category }}
                </span>
              </td>
              <td>
                <a :href="course.Link" target="_blank" class="btn btn-link">
                  <i class="bi bi-box-arrow-up-right"></i> View Course
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Course Details Modal -->
  <Modal ref="courseDetailsModal" hideCloseButton modal-lg>
    <template #body>
      <div v-if="selectedCourse" class="course-details">
        <div class="mb-4">
          <h4 class="course-title mb-3">{{ selectedCourse.Technology }}</h4>
          <div class="provider mb-2">
            <i class="bi bi-building me-2"></i>
            {{ selectedCourse.Provider }}
          </div>
          <span
            class="category-badge"
            :style="{ backgroundColor: getCategoryColor(selectedCourse.Category) }"
          >
            {{ selectedCourse.Category }}
          </span>
        </div>

        <div class="description mb-4">
          <h6 class="text-muted mb-2">Description</h6>
          <p>{{ selectedCourse.Description }}</p>
        </div>
      </div>
    </template>
    <template #footer>
      <a :href="selectedCourse?.Link" target="_blank" class="btn btn-primary">
        <i class="bi bi-box-arrow-up-right me-2"></i>
        View Course on {{ selectedCourse?.Provider }}
      </a>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import Modal from "@/components/Modal.vue";
import coursesData from "@/assets/courses/courses.json";

const route = useRoute();
const router = useRouter();
const courses = ref(coursesData);
const searchQuery = ref("");
const sortField = ref("Technology");
const sortDirection = ref("asc");
const activeCategory = ref("");
const courseDetailsModal = ref();
const selectedCourse = ref(null);

// Generate category colors
const categoryColors = new Map();
const pastelColors = [
  "#FFB3BA",
  "#BAFFC9",
  "#BAE1FF",
  "#FFFFBA",
  "#FFB3FF",
  "#B3FFB3",
  "#B3B3FF",
  "#FFE4B3",
  "#B3FFE4",
  "#FFB3E4",
];

const uniqueCategories = computed(() => {
  const categories = new Set(courses.value.map((course) => course.Category));
  return Array.from(categories).sort();
});

const getCategoryColor = (category: string) => {
  if (!categoryColors.has(category)) {
    const colorIndex = categoryColors.size % pastelColors.length;
    categoryColors.set(category, pastelColors[colorIndex]);
  }
  return categoryColors.get(category);
};

// Handle route query parameters
onMounted(() => {
  const categoryQuery = route.query.category as string;
  if (categoryQuery) {
    activeCategory.value = decodeURIComponent(categoryQuery);
  }
});

const setCategory = (category: string) => {
  activeCategory.value = category;
  if (category) {
    router.push({ query: { ...route.query, category: encodeURIComponent(category) } });
  } else {
    const { category, ...queryWithoutCategory } = route.query;
    router.push({ query: queryWithoutCategory });
  }
};

const filteredCourses = computed(() => {
  return courses.value
    .filter((course) => {
      const matchesSearch =
        searchQuery.value === "" ||
        course.Technology.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        course.Provider.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        course.Category.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        course.Description.toLowerCase().includes(searchQuery.value.toLowerCase());

      const matchesCategory = !activeCategory.value || course.Category === activeCategory.value;

      return matchesSearch && matchesCategory;
    })
    .sort((a, b) => {
      const aValue = a[sortField.value];
      const bValue = b[sortField.value];

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortDirection.value === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return sortDirection.value === "asc" ? (aValue > bValue ? 1 : -1) : bValue > aValue ? 1 : -1;
    });
});

const sortBy = (field: string) => {
  if (sortField.value === field) {
    sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
  } else {
    sortField.value = field;
    sortDirection.value = "asc";
  }
};

const getSortIcon = (field: string) => {
  if (sortField.value !== field) return "bi bi-chevron-expand";
  return sortDirection.value === "asc" ? "bi bi-chevron-up" : "bi bi-chevron-down";
};

const showCourseDetails = (course) => {
  selectedCourse.value = course;
  courseDetailsModal.value.show();
};
</script>

<style scoped>
.dashboard-container {
  padding: 1.5rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.search-box {
  position: relative;
}

.search-box i {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.search-box input {
  padding-left: 2.5rem;
  border-color: #e5e7eb;
}

.courses-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.table-responsive {
  flex: 1;
  overflow: auto;
}

.table {
  margin-bottom: 0;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

.sortable-header {
  cursor: pointer;
  user-select: none;
  background: white;
}

.sortable-header:hover {
  background-color: #f8f9fa;
}

.sort-icon {
  font-size: 0.75rem;
  vertical-align: middle;
}

.category-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.btn-link {
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  color: #1976d2;
}

.btn-link:hover {
  background-color: #f8f9fa;
}

.dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.course-details {
  padding: 0.5rem;
}

.course-title {
  color: #2c3e50;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.3;
}

.provider {
  color: #666;
  font-size: 1rem;
}

.description {
  color: #4a5568;
  line-height: 1.6;
}

tr:hover {
  background-color: #f8f9fa;
  transition: background-color 0.2s ease;
}
</style>
