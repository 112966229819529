<template>
  <div class="setup-profile">
    <Modal title="There's been an error!" ref="errorModal">
      <template #body>There was an error processing this request. Please try again later!</template>
    </Modal>
    <Modal title="This license appears to be incorrect" ref="licenseErrorModal">
      <template #body
        >Check your license is typed in exactly as you received it in your invite email. It should
        have the exact format <b>XXXX-XXXX-XXXX-XXXX</b> with no trailing spaces, additional
        characters etc. This license is case sensitive. It can also only be used once. If you're
        still having problems, please email us at hello@jobbuddytech.com</template
      >
    </Modal>
    <div class="container py-5">
      <div class="form-container">
        <div class="form-header">
          <h1>Complete Your Profile</h1>
          <p class="text-muted">Please fill in your details to get started</p>
        </div>

        <div class="form-sections">
          <!-- Personal Details Section -->
          <div class="section">
            <h2 class="section-title">Personal Details</h2>
            <div class="row g-4">
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    v-model="createUser.name"
                    type="text"
                    class="form-control"
                    :class="{ 'error-input': isSubmitted && !createUser.name }"
                    id="nameInput"
                    placeholder="John Doe"
                  />
                  <label for="nameInput">Full Name</label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    v-model="createUser.email"
                    type="email"
                    class="form-control"
                    id="emailInput"
                    placeholder="name@example.com"
                    disabled
                  />
                  <label for="emailInput">Email</label>
                </div>
              </div>
            </div>
          </div>

          <!-- Professional Details Section -->
          <div class="section">
            <h2 class="section-title">Professional Details</h2>
            <div class="row g-4">
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    v-model="createUser.occupation"
                    type="text"
                    class="form-control"
                    :class="{ 'error-input': isSubmitted && !createUser.occupation }"
                    id="occupationInput"
                    placeholder="Your occupation"
                  />
                  <label for="occupationInput">Current Occupation</label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-floating">
                  <select
                    v-model="createUser.employmentStatus"
                    class="form-select"
                    :class="{ 'error-input': isSubmitted && !createUser.employmentStatus }"
                    id="employmentStatus"
                  >
                    <option value="" disabled selected>Select status</option>
                    <option>Unemployed - Casually browsing</option>
                    <option>Unemployed - Long term unemployed</option>
                    <option>Employed - Looking at new companies</option>
                    <option>Employed - Looking to change career/role</option>
                    <option>Graduate</option>
                  </select>
                  <label for="employmentStatus">Employment Status</label>
                </div>
              </div>
            </div>
          </div>

          <!-- Additional Information Section -->
          <div class="section">
            <h2 class="section-title">Additional Information</h2>
            <div class="row g-4">
              <div class="col-md-4">
                <CountrySelector v-model="createUser.country" />
              </div>

              <div class="col-md-4">
                <div class="form-floating">
                  <select
                    v-model="createUser.gender"
                    class="form-select"
                    :class="{ 'error-input': isSubmitted && !createUser.gender }"
                    id="genderSelect"
                  >
                    <option value="" disabled selected>Select gender</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </select>
                  <label for="genderSelect">Gender</label>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-floating">
                  <select
                    v-model="createUser.whereDidYouHearAboutUs"
                    class="form-select"
                    :class="{ 'error-input': isSubmitted && !createUser.whereDidYouHearAboutUs }"
                    id="sourceSelect"
                  >
                    <option value="" disabled selected>Select source</option>
                    <option>Google</option>
                    <option>Reddit</option>
                    <option>TikTok</option>
                    <option>Twitter</option>
                    <option>A Friend</option>
                    <option>Other</option>
                  </select>
                  <label for="sourceSelect">How did you hear about us?</label>
                </div>
              </div>
            </div>
          </div>

          <!-- Terms Section -->
          <div class="section terms-section">
            <h2 class="section-title">Terms & Conditions</h2>
            <div class="terms-content">
              <h3>About your data...</h3>

              <p>
                JobBuddy may store some of your data in order to improve your experience using the
                service such as saving your data for use between sessions or in order to provide a
                better service. We won't share your data with anybody, except where you have
                consented for us to do so, or doing so is required in order to provide our services.
                By creating an account with JobBuddy, you agree that your data will be shared with
                OpenAI, the proprietors of the machine learning models JobBuddy uses in order to
                provide its services. You are also therefore agreeing to abide by the OpenAI terms,
                which can be found here: https://openai.com/terms. You may be provided the option to
                provide additional explicit consent to allow us to process your data to improve
                JobBuddy's own machine learning capabilities by allowing your data to be used in
                training a model. This is optional and not required in order to use the service.
              </p>

              <h3>1. Terms</h3>

              <p>
                By accessing this web site, you are agreeing to be bound by these web site Terms and
                Conditions of Use, all applicable laws and regulations, and agree that you are
                responsible for compliance with any applicable local laws. If you do not agree with
                any of these terms, you are prohibited from using or accessing this site. The
                materials contained in this web site are protected by applicable copyright and trade
                mark law.
              </p>

              <h3>2. Use License</h3>

              <ol type="a">
                <li>
                  Permission is granted to temporarily download one copy of the materials
                  (information or software) on JobBuddy's web site for personal, non-commercial
                  transitory viewing only. This is the grant of a license, not a transfer of title,
                  and under this license you may not:

                  <ol type="i">
                    <li>modify or copy the materials;</li>
                    <li>
                      use the materials for any commercial purpose, or for any public display
                      (commercial or non-commercial);
                    </li>
                    <li>
                      attempt to decompile or reverse engineer any software contained on JobBuddy's
                      web site;
                    </li>
                    <li>
                      remove any copyright or other proprietary notations from the materials; or
                    </li>
                    <li>
                      transfer the materials to another person or "mirror" the materials on any
                      other server.
                    </li>
                  </ol>
                </li>
                <li>
                  This license shall automatically terminate if you violate any of these
                  restrictions and may be terminated by JobBuddy at any time. Upon terminating your
                  viewing of these materials or upon the termination of this license, you must
                  destroy any downloaded materials in your possession whether in electronic or
                  printed format.
                </li>
              </ol>

              <h3>3. Disclaimer</h3>

              <ol type="a">
                <li>
                  The materials on JobBuddy's web site are provided "as is". JobBuddy makes no
                  warranties, expressed or implied, and hereby disclaims and negates all other
                  warranties, including without limitation, implied warranties or conditions of
                  merchantability, fitness for a particular purpose, or non-infringement of
                  intellectual property or other violation of rights. Further, JobBuddy does not
                  warrant or make any representations concerning the accuracy, likely results, or
                  reliability of the use of the materials on its Internet web site or otherwise
                  relating to such materials or on any sites linked to this site.
                </li>
              </ol>

              <h3>4. Limitations</h3>

              <p>
                In no event shall JobBuddy or its suppliers be liable for any damages (including,
                without limitation, damages for loss of data or profit, or due to business
                interruption,) arising out of the use or inability to use the materials on
                JobBuddy's Internet site, even if JobBuddy or a JobBuddy authorized representative
                has been notified orally or in writing of the possibility of such damage. Because
                some jurisdictions do not allow limitations on implied warranties, or limitations of
                liability for consequential or incidental damages, these limitations may not apply
                to you.
              </p>

              <h3>5. Revisions and Errata</h3>

              <p>
                The materials appearing on JobBuddy's web site could include technical,
                typographical, or photographic errors. JobBuddy does not warrant that any of the
                materials on its web site are accurate, complete, or current. JobBuddy may make
                changes to the materials contained on its web site at any time without notice.
                JobBuddy does not, however, make any commitment to update the materials.
              </p>

              <h3>6. Links</h3>

              <p>
                JobBuddy has not reviewed all of the sites linked to its Internet web site and is
                not responsible for the contents of any such linked site. The inclusion of any link
                does not imply endorsement by JobBuddy of the site. Use of any such linked web site
                is at the user's own risk.
              </p>

              <h3>7. Site Terms of Use Modifications</h3>

              <p>
                JobBuddy may revise these terms of use for its web site at any time without notice.
                By using this web site you are agreeing to be bound by the then current version of
                these Terms and Conditions of Use.
              </p>

              <h3>8. Governing Law</h3>

              <p>
                Any claim relating to JobBuddy's web site shall be governed by the laws of the the
                United Kingdom without regard to its conflict of law provisions.
              </p>

              <p>General Terms and Conditions applicable to Use of a Web Site.</p>

              <h2>Privacy Policy</h2>

              <p>
                Your privacy is very important to us. Accordingly, we have developed this Policy in
                order for you to understand how we collect, use, communicate and disclose and make
                use of personal information. The following outlines our privacy policy.
              </p>

              <ul>
                <li>
                  Before or at the time of collecting personal information, we will identify the
                  purposes for which information is being collected.
                </li>
                <li>
                  We will collect and use of personal information solely with the objective of
                  fulfilling those purposes specified by us and for other compatible purposes,
                  unless we obtain the consent of the individual concerned or as required by law.
                </li>
                <li>
                  We will only retain personal information as long as necessary for the fulfillment
                  of those purposes.
                </li>
                <li>
                  We will collect personal information by lawful and fair means and, where
                  appropriate, with the knowledge or consent of the individual concerned.
                </li>
                <li>
                  Personal data should be relevant to the purposes for which it is to be used, and,
                  to the extent necessary for those purposes, should be accurate, complete, and
                  up-to-date.
                </li>
                <li>
                  We will protect personal information by reasonable security safeguards against
                  loss or theft, as well as unauthorized access, disclosure, copying, use or
                  modification.
                </li>
                <li>
                  We will make readily available to customers information about our policies and
                  practices relating to the management of personal information.
                </li>
              </ul>

              <p>
                We are committed to conducting our business in accordance with these principles in
                order to ensure that the confidentiality of personal information is protected and
                maintained.
              </p>
            </div>
            <div
              class="terms-checkbox"
              :class="{ 'error-terms': isSubmitted && !createUser.hasAgreedToTerms }"
            >
              <label class="custom-checkbox">
                <input v-model="createUser.hasAgreedToTerms" type="checkbox" id="terms-checkbox" />
                <span class="checkmark"></span>
                <span class="label-text">I agree to the terms and conditions</span>
              </label>
            </div>
          </div>

          <!-- Submit Button -->
          <button class="submit-button" @click="createNewUser">
            <span v-if="!loading">Complete Signup</span>
            <div v-else class="spinner-border spinner-border-sm" role="status" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import { onMounted, ref } from "vue";
import router from "@/router";
import store from "@/store";
import { useAuth0 } from "@auth0/auth0-vue";
import { countries } from "../components/CountriesList";
const { user } = useAuth0();

const errorModal = ref(null);
const licenseErrorModal = ref(null);

const loading = ref(false);
const license = ref("");
const createUser = ref({
  name: null,
  email: null,
  occupation: null,
  gender: null,
  employmentStatus: null,
  hasAgreedToTerms: false,
  country: null,
  whereDidYouHearAboutUs: null,
});

const isSubmitted = ref(false);

onMounted(() => {
  createUser.value.email = user.value?.email?.length ? user.value.email : null;
  createUser.value.gender = user.value?.gender ? user.value.gender : null;
  createUser.value.name =
    user.value?.name && user.value.name !== user.value.email ? user.value.name : null;
});

async function createNewUser() {
  isSubmitted.value = true;

  await store.dispatch("user/createUser", createUser.value);
  router.push("/upload-resume");
}
</script>

<style scoped>
.setup-profile {
  background-color: #f5f7fa;
  min-height: 100vh;
  padding: 2rem 0;
}

.form-container {
  max-width: 1000px;
  margin: 0 auto;
  background: white;
  padding: 3rem;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.form-header {
  text-align: center;
  margin-bottom: 3rem;
}

.form-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

.section {
  margin-bottom: 3rem;
  padding: 2rem;
  background: #f8fafc;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 1.5rem;
}

.form-floating {
  margin-bottom: 1rem;
}

.form-control,
.form-select {
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  padding: 1rem;
  height: 3.5rem;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.form-control:focus,
.form-select:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.terms-content {
  max-height: 300px;
  overflow-y: auto;
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  margin-bottom: 1.5rem;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.label-text {
  font-size: 1rem;
  font-weight: 500;
  color: #2d3748;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.2s ease;
}

.submit-button:not(:disabled):hover {
  background: #2563eb;
  transform: translateY(-1px);
}

.submit-button:disabled {
  background: #94a3b8;
  cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-container {
    padding: 1.5rem;
    margin: 1rem;
  }

  .section {
    padding: 1.5rem;
  }
}

.error-input {
  border-color: #ff4444 !important;
  background-color: #fff8f8 !important;
}

.error-input:focus {
  box-shadow: 0 0 0 3px rgba(255, 68, 68, 0.1) !important;
}

.terms-container {
  margin: 20px 0;
  padding: 15px;
  border-radius: 8px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}

.error-terms {
  border-color: #ff4444;
  background-color: #fff8f8;
}

.terms-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  color: #333;
}

.terms-label input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.terms-label a {
  color: #007bff;
  text-decoration: none;
}

.terms-label a:hover {
  text-decoration: underline;
}

.terms-checkbox {
  background: #f8fafc;
  padding: 1rem;
  border-radius: 8px;
  border: 2px solid #f8fafc;
  margin-top: 1rem;
}

.terms-checkbox.error-terms {
  border-color: #ff4444;
  background-color: #fff8f8;
}

.form-floating > .error-input + label {
  color: #ff4444;
}
</style>
