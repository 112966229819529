export enum SubscriptionControlledActions {
  CREATE_RESPONSE = 'createResponse',
  CREATE_APPLICATION = 'createApplication',
  INTERVIEW_PRACTICE = 'interviewPractice',
}

export enum SubscriptionPlan {
  BASIC = 'basic',
  JOB_SEEKER = 'jobSeeker',
  BUSINESS = 'business',
  SILVER = 'silver',
  GOLD = 'gold',
}

export enum ApplicationStatus {
  TO_APPLY,
  APPLIED,
  INTERVIEWING,
  REJECTED,
  OFFERED,
}

export enum Tone {
  FORMAL,
  ENTHUSIASTIC,
  ASSERTIVE,
  CONVERSATIONAL,
}

export enum EmailGenerationType {
  GRACEFUL_REJECTION,
  ACCEPTANCE,
  THANKYOU
}
