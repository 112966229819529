<template>
  <div class="stats-container p-4">
    <div class="row g-4">
      <!-- Applications This Week -->
      <div class="col-md-6">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Applications This Week</h5>
            <v-chart class="chart" :option="weeklyChartOption" autoresize />
          </div>
        </div>
      </div>

      <!-- Applications by Status -->
      <div class="col-md-6">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Applications by Status</h5>
            <v-chart class="chart" :option="statusChartOption" autoresize />
          </div>
        </div>
      </div>

      <!-- Monthly Applications -->
      <div class="col-md-12">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Applications Over Time</h5>
            <v-chart class="chart" :option="monthlyChartOption" autoresize />
          </div>
        </div>
      </div>

      <!-- Applications by Job Board -->
      <div class="col-md-6">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Applications by Job Board</h5>
            <v-chart class="chart" :option="jobBoardChartOption" autoresize />
          </div>
        </div>
      </div>

      <!-- Response Rate by Job Board -->
      <div class="col-md-6">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Response Rate by Job Board</h5>
            <v-chart class="chart" :option="responseRateChartOption" autoresize />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart, PieChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import { ApplicationApiService } from "@/services/ApplicationApiService";
import { ApplicationStatus } from "@models/SystemEnums";

interface Application {
  dateCreated: string;
  applicationStatus?: ApplicationStatus;
  jobUrl?: string;
}

interface DayData {
  date: Date;
  count: number;
  label: string;
}

interface StatusCounts {
  [key: string]: number;
}

interface MonthlyData {
  [key: string]: number;
}

interface JobBoardStats {
  [key: string]: number;
}

interface ChartParams {
  name: string;
  value: number;
}

// Register ECharts components
use([
  CanvasRenderer,
  LineChart,
  BarChart,
  PieChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
]);

const weeklyChartOption = ref({});
const statusChartOption = ref({});
const monthlyChartOption = ref({});
const jobBoardChartOption = ref({});
const responseRateChartOption = ref({});

// Data processing functions (keep existing ones)
function getWeeklyApplicationsData(applications: Application[]): {
  labels: string[];
  data: number[];
} {
  // Get current date in user's local timezone
  const now = new Date();
  const msPerDay = 24 * 60 * 60 * 1000;

  // Get dates for the last 7 days
  const days: DayData[] = [];
  const startOfToday = new Date(now.setHours(0, 0, 0, 0));

  // Build array of the last 7 days
  for (let i = 6; i >= 0; i--) {
    const date = new Date(startOfToday.getTime() - i * msPerDay);
    days.push({
      date: date,
      count: 0,
      label: date.toLocaleDateString("en-US", {
        weekday: "short",
      }),
    });
  }

  // Count applications for each day
  applications.forEach((app: Application) => {
    const appDate = new Date(app.dateCreated);
    // Set to start of day in local timezone
    const startOfAppDay = new Date(appDate.setHours(0, 0, 0, 0));

    // Find matching day
    const dayIndex = days.findIndex((day) => day.date.getTime() === startOfAppDay.getTime());

    if (dayIndex !== -1) {
      days[dayIndex].count++;
    }
  });

  return {
    labels: days.map((d) => d.label),
    data: days.map((d) => d.count),
  };
}

function getApplicationsByStatus(applications: Application[]): {
  labels: string[];
  data: number[];
} {
  const statusCounts: StatusCounts = {};
  const statusLabels = Object.keys(ApplicationStatus).filter((key) => isNaN(Number(key)));

  statusLabels.forEach((status) => {
    statusCounts[status] = 0;
  });

  applications.forEach((app: Application) => {
    if (app.applicationStatus !== undefined) {
      const statusKey = ApplicationStatus[app.applicationStatus];
      if (statusKey) {
        statusCounts[statusKey]++;
      }
    }
  });

  return {
    labels: statusLabels,
    data: statusLabels.map((status) => statusCounts[status]),
  };
}

function getMonthlyApplicationsData(applications: Application[]): {
  labels: string[];
  applications: number[];
  rejections: number[];
} {
  const monthlyData: MonthlyData = {};
  const monthlyRejections: MonthlyData = {};
  const labels: string[] = [];

  for (let i = 11; i >= 0; i--) {
    const date = new Date();
    date.setMonth(date.getMonth() - i);
    const monthKey = date.toLocaleDateString("en-US", { year: "numeric", month: "short" });
    labels.push(monthKey);
    monthlyData[monthKey] = 0;
    monthlyRejections[monthKey] = 0;
  }

  applications.forEach((app: Application) => {
    const appDate = new Date(app.dateCreated);
    const monthKey = appDate.toLocaleDateString("en-US", { year: "numeric", month: "short" });

    if (monthlyData[monthKey] !== undefined) {
      monthlyData[monthKey]++;
      if (app.applicationStatus === ApplicationStatus.REJECTED) {
        monthlyRejections[monthKey]++;
      }
    }
  });

  return {
    labels,
    applications: labels.map((month) => monthlyData[month]),
    rejections: labels.map((month) => monthlyRejections[month]),
  };
}

function getJobBoardStats(applications: Application[]): {
  labels: string[];
  counts: number[];
  responseRates: number[];
} {
  const jobBoardCounts: JobBoardStats = {};
  const jobBoardResponses: JobBoardStats = {};

  applications.forEach((app: Application) => {
    let source = "Other";
    if (app.jobUrl) {
      try {
        const url = new URL(app.jobUrl);
        const hostParts = url.hostname.replace("www.", "").split(".");
        source = hostParts.length > 2 ? hostParts[hostParts.length - 2] : hostParts[0];
        source = source.charAt(0).toUpperCase() + source.slice(1);
      } catch (e) {
        source = "Other";
      }
    }

    jobBoardCounts[source] = (jobBoardCounts[source] || 0) + 1;

    if (
      app.applicationStatus !== undefined &&
      typeof app.applicationStatus === "number" &&
      app.applicationStatus !== ApplicationStatus.TO_APPLY
    ) {
      jobBoardResponses[source] = (jobBoardResponses[source] || 0) + 1;
    }
  });

  const labels = Object.keys(jobBoardCounts);
  const responseRates = labels.map((board) =>
    Math.round(((jobBoardResponses[board] || 0) / jobBoardCounts[board]) * 100)
  );

  return {
    labels,
    counts: labels.map((board) => jobBoardCounts[board]),
    responseRates,
  };
}

function humanizeStatus(status: string | number): string {
  if (typeof status !== "string") return String(status);
  return status
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

// Initialize charts
async function initializeCharts() {
  try {
    const response = await ApplicationApiService.getUserApplicationList();
    if (response.status === 200) {
      const applications = response.data.applications;

      // Weekly Applications Chart
      const weeklyData = getWeeklyApplicationsData(applications);
      weeklyChartOption.value = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          borderWidth: 0,
          textStyle: { color: "#333" },
          extraCssText: "box-shadow: none !important;",
        },
        xAxis: {
          type: "category",
          data: weeklyData.labels,
          axisLine: { lineStyle: { color: "#eee" } },
          axisTick: { show: false },
          axisLabel: { color: "#666" },
        },
        yAxis: {
          type: "value",
          minInterval: 1,
          splitLine: { lineStyle: { color: "#eee" } },
          axisLine: { show: false },
          axisTick: { show: false },
        },
        series: [
          {
            name: "Applications",
            type: "line",
            data: weeklyData.data,
            smooth: true,
            showSymbol: true,
            symbolSize: 8,
            lineStyle: { width: 4 },
            itemStyle: { color: "#87CEEB" },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: "rgba(135, 206, 235, 0.3)" },
                  { offset: 1, color: "rgba(135, 206, 235, 0.05)" },
                ],
              },
            },
          },
        ],
      };

      // Status Chart
      const statusData = getApplicationsByStatus(applications);
      statusChartOption.value = {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          borderWidth: 0,
          textStyle: { color: "#333" },
          extraCssText: "box-shadow: none !important;",
          formatter: function (params: ChartParams) {
            return `${humanizeStatus(params.name)}: ${params.value}`;
          },
        },
        legend: {
          orient: "vertical",
          right: "5%",
          top: "center",
          textStyle: { color: "#666" },
          formatter: function (name: string) {
            return humanizeStatus(name);
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
                formatter: function (params: ChartParams) {
                  return humanizeStatus(params.name);
                },
              },
            },
            data: statusData.labels.map((label, index) => ({
              name: label,
              value: statusData.data[index],
              itemStyle: {
                color: [
                  "#87CEEB", // Sky blue
                  "#4682B4", // Steel blue
                  "#6495ED", // Cornflower blue
                  "#4682B4", // Steel blue
                  "#4169E1", // Royal blue
                  "#87CEEB", // Sky blue
                ][index % 6],
              },
            })),
          },
        ],
      };

      // Monthly Applications Chart
      const monthlyData = getMonthlyApplicationsData(applications);
      monthlyChartOption.value = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          borderWidth: 0,
          textStyle: { color: "#333" },
          extraCssText: "box-shadow: none !important;",
        },
        legend: {
          bottom: "0",
          textStyle: { color: "#666" },
        },
        xAxis: {
          type: "category",
          data: monthlyData.labels,
          axisLine: { lineStyle: { color: "#eee" } },
          axisTick: { show: false },
          axisLabel: { color: "#666" },
        },
        yAxis: {
          type: "value",
          splitLine: { lineStyle: { color: "#eee" } },
          axisLine: { show: false },
          axisTick: { show: false },
        },
        series: [
          {
            name: "Applications",
            type: "bar",
            data: monthlyData.applications,
            itemStyle: {
              color: "#87CEEB", // Sky blue
              borderRadius: [4, 4, 0, 0],
            },
          },
          {
            name: "Rejections",
            type: "bar",
            data: monthlyData.rejections,
            itemStyle: {
              color: "#4682B4", // Steel blue
              borderRadius: [4, 4, 0, 0],
            },
          },
        ],
      };

      // Job Board Charts
      const jobBoardData = getJobBoardStats(applications);

      jobBoardChartOption.value = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          borderWidth: 0,
          textStyle: { color: "#333" },
          extraCssText: "box-shadow: none !important;",
        },
        xAxis: {
          type: "category",
          data: jobBoardData.labels,
          axisLabel: { interval: 0, rotate: 30, color: "#666" },
          axisLine: { lineStyle: { color: "#eee" } },
          axisTick: { show: false },
        },
        yAxis: {
          type: "value",
          splitLine: { lineStyle: { color: "#eee" } },
          axisLine: { show: false },
          axisTick: { show: false },
        },
        series: [
          {
            type: "bar",
            data: jobBoardData.counts.map((value, index) => ({
              value,
              itemStyle: {
                color: [
                  "#87CEEB", // Sky blue
                  "#4682B4", // Steel blue
                  "#6495ED", // Cornflower blue
                  "#4682B4", // Steel blue
                  "#4169E1", // Royal blue
                  "#87CEEB", // Sky blue
                ][index % 6],
                borderRadius: [4, 4, 0, 0],
              },
            })),
          },
        ],
      };

      responseRateChartOption.value = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          borderWidth: 0,
          textStyle: { color: "#333" },
          extraCssText: "box-shadow: none !important;",
          formatter: "{b}: {c}%",
        },
        xAxis: {
          type: "category",
          data: jobBoardData.labels,
          axisLabel: { interval: 0, rotate: 30, color: "#666" },
          axisLine: { lineStyle: { color: "#eee" } },
          axisTick: { show: false },
        },
        yAxis: {
          type: "value",
          max: 100,
          splitLine: { lineStyle: { color: "#eee" } },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            formatter: "{value}%",
          },
        },
        series: [
          {
            type: "bar",
            data: jobBoardData.responseRates.map((value, index) => ({
              value,
              itemStyle: {
                color: [
                  "#87CEEB", // Sky blue
                  "#4682B4", // Steel blue
                  "#6495ED", // Cornflower blue
                  "#4682B4", // Steel blue
                  "#4169E1", // Royal blue
                  "#87CEEB", // Sky blue
                ][index % 6],
                borderRadius: [4, 4, 0, 0],
              },
            })),
          },
        ],
      };
    }
  } catch (error) {
    console.error("Error loading application data:", error);
  }
}

onMounted(() => {
  initializeCharts();
});
</script>

<style scoped>
.stats-container {
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
}

.card {
  box-shadow: none !important;
  border: 1px solid #eee;
}

.card-body {
  height: 400px;
}

.chart {
  width: 100%;
  height: calc(100% - 2rem);
}

:deep(.echarts) {
  box-shadow: none !important;
}

:deep(x-vue-echarts),
:deep(.x-vue-echarts) {
  box-shadow: none !important;
}

:deep(x-vue-echarts div),
:deep(.x-vue-echarts div) {
  box-shadow: none !important;
}

:deep(canvas) {
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .card-body {
    height: 300px;
  }
}
</style>
