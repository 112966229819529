<template>
  <div class="container mt-5">
    <JobDetailsModal :job="selectedJob" @open="openUrl" ref="jobDetailsModal" />
    <div class="m-2 d-flex justify-content-center w-100">
      <h2>Job<span class="text-primary">Buddy</span> Smart Search</h2>
      <i class="bi bi-stars text-primary"></i>
    </div>
    <div class="m-2 d-flex justify-content-center w-100">
      <p>The smart way to find your dream job</p>
    </div>
    <div class="row d-flex justify-content-center align-items-center p-1">
      <div class="col-md-11">
        <div class="form">
          <input type="text" class="form-control form-input" placeholder="Find your dream job..." />
          <span class="left-pan"><i class="bi bi-search"></i></span>
        </div>
      </div>
      <div class="col-md-1">
        <div @click="toggleFilter" class="btn btn-outline-primary btn-filter">
          <i class="bi bi-funnel"> </i>
        </div>
      </div>
    </div>
    <transition name="expand">
      <div v-if="filterExpanded" class="m-1 rounded-3 border">
        <div class="m-1">
          <div class="row d-flex justify-content-center align-items-center p-1">
            <div class="col-md-3">
              <div class="form">
                <input type="text" class="form-control form-input" placeholder="Location" />
                <span class="left-pan"><i class="bi bi-pin"></i></span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form">
                <input type="text" class="form-control form-input" placeholder="Salary" />
                <span class="left-pan"><i class="bi bi-cash-stack"></i></span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form">
                <input type="text" class="form-control form-input" placeholder="Posted On" />
                <span class="left-pan"><i class="bi bi-calendar"></i></span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form">
                <input type="text" class="form-control form-input" placeholder="Remote" />
                <span class="left-pan"><i class="bi bi-laptop"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="pt-5 p-2 d-flex justify-content-center align-items-center">
      <div v-if="jobs.length > 0" class="row table-responsive px-2">
        <div class="col col-md-12 border rounded-3">
          <!-- <span>Today's Top Picks</span> -->
          <table class="table table-hover">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Company</th>
                <th scope="col">Location</th>
                <th scope="col">Salary</th>
                <th scope="col">Posted On</th>
                <th scope="col">Match</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="job in jobs" :key="job.id">
                <td>{{ job.title }}</td>
                <td>{{ job.company }}</td>
                <td>{{ job.location }}</td>
                <td>£{{ job.min_amount }} - £{{ job.max_amount }} P/A</td>
                <td>{{ formatDate(job.date_posted) }}</td>
                <td class="d-flex justify-content-center">
                  <gauge class="gauge" :value="job.match"></gauge>
                </td>
                <td>
                  <button class="btn btn-outline-secondary btn-sm" @click="viewJobDetails(job)">
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="text-center">
        <p>No jobs found.</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import Gauge from "@/components/Gauge.vue";
import { JobSearchService } from "@/services/JobSearchService";
import jobsData from "@/data/jobs";
import JobDetailsModal from "../components/JobDetailsModal.vue";

const jobs = ref([]);
const selectedJob = ref(null);
const filterExpanded = ref(false);
onMounted(() => {
  loadUserJobs();
});

async function loadUserJobs() {
  jobs.value = jobsData.data.jobsForUser.jobs;
  // const response = await JobSearchService.getJobsForUser();
  // jobs.value = JSON.parse(response.data.jobsForUser.jobsForUser.jobs);
}

function toggleFilter() {
  filterExpanded.value = !filterExpanded.value;
}

function formatDate(timestamp: number) {
  const date = new Date(timestamp);
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
}

function viewJobDetails(job: any) {
  selectedJob.value = job;
}

function openUrl(url: string) {
  window.open(url, "_blank");
}
</script>

<style scoped>
body {
  background: #d1d5db;
}

.form {
  position: relative;
}

.form span {
  position: absolute;
  right: 17px;
  top: 13px;
  padding: 2px;
  border-left: 1px solid #d1d5db;
}

.left-pan {
  padding-left: 7px;
}

.left-pan i {
  padding-left: 10px;
}

.btn-filter {
  padding: 9px;
}

.bi-funnel {
  font-size: 1.5rem;
}

.form-input {
  height: 55px;
  text-indent: 33px;
  border-radius: 10px;
}

.form-input:focus {
  box-shadow: none;
}

.container {
  max-width: 1200px;
}

.card {
  border-radius: 10px;
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-subtitle {
  font-size: 1rem;
}

.card-text {
  font-size: 0.9rem;
}

.btn {
  width: 100%;
}

.gauge {
  width: 60px;
  height: 60px;
}
</style>
