import { instance } from './apiProvider/ApiProvider';
import type { AxiosResponse } from 'axios';

export class HumeApiService {
  public static async getAccessToken(): Promise<string> {
    try {
      const axios = await instance();
      const response = await axios.get<{ token: string }>('hume/getAccessToken');
      if (!response.data.token) {
        throw new Error('No token received from server');
      }
      return response.data.token;
    } catch (error) {
      console.error('Failed to get Hume access token:', error);
      throw error;
    }
  }

  public static async recordInterviewTime(minutes: number): Promise<AxiosResponse<void>> {
    try {
      const axios = await instance();
      return await axios.post('hume/recordInterviewTime', { minutes });
    } catch (error: any) {
      console.error('Error recording interview time:', error.message);
      throw new Error('Failed to record interview time');
    }
  }

  public static async analyzeInterviewTranscript (transcript: string, applicationId: string): Promise<AxiosResponse<any>> {
    try {
      const axios = await instance();
      return await axios.post('hume/analyzeInterviewTranscript', { transcript, applicationId });
    } catch (error: any) {
      console.error('Error analyzing interview transcript:', error.message);
      throw new Error('Failed to analyze interview transcript');
    }
  }
}
