<template>
  <div class="resume-builder">
    <!-- Smart Import Bar -->
    <div class="container">
      <div class="smart-import">
        <div class="container d-flex justify-content-between align-items-center">
          <span>🚀 Speed up the process</span>
          <button class="btn-import" @click="showImportModal">
            <i class="bi bi-cloud-upload me-2"></i>
            Import from Resume
          </button>
        </div>
      </div>
      <div class="resume-content">
        <!-- Left Column -->
        <div class="progress-column">
          <div class="progress-card">
            <div class="profile-section">
              <div class="profile-avatar" :style="{ background: getAvatarColor() }">
                {{ getInitials() }}
              </div>
              <div class="profile-info">
                <h3>{{ resume.contactInfo?.firstName || "Your Profile" }}</h3>
                <div class="progress-bar">
                  <div class="progress" :style="{ width: `${completionPercentage}%` }"></div>
                </div>
                <span class="progress-text">{{ completionPercentage }}% Complete</span>
              </div>
            </div>
            <div class="section-nav-container">
              <div class="section-nav">
                <button
                  v-for="section in sections"
                  :key="section.id"
                  class="section-btn"
                  :class="{
                    active: currentSection === section.id,
                    completed: isSectionComplete(section.id),
                  }"
                  @click="setSection(section.id)"
                >
                  <i :class="section.icon"></i>
                  <span>{{ section.title }}</span>
                  <i
                    v-if="isSectionComplete(section.id)"
                    class="bi bi-check-circle-fill check-icon"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Main Content -->
        <div class="main-column">
          <div class="content-card">
            <div class="save-button-container" v-if="hasUnsavedChanges">
              <button class="btn-save-resume" @click="saveResume">
                <i class="bi bi-save me-2"></i>Save Changes
              </button>
            </div>
            <transition name="fade" mode="out-in">
              <!-- Contact Information -->
              <div v-if="currentSection === 'contact'" key="contact" class="section">
                <div class="section-header">
                  <h2>Contact Information</h2>
                  <p>Let's start with your basic details</p>
                </div>

                <div class="form-grid">
                  <div class="form-group">
                    <label>First Name</label>
                    <input
                      v-model="resume.contactInfo.firstName"
                      type="text"
                      placeholder="e.g., John"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Surname</label>
                    <input
                      v-model="resume.contactInfo.surname"
                      type="text"
                      placeholder="e.g., Smith"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-span-2">
                    <label>Location</label>
                    <input
                      v-model="resume.contactInfo.location"
                      type="text"
                      placeholder="e.g., London, United Kingdom"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Email</label>
                    <input v-model="resume.contactInfo.email" type="email" class="form-control" />
                  </div>
                  <div class="form-group">
                    <label>Phone</label>
                    <input v-model="resume.contactInfo.phone" type="tel" class="form-control" />
                  </div>
                  <div class="form-group">
                    <label>LinkedIn (Optional)</label>
                    <input v-model="resume.contactInfo.linkedin" type="url" class="form-control" />
                  </div>
                  <div class="form-group">
                    <label>Portfolio/Website (Optional)</label>
                    <input
                      v-model="resume.contactInfo.websiteOrPortfolio"
                      type="url"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              <!-- Professional Summary -->
              <div v-else-if="currentSection === 'summary'" key="summary" class="section">
                <div class="section-header">
                  <h2>Professional Summary</h2>
                  <p>Write a brief overview of your career and goals</p>
                </div>

                <div class="form-grid">
                  <div class="form-group col-span-2">
                    <label>Summary Title</label>
                    <input
                      v-model="resume.summary.title"
                      type="text"
                      placeholder="e.g., Career Objective, Professional Summary"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-span-2">
                    <label>Description</label>
                    <textarea
                      v-model="resume.summary.description"
                      rows="4"
                      placeholder="Describe your professional background and career goals..."
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>

              <!-- Work Experience -->
              <div v-else-if="currentSection === 'work'" key="work" class="section">
                <div class="section-header">
                  <div>
                    <h2>Work Experience</h2>
                    <p>Add your professional experience</p>
                  </div>
                  <button class="btn-add" @click="addWorkExperience">
                    <i class="bi bi-plus-lg"></i>
                    Add Position
                  </button>
                </div>

                <TransitionGroup name="list" tag="div" class="experience-list">
                  <div
                    v-for="(work, index) in resume.workExperience"
                    :key="index"
                    class="experience-card"
                  >
                    <div class="experience-header">
                      <input
                        v-model="work.role"
                        type="text"
                        placeholder="Position Title"
                        class="title-input"
                      />
                      <button class="btn-remove" @click="removeWorkExperience(index)">
                        <i class="bi bi-trash"></i>
                      </button>
                    </div>

                    <div class="form-grid">
                      <div class="form-group">
                        <label>Company</label>
                        <input v-model="work.company" type="text" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Location</label>
                        <input v-model="work.location" type="text" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Dates</label>
                        <input v-model="work.dates" type="text" class="form-control" />
                      </div>
                      <div class="form-group col-span-2">
                        <label>Achievements</label>
                        <div class="achievements-list">
                          <div
                            v-for="(achievement, aIndex) in work.achievements"
                            :key="aIndex"
                            class="achievement-item"
                          >
                            <input
                              v-model="work.achievements[aIndex]"
                              type="text"
                              class="form-control"
                              placeholder="Add key achievement"
                            />
                            <button
                              class="btn-remove-small"
                              @click="removeAchievement(work, aIndex)"
                            >
                              <i class="bi bi-x-lg"></i>
                            </button>
                          </div>
                          <button class="btn-add-achievement" @click="addAchievement(work)">
                            <i class="bi bi-plus-lg"></i>
                            Add Achievement
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </TransitionGroup>
              </div>

              <!-- Education -->
              <div v-else-if="currentSection === 'education'" key="education" class="section">
                <div class="section-header">
                  <div>
                    <h2>Education</h2>
                    <p>Add your educational background</p>
                  </div>
                  <button class="btn-add" @click="addEducation">
                    <i class="bi bi-plus-lg"></i>
                    Add Education
                  </button>
                </div>

                <TransitionGroup name="list" tag="div" class="experience-list">
                  <div
                    v-for="(edu, index) in resume.education"
                    :key="index"
                    class="experience-card"
                  >
                    <div class="experience-header">
                      <input
                        v-model="edu.course"
                        type="text"
                        placeholder="Degree / Course Title"
                        class="title-input"
                      />
                      <button class="btn-remove" @click="removeEducation(index)">
                        <i class="bi bi-trash"></i>
                      </button>
                    </div>

                    <div class="form-grid">
                      <div class="form-group">
                        <label>Institution</label>
                        <input v-model="edu.instituteName" type="text" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Location</label>
                        <input v-model="edu.location" type="text" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Dates</label>
                        <input v-model="edu.dates" type="text" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Grade/Classification</label>
                        <input
                          v-model="edu.gradeOrClassification"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-span-2">
                        <label>Specialism (Optional)</label>
                        <input v-model="edu.specialism" type="text" class="form-control" />
                      </div>
                      <div class="form-group col-span-2">
                        <label>Modules Section Title</label>
                        <input
                          v-model="edu.modulesTitle"
                          type="text"
                          placeholder="e.g., Key Modules, Relevant Coursework"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-span-2">
                        <label>Modules</label>
                        <div class="modules-list">
                          <div
                            v-for="(module, mIndex) in edu.modules"
                            :key="mIndex"
                            class="module-item"
                          >
                            <input
                              v-model="module.module"
                              type="text"
                              class="form-control"
                              placeholder="Module name"
                            />
                            <input
                              v-model="module.grade"
                              type="text"
                              class="form-control grade-input"
                              placeholder="Grade"
                            />
                            <button class="btn-remove-small" @click="removeModule(edu, mIndex)">
                              <i class="bi bi-x-lg"></i>
                            </button>
                          </div>
                          <button class="btn-add-achievement" @click="addModule(edu)">
                            <i class="bi bi-plus-lg"></i>
                            Add Module
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </TransitionGroup>
              </div>

              <!-- Activities Section -->
              <div v-else-if="currentSection === 'activities'" key="activities" class="section">
                <div class="section-header">
                  <div>
                    <h2>Activities & Leadership</h2>
                    <p>Add your extracurricular activities and leadership roles</p>
                  </div>
                  <button class="btn-add" @click="addActivity">
                    <i class="bi bi-plus-lg"></i>
                    Add Activity
                  </button>
                </div>

                <TransitionGroup name="list" tag="div" class="experience-list">
                  <div
                    v-for="(activity, index) in resume.activities"
                    :key="index"
                    class="experience-card"
                  >
                    <div class="experience-header">
                      <input
                        v-model="activity.activityName"
                        type="text"
                        placeholder="Activity Name"
                        class="title-input"
                      />
                      <button class="btn-remove" @click="removeActivity(index)">
                        <i class="bi bi-trash"></i>
                      </button>
                    </div>

                    <div class="form-grid">
                      <div class="form-group">
                        <label>Role</label>
                        <input v-model="activity.role" type="text" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Location</label>
                        <input v-model="activity.location" type="text" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Dates</label>
                        <input v-model="activity.dates" type="text" class="form-control" />
                      </div>
                      <div class="form-group col-span-2">
                        <label>Achievements</label>
                        <div class="achievements-list">
                          <div
                            v-for="(achievement, aIndex) in activity.achievements"
                            :key="aIndex"
                            class="achievement-item"
                          >
                            <input
                              v-model="activity.achievements[aIndex]"
                              type="text"
                              class="form-control"
                              placeholder="Add achievement"
                            />
                            <button
                              class="btn-remove-small"
                              @click="removeActivityAchievement(activity, aIndex)"
                            >
                              <i class="bi bi-x-lg"></i>
                            </button>
                          </div>
                          <button
                            class="btn-add-achievement"
                            @click="addActivityAchievement(activity)"
                          >
                            <i class="bi bi-plus-lg"></i>
                            Add Achievement
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </TransitionGroup>
              </div>

              <!-- Skills Section -->
              <div v-else-if="currentSection === 'skills'" key="skills" class="section">
                <div class="section-header">
                  <div>
                    <h2>Skills</h2>
                    <p>Add your key skills grouped by category</p>
                  </div>
                  <button class="btn-add" @click="addSkillGroup">
                    <i class="bi bi-plus-lg"></i>
                    Add Skill Group
                  </button>
                </div>

                <div class="skills-list">
                  <div
                    v-for="(skill, index) in resume.additionalSkills"
                    :key="index"
                    class="skill-group-card"
                  >
                    <div class="skill-group-header">
                      <input
                        v-model="skill.skillGroup"
                        type="text"
                        placeholder="Skill Category (e.g., Programming Languages)"
                        class="title-input"
                      />
                      <button class="btn-remove" @click="removeSkillGroup(index)">
                        <i class="bi bi-trash"></i>
                      </button>
                    </div>
                    <div class="form-group">
                      <input
                        v-model="skill.skillDetail"
                        type="text"
                        placeholder="List skills (e.g., Python, Java, C++)"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Projects Section -->
              <div v-else-if="currentSection === 'projects'" key="projects" class="section">
                <div class="section-header">
                  <div>
                    <h2>Projects</h2>
                    <p>Showcase your key projects</p>
                  </div>
                  <button class="btn-add" @click="addProject">
                    <i class="bi bi-plus-lg"></i>
                    Add Project
                  </button>
                </div>

                <TransitionGroup name="list" tag="div" class="experience-list">
                  <div
                    v-for="(project, index) in resume.projects"
                    :key="index"
                    class="experience-card"
                  >
                    <div class="experience-header">
                      <input
                        v-model="project.name"
                        type="text"
                        placeholder="Project Name"
                        class="title-input"
                      />
                      <button class="btn-remove" @click="removeProject(index)">
                        <i class="bi bi-trash"></i>
                      </button>
                    </div>

                    <div class="form-grid">
                      <div class="form-group col-span-2">
                        <label>Description</label>
                        <textarea
                          v-model="project.description"
                          rows="3"
                          class="form-control"
                          placeholder="Brief description of the project"
                        ></textarea>
                      </div>
                      <div class="form-group col-span-2">
                        <label>Technologies Used</label>
                        <input
                          v-model="project.technologies"
                          type="text"
                          class="form-control"
                          placeholder="e.g., React, Node.js, MongoDB"
                        />
                      </div>
                      <div class="form-group col-span-2">
                        <label>Impact/Outcome</label>
                        <input
                          v-model="project.impact"
                          type="text"
                          class="form-control"
                          placeholder="What was achieved? e.g., Increased efficiency by 50%"
                        />
                      </div>
                    </div>
                  </div>
                </TransitionGroup>
              </div>

              <!-- Languages Section -->
              <div v-else-if="currentSection === 'languages'" key="languages" class="section">
                <div class="section-header">
                  <div>
                    <h2>Languages</h2>
                    <p>Add languages you speak</p>
                  </div>
                  <button class="btn-add" @click="addLanguage">
                    <i class="bi bi-plus-lg"></i>
                    Add Language
                  </button>
                </div>

                <div class="languages-list">
                  <div
                    v-for="(language, index) in resume.languages"
                    :key="index"
                    class="language-item"
                  >
                    <input
                      v-model="resume.languages[index]"
                      type="text"
                      class="form-control"
                      placeholder="e.g., English (Native), Spanish (Fluent)"
                    />
                    <button class="btn-remove-small" @click="removeLanguage(index)">
                      <i class="bi bi-x-lg"></i>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Interests Section -->
              <div v-else-if="currentSection === 'interests'" key="interests" class="section">
                <div class="section-header">
                  <div>
                    <h2>Interests</h2>
                    <p>Share your hobbies and interests</p>
                  </div>
                  <button class="btn-add" @click="addInterest">
                    <i class="bi bi-plus-lg"></i>
                    Add Interest
                  </button>
                </div>

                <div class="interests-list">
                  <div
                    v-for="(interest, index) in resume.interests"
                    :key="index"
                    class="interest-item"
                  >
                    <input
                      v-model="resume.interests[index]"
                      type="text"
                      class="form-control"
                      placeholder="e.g., Photography, Chess, Mountain Biking"
                    />
                    <button class="btn-remove-small" @click="removeInterest(index)">
                      <i class="bi bi-x-lg"></i>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Certifications Section -->
              <div
                v-else-if="currentSection === 'certifications'"
                key="certifications"
                class="section"
              >
                <div class="section-header">
                  <div>
                    <h2>Certifications</h2>
                    <p>Add your professional certifications and qualifications</p>
                  </div>
                  <button class="btn-add" @click="addCertification">
                    <i class="bi bi-plus-lg"></i>
                    Add Certification
                  </button>
                </div>

                <TransitionGroup name="list" tag="div" class="experience-list">
                  <div
                    v-for="(cert, index) in resume.certifications"
                    :key="index"
                    class="experience-card"
                  >
                    <div class="experience-header">
                      <input
                        v-model="cert.name"
                        type="text"
                        placeholder="Certification Name"
                        class="title-input"
                      />
                      <button class="btn-remove" @click="removeCertification(index)">
                        <i class="bi bi-trash"></i>
                      </button>
                    </div>

                    <div class="form-grid">
                      <div class="form-group">
                        <label>Issuing Organization</label>
                        <input
                          v-model="cert.issuedBy"
                          type="text"
                          class="form-control"
                          placeholder="e.g., AWS, Microsoft, Google"
                        />
                      </div>
                      <div class="form-group">
                        <label>Issue Date</label>
                        <input
                          v-model="cert.issueDate"
                          type="text"
                          class="form-control"
                          placeholder="e.g., June 2023"
                        />
                      </div>
                    </div>
                  </div>
                </TransitionGroup>
              </div>

              <!-- Would you like me to continue with the remaining sections? -->
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.resume-builder {
  padding: 2rem;
}

.smart-import {
  background: #0c6cfdc0;
  color: white;
  padding: 0.75rem 0;
  margin-bottom: 2rem;
  border-radius: 8px;
}

.btn-import {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.btn-import:hover {
  background: rgba(255, 255, 255, 0.3);
}

.resume-content {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
  margin: 0 auto;
  max-height: 100%;
}

.progress-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  position: sticky;
  top: 2rem;
  display: flex;
  flex-direction: column;
  max-height: 75vh;
}

.profile-section {
  text-align: center;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e5e7eb;
  flex-shrink: 0;
}

.profile-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}

.progress-bar {
  height: 6px;
  background: #e5e7eb;
  border-radius: 3px;
  margin: 1rem 0 0.5rem;
}

.progress {
  height: 100%;
  background: #0c6efd;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 0.875rem;
  color: #6b7280;
}

.section-nav-container {
  overflow-y: auto;
  flex-grow: 1;
  min-height: 0;
}
.section-nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;
}

.section-btn {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  border: none;
  background: transparent;
  border-radius: 8px;
  color: #6b7280;
  transition: all 0.2s ease;
  text-align: left;
}

.section-btn:hover {
  background: #f3f4f6;
}

.section-btn.active {
  background: #0c6efd;
  color: white;
}

.section-btn.completed {
}

.check-icon {
  margin-left: auto;
}

.content-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  position: sticky;
  top: 2rem;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.section-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.25rem;
}

.section-header p {
  color: #6b7280;
  margin: 0;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group.col-span-2 {
  grid-column: span 2;
}

label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.form-control {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  background: #f9fafb;
}

.form-control:focus {
  outline: none;
  border-color: #0c6efd;
  background: white;
  box-shadow: 0 0 0 3px rgba(12, 110, 253, 0.1);
}

.experience-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.experience-card {
  background: #f9fafb;
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid #e5e7eb;
  transition: all 0.2s ease;
}

.experience-card:hover {
  border-color: #0c6efd;
  box-shadow: 0 4px 6px -1px rgba(12, 110, 253, 0.1);
}

.experience-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.title-input {
  font-size: 1.125rem;
  font-weight: 500;
  border: none;
  background: transparent;
  color: #111827;
  width: 100%;
  padding: 0.5rem;
  border-radius: 6px;
}

.title-input:focus {
  outline: none;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.btn-remove {
  color: #ef4444;
  border: none;
  background: none;
  padding: 0.5rem;
  border-radius: 6px;
  opacity: 0;
  transition: all 0.2s ease;
}

.experience-card:hover .btn-remove {
  opacity: 1;
}

.btn-remove:hover {
  background: #fee2e2;
}

.achievements-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.achievement-item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.btn-remove-small {
  color: #6b7280;
  border: none;
  background: none;
  padding: 0.25rem;
  border-radius: 4px;
  opacity: 0;
  transition: all 0.2s ease;
}

.achievement-item:hover .btn-remove-small {
  opacity: 1;
}

.btn-remove-small:hover {
  background: #f3f4f6;
  color: #ef4444;
}

.btn-add-achievement {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #0c6efd;
  background: white;
  border: 1px dashed #e5e7eb;
  padding: 0.75rem;
  border-radius: 8px;
  width: 100%;
  justify-content: center;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.btn-add-achievement:hover {
  border-color: #0c6efd;
  background: rgba(12, 110, 253, 0.1);
}

.btn-add {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #0c6efd;
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-add:hover {
  background: #0b5ed7;
  transform: translateY(-1px);
}

.navigation-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #e5e7eb;
}

.btn-secondary {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: white;
  border: 1px solid #e5e7eb;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  transition: all 0.2s ease;
}

.btn-secondary:hover {
  background: #f9fafb;
  border-color: #d1d5db;
}

.btn-primary {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #0c6efd;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-primary:hover {
  background: #0b5ed7;
  transform: translateY(-1px);
}

.save-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 1.5rem;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 1.5rem;
}

.btn-save-resume {
  display: inline-flex;
  align-items: center;
  padding: 0.625rem 1.25rem;
  background: #0c6efd;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn-save-resume:hover {
  background: #0b5ed7;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-save-resume:active {
  transform: translateY(0);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn-save-resume i {
  font-size: 1rem;
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.progress-card {
  max-height: 80vh;
}

.with-sidebar {
  overflow: hidden;
}

.resume-builder {
  overflow: hidden;
}
@media (max-width: 768px) {
  .resume-content {
    grid-template-columns: 1fr;
  }

  .progress-card {
    position: relative;
    top: 0;
    margin-bottom: 1rem;
  }
}
</style>

<script setup lang="ts">
import { ref, computed, onMounted, nextTick, watch } from "vue";
import { DocumentApiService } from "@/services/DocumentApiService";
import type { Resume } from "@models/Resume";
import store from "@/store";
import User from "@models/User";
import router from "../router";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const sections = [
  { id: "contact", title: "Contact Information", icon: "bi bi-person-vcard" },
  { id: "summary", title: "Professional Summary", icon: "bi bi-file-text" },
  { id: "work", title: "Work Experience", icon: "bi bi-briefcase" },
  { id: "education", title: "Education", icon: "bi bi-mortarboard" },
  { id: "activities", title: "Activities", icon: "bi bi-trophy" },
  { id: "skills", title: "Skills", icon: "bi bi-stars" },
  { id: "certifications", title: "Certifications", icon: "bi bi-patch-check" },
  { id: "projects", title: "Projects", icon: "bi bi-folder" },
  { id: "languages", title: "Languages", icon: "bi bi-translate" },
  { id: "interests", title: "Interests", icon: "bi bi-heart" },
];

const currentSection = ref("contact");
const resume = ref<Resume>({
  userId: "",
  contactInfo: {
    firstName: "",
    surname: "",
    email: "",
    location: "",
    phone: "",
    linkedin: "",
    websiteOrPortfolio: "",
  },
  summary: {
    title: "",
    description: "",
  },
  workExperience: [
    {
      company: "",
      location: "",
      role: "",
      dates: "",
      achievements: [""],
    },
  ],
  education: [
    {
      instituteName: "",
      location: "",
      course: "",
      dates: "",
      specialism: "",
      gradeOrClassification: "",
      modulesTitle: "",
      modules: [{ module: "", grade: "" }],
    },
  ],
  activities: [
    {
      activityName: "",
      location: "",
      role: "",
      dates: "",
      achievements: [""],
    },
  ],
  certifications: [
    {
      name: "",
      issuedBy: "",
      issueDate: "",
    },
  ],
  additionalSkills: [
    {
      skillGroup: "",
      skillDetail: "",
    },
  ],
  projects: [
    {
      name: "",
      description: "",
      technologies: [],
      impact: "",
    },
  ],
  languages: [""],
  interests: [""],
});

const originalResume = ref<Resume | null>(null);
const hasUnsavedChanges = ref(false);

// Watch for changes in the resume object
watch(
  () => JSON.stringify(resume.value),
  (newValue) => {
    if (originalResume.value) {
      hasUnsavedChanges.value = newValue !== JSON.stringify(originalResume.value);
    }
  },
  { deep: true }
);

const completionPercentage = computed(() => {
  let completed = 0;
  let total = 0;

  // Contact Info section
  const contactFields = ["firstName", "surname", "email", "phone", "location"] as const;
  contactFields.forEach((field) => {
    total++;
    if (resume.value.contactInfo[field]) completed++;
  });

  // Summary section
  if (resume.value.summary.description) completed++;
  total++;

  // Work Experience section
  if (resume.value.workExperience?.length) {
    resume.value.workExperience.forEach((work) => {
      ["company", "role", "dates", "location"].forEach((field) => {
        total++;
        if (work[field as keyof typeof work]) completed++;
      });
      if (work.achievements?.length) {
        total++;
        if (work.achievements.some((a) => a.trim())) completed++;
      }
    });
  }

  // Education section
  if (resume.value.education?.length) {
    resume.value.education.forEach((edu) => {
      ["instituteName", "course", "dates"].forEach((field) => {
        total++;
        if (edu[field as keyof typeof edu]) completed++;
      });
    });
  }

  // Calculate percentage
  return Math.round((completed / total) * 100) || 0;
});

function getAvatarColor() {
  return "rgba(12, 110, 253, 0.5)"; // Bootstrap blue with 50% transparency
}

function getInitials() {
  const firstName = resume.value.contactInfo?.firstName;
  const surname = resume.value.contactInfo?.surname;
  if (!firstName && !surname) return "?";
  return `${firstName?.[0] || ""}${surname?.[0] || ""}`.toUpperCase();
}

function isSectionComplete(sectionId: string): boolean {
  switch (sectionId) {
    case "contact":
      return !!(
        resume.value.contactInfo?.firstName &&
        resume.value.contactInfo?.surname &&
        resume.value.contactInfo?.email
      );
    case "summary":
      return !!resume.value.summary.description;
    case "work":
      return (
        resume.value.workExperience?.length > 0 &&
        resume.value.workExperience.every((w) => w.company && w.role)
      );
    case "education":
      return (
        resume.value.education?.length > 0 &&
        resume.value.education.every((e) => e.instituteName && e.course)
      );
    default:
      return false;
  }
}

function setSection(sectionId: string) {
  currentSection.value = sectionId;
}

function addWorkExperience() {
  resume.value.workExperience.unshift({
    company: "",
    location: "",
    role: "",
    dates: "",
    achievements: [""],
  });
}

function removeWorkExperience(index: number) {
  resume.value.workExperience.splice(index, 1);
}

function addAchievement(work: WorkExperience) {
  work.achievements.push("");
}

function removeAchievement(work: WorkExperience, index: number) {
  work.achievements.splice(index, 1);
}

const isFirstSection = computed(() => currentSection.value === sections[0].id);
const isLastSection = computed(() => currentSection.value === sections[sections?.length - 1].id);

function nextSection() {
  const currentIndex = sections.findIndex((s) => s.id === currentSection.value);
  if (currentIndex < sections?.length - 1) {
    currentSection.value = sections[currentIndex + 1].id;
  }
}

function previousSection() {
  const currentIndex = sections.findIndex((s) => s.id === currentSection.value);
  if (currentIndex > 0) {
    currentSection.value = sections[currentIndex - 1].id;
  }
}

function showImportModal() {
  router.push({
    name: "UploadResume",
    query: { redirect: "applicantDetails" },
  });
}

function addEducation() {
  resume.value.education.unshift({
    instituteName: "",
    location: "",
    course: "",
    dates: "",
    specialism: "",
    gradeOrClassification: "",
    modulesTitle: "",
    modules: [{ module: "", grade: "" }],
  });
}

function removeEducation(index: number) {
  resume.value.education.splice(index, 1);
}

function addModule(education: Education) {
  if (!education.modules) education.modules = [];
  education.modules.push({ module: "", grade: "" });
}

function removeModule(education: Education, index: number) {
  education.modules?.splice(index, 1);
}

function addSkill() {
  resume.value.skills.push("");
}

function removeSkill(index: number) {
  resume.value.skills.splice(index, 1);
}

function addCertification() {
  if (!resume.value.certifications) {
    resume.value.certifications = [];
  }
  resume.value.certifications.push({
    name: "",
    issuedBy: "",
    issueDate: "",
  });
}

function removeCertification(index: number) {
  resume.value.certifications?.splice(index, 1);
}

function addInterest() {
  resume.value.interests.push("");
}

function removeInterest(index: number) {
  resume.value.interests.splice(index, 1);
}

function addActivity() {
  if (!resume.value.activities) resume.value.activities = [];
  resume.value.activities.unshift({
    activityName: "",
    location: "",
    role: "",
    dates: "",
    achievements: [""],
  });
}

function removeActivity(index: number) {
  resume.value.activities?.splice(index, 1);
}

function addActivityAchievement(activity: Activity) {
  activity.achievements.push("");
}

function removeActivityAchievement(activity: Activity, index: number) {
  activity.achievements.splice(index, 1);
}

function addSkillGroup() {
  if (!resume.value.additionalSkills) resume.value.additionalSkills = [];
  resume.value.additionalSkills.push({
    skillGroup: "",
    skillDetail: "",
  });
}

function removeSkillGroup(index: number) {
  resume.value.additionalSkills?.splice(index, 1);
}

function addProject() {
  if (!resume.value.projects) resume.value.projects = [];
  resume.value.projects.push({
    name: "",
    description: "",
    technologies: [],
    impact: "",
  });
}

function removeProject(index: number) {
  resume.value.projects?.splice(index, 1);
}

function addLanguage() {
  if (!resume.value.languages) resume.value.languages = [];
  resume.value.languages.push("");
}

function removeLanguage(index: number) {
  resume.value.languages?.splice(index, 1);
}

// Add fetch resume function
const fetchResume = async () => {
  try {
    console.log("Fetching resume for user:", user.value._id);
    const result = await DocumentApiService.getUserResume(user.value._id);

    if (result) {
      const userResume = result.data;
      // Create the new resume object
      const newResume = {
        userId: userResume.userId || user.value._id,
        contactInfo: userResume.contactInfo || {},
        summary: userResume.summary || { title: "", description: "" },
        workExperience: userResume.workExperience || [],
        education: userResume.education || [],
        activities: userResume.activities || [],
        certifications: userResume.certifications || [],
        additionalSkills: userResume.additionalSkills || [],
        projects: userResume.projects || [],
        languages: userResume.languages || [],
        interests: userResume.interests || [],
      };

      console.log("New resume object:", newResume);

      // Use nextTick to ensure the UI updates
      nextTick(() => {
        resume.value = newResume;
        originalResume.value = JSON.parse(JSON.stringify(newResume)); // Store a deep copy
        hasUnsavedChanges.value = false;
        console.log("Resume after assignment:", resume.value);
      });
    }
  } catch (error) {
    console.error("Error fetching resume:", error);
  }
};

const user: Ref<User> = ref({});
const loadUser = async () => {
  user.value = await store.getters["user/getLoggedInUser"];
  if (user.value) {
    resume.value.userId = user.value._id;
  }
};

// Add save resume function for future use
const saveResume = async () => {
  try {
    await DocumentApiService.updateUserResume(resume.value);
    originalResume.value = JSON.parse(JSON.stringify(resume.value)); // Update original state
    hasUnsavedChanges.value = false; // Reset changes flag
    toast.success("Your details have been saved successfully!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  } catch (error) {
    console.error("Error saving resume:", error);
    toast.error("Failed to save your details. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  }
};

// Add onMounted hook to fetch resume data when component loads
onMounted(async () => {
  console.log("Component mounted");
  try {
    await loadUser();
    console.log("User loaded:", user.value);
    await fetchResume();
  } catch (error) {
    console.error("Error in onMounted:", error);
  }
});
</script>
