<template>
  <BreadcrumbNav />
  <div class="match-analysis-container">
    <div class="header">
      <h1 class="title">Match Analysis</h1>
      <p class="subtitle">
        Your resume match analysis with {{ application.roleTitle }} at
        {{ application.companyName }}
      </p>

      <div class="content-wrapper">
        <!-- Loading overlay -->
        <div class="loading-overlay" :class="{ visible: isLoading }">
          <div class="loading-spinner"></div>
        </div>

        <!-- Empty State -->
        <div
          v-if="!analyzed && !isInitialLoading"
          class="empty-state text-center py-5"
          :class="{ loading: isLoading }"
        >
          <div class="empty-state-icon mb-4">
            <i class="bi bi-bar-chart display-1 text-muted"></i>
          </div>
          <h4 class="mb-3">No Match Analysis Yet</h4>
          <p class="text-muted mb-4">
            Click analyze to see how well your resume matches this role.
          </p>
          <button class="btn btn-primary" @click="analyzeResume" :disabled="isLoading">
            <span v-if="isLoading" class="loading-spinner"></span>
            <i v-else class="bi bi-magic me-2"></i>
            <span>{{ isLoading ? "Analyzing..." : "Analyze Resume Match" }}</span>
          </button>
        </div>

        <!-- Analysis Content -->
        <div v-else-if="!isInitialLoading" class="analysis-content" :class="{ loading: isLoading }">
          <div class="overall-score-card">
            <div class="analyze-button" v-if="!analyzed">
              <button class="btn btn-primary" @click="analyzeResume" :disabled="isLoading">
                <span v-if="isLoading" class="loading-spinner"></span>
                <span>{{ isLoading ? "Analyzing..." : "Analyze Resume Match" }}</span>
              </button>
            </div>
            <div class="score-content" v-else>
              <div class="score-details">
                <h2>Overall Match</h2>
                <div class="score-stats">
                  <div v-if="hasKeywordRequirements" class="stat">
                    <div class="stat-label">Keywords</div>
                    <div class="stat-value" :class="getScoreClass(matchData.keywordMatch)">
                      {{ matchData.keywordMatch === -1 ? "N/A" : `${matchData.keywordMatch}%` }}
                    </div>
                  </div>
                  <div v-if="hasSkillRequirements" class="stat">
                    <div class="stat-label">Skills</div>
                    <div class="stat-value" :class="getScoreClass(matchData.skillsMatch)">
                      {{ matchData.skillsMatch === -1 ? "N/A" : `${matchData.skillsMatch}%` }}
                    </div>
                  </div>
                  <div v-if="hasExperienceRequirements" class="stat">
                    <div class="stat-label">Experience</div>
                    <div class="stat-value" :class="getScoreClass(matchData.experienceMatch)">
                      {{
                        matchData.experienceMatch === -1 ? "N/A" : `${matchData.experienceMatch}%`
                      }}
                    </div>
                  </div>
                  <div v-if="hasEducationRequirements" class="stat">
                    <div class="stat-label">Education</div>
                    <div class="stat-value" :class="getScoreClass(matchData.educationMatch)">
                      {{ matchData.educationMatch === -1 ? "N/A" : `${matchData.educationMatch}%` }}
                    </div>
                  </div>
                  <div v-if="hasCulturalRequirements" class="stat">
                    <div class="stat-label">Cultural</div>
                    <div class="stat-value" :class="getScoreClass(matchData.culturalFitMatch)">
                      {{
                        matchData.culturalFitMatch === -1 ? "N/A" : `${matchData.culturalFitMatch}%`
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="score-gauge">
                <Gauge
                  :value="overallScore === -1 ? null : overallScore"
                  :threshold="75"
                  size="large"
                />
                <div class="match-label" :class="getScoreClass(overallScore)">
                  {{ getOverallMatchLabel }}
                </div>
              </div>
            </div>
            <div class="score-summary">
              <i class="bi bi-info-circle"></i>
              {{ getOverallSummary }}
            </div>
          </div>
          <div class="analysis-sections">
            <!-- Keyword Match Section -->
            <div
              v-if="hasKeywordRequirements"
              class="analysis-section"
              :class="{ expanded: expandedSections.keywords }"
            >
              <div class="section-header" @click="toggleSection('keywords')">
                <div class="header-left">
                  <Gauge
                    :value="matchData.keywordMatch === -1 ? null : matchData.keywordMatch"
                    :threshold="75"
                  />
                  <div class="header-content">
                    <h2>Keyword Match</h2>
                    <p class="summary">{{ getKeywordSummary }}</p>
                  </div>
                </div>
                <div class="expand-icon" :class="{ expanded: expandedSections.keywords }">
                  <i class="bi bi-chevron-down"></i>
                </div>
              </div>
              <div class="section-content" v-show="expandedSections.keywords">
                <div class="keyword-stats">
                  <div class="stat-card">
                    <div class="stat-number">{{ matchedKeywordsCount }}</div>
                    <div class="stat-label">Keywords Found</div>
                  </div>
                </div>
                <div class="keyword-grid">
                  <div
                    v-for="(keyword, index) in keywordMatchData"
                    :key="index"
                    class="keyword-card"
                    :class="{ missing: !keyword.included }"
                  >
                    <div class="keyword-status">
                      <i
                        :class="
                          keyword.included ? 'bi bi-check-circle-fill' : 'bi bi-x-circle-fill'
                        "
                      ></i>
                    </div>
                    <div class="keyword-content">
                      <div class="keyword-term">{{ toTitleCase(keyword.term) }}</div>
                      <div class="keyword-location" v-if="keyword.included">
                        <span>Found in {{ keyword.location }}</span>
                      </div>
                      <div class="keyword-action" v-else>
                        <a href="#" @click.prevent="showKeywordTips(keyword)">
                          How to add this keyword →
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Skills Match Section -->
            <div
              v-if="hasSkillRequirements"
              class="analysis-section"
              :class="{ expanded: expandedSections.skills }"
            >
              <div class="section-header" @click="toggleSection('skills')">
                <div class="header-left">
                  <Gauge
                    :value="matchData.skillsMatch === -1 ? null : matchData.skillsMatch"
                    :threshold="75"
                  />
                  <div class="header-content">
                    <h2>Skills Match</h2>
                    <p class="summary">{{ getSkillsSummary }}</p>
                  </div>
                </div>
                <div class="expand-icon" :class="{ expanded: expandedSections.skills }">
                  <i class="bi bi-chevron-down"></i>
                </div>
              </div>
              <div class="section-content" v-show="expandedSections.skills">
                <div class="keyword-stats">
                  <div class="stat-card">
                    <div class="stat-number">{{ matchedSkillsCount }}</div>
                    <div class="stat-label">Skills Found</div>
                  </div>
                </div>
                <div class="keyword-grid">
                  <div
                    v-for="(skill, index) in skillMatchData"
                    :key="index"
                    class="keyword-card"
                    :class="{ missing: !skill.included }"
                  >
                    <div class="keyword-status">
                      <i
                        :class="skill.included ? 'bi bi-check-circle-fill' : 'bi bi-x-circle-fill'"
                      ></i>
                    </div>
                    <div class="keyword-content">
                      <div class="keyword-term">{{ toTitleCase(skill.term) }}</div>
                      <div class="keyword-location" v-if="skill.included">
                        <span>Found in {{ skill.location }}</span>
                      </div>
                      <div class="keyword-action" v-else>
                        <a href="#" @click.prevent="showSkillTips(skill)">
                          How to add this skill →
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Experience Match Section -->
            <div
              v-if="hasExperienceRequirements"
              class="analysis-section"
              :class="{ expanded: expandedSections.experience }"
            >
              <div class="section-header" @click="toggleSection('experience')">
                <div class="header-left">
                  <Gauge
                    :value="matchData.experienceMatch === -1 ? null : matchData.experienceMatch"
                    :threshold="75"
                  />
                  <div class="header-content">
                    <h2>Experience Match</h2>
                    <p class="summary">{{ getExperienceSummary }}</p>
                  </div>
                </div>
                <div class="expand-icon" :class="{ expanded: expandedSections.experience }">
                  <i class="bi bi-chevron-down"></i>
                </div>
              </div>
              <div class="section-content" v-show="expandedSections.experience">
                <div class="keyword-stats">
                  <div class="stat-card">
                    <div class="stat-number">{{ matchedExperienceCount }}</div>
                    <div class="stat-label">Experience Items Found</div>
                  </div>
                </div>
                <div class="keyword-grid">
                  <div
                    v-for="(exp, index) in experienceMatchData"
                    :key="index"
                    class="keyword-card"
                    :class="{ missing: !exp.included }"
                  >
                    <div class="keyword-status">
                      <i
                        :class="exp.included ? 'bi bi-check-circle-fill' : 'bi bi-x-circle-fill'"
                      ></i>
                    </div>
                    <div class="keyword-content">
                      <div class="keyword-term">{{ toTitleCase(exp.term) }}</div>
                      <div class="keyword-location" v-if="exp.included">
                        <span>Found in {{ exp.location }}</span>
                      </div>
                      <div class="keyword-action" v-else>
                        <a href="#" @click.prevent="showExperienceTips(exp)">
                          How to add this experience →
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Education Match Section -->
            <div
              v-if="hasEducationRequirements"
              class="analysis-section"
              :class="{ expanded: expandedSections.education }"
            >
              <div class="section-header" @click="toggleSection('education')">
                <div class="header-left">
                  <Gauge
                    :value="matchData.educationMatch === -1 ? null : matchData.educationMatch"
                    :threshold="75"
                  />
                  <div class="header-content">
                    <h2>Education Match</h2>
                    <p class="summary">{{ getEducationSummary }}</p>
                  </div>
                </div>
                <div class="expand-icon" :class="{ expanded: expandedSections.education }">
                  <i class="bi bi-chevron-down"></i>
                </div>
              </div>
              <div class="section-content" v-show="expandedSections.education">
                <div class="keyword-stats">
                  <div class="stat-card">
                    <div class="stat-number">{{ matchedEducationCount }}</div>
                    <div class="stat-label">Education Requirements Met</div>
                  </div>
                </div>
                <div class="keyword-grid">
                  <div
                    v-for="(edu, index) in educationMatchData"
                    :key="index"
                    class="keyword-card"
                    :class="{ missing: !edu.included }"
                  >
                    <div class="keyword-status">
                      <i
                        :class="edu.included ? 'bi bi-check-circle-fill' : 'bi bi-x-circle-fill'"
                      ></i>
                    </div>
                    <div class="keyword-content">
                      <div class="keyword-term">{{ toTitleCase(edu.term) }}</div>
                      <div class="keyword-location" v-if="edu.included">
                        <span>Found in {{ edu.location }}</span>
                      </div>
                      <div class="keyword-action" v-else>
                        <a href="#" @click.prevent="showEducationTips(edu)">
                          Add this experience →
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Cultural Fit Match Section -->
            <div
              v-if="hasCulturalRequirements"
              class="analysis-section"
              :class="{ expanded: expandedSections.cultural }"
            >
              <div class="section-header" @click="toggleSection('cultural')">
                <div class="header-left">
                  <Gauge
                    :value="matchData.culturalFitMatch === -1 ? null : matchData.culturalFitMatch"
                    :threshold="75"
                  />
                  <div class="header-content">
                    <h2>Cultural Fit Match</h2>
                    <p class="summary">{{ getCulturalSummary }}</p>
                  </div>
                </div>
                <div class="expand-icon" :class="{ expanded: expandedSections.cultural }">
                  <i class="bi bi-chevron-down"></i>
                </div>
              </div>
              <div class="section-content" v-show="expandedSections.cultural">
                <div class="keyword-stats">
                  <div class="stat-card">
                    <div class="stat-number">{{ matchedCulturalCount }}</div>
                    <div class="stat-label">Cultural Values Aligned</div>
                  </div>
                </div>
                <div class="keyword-grid">
                  <div
                    v-for="(value, index) in culturalMatchData"
                    :key="index"
                    class="keyword-card"
                    :class="{ missing: !value.included }"
                  >
                    <div class="keyword-status">
                      <i
                        :class="value.included ? 'bi bi-check-circle-fill' : 'bi bi-x-circle-fill'"
                      ></i>
                    </div>
                    <div class="keyword-content">
                      <div class="keyword-term">{{ toTitleCase(value.term) }}</div>
                      <div class="keyword-location" v-if="value.included">
                        <span>Found in {{ value.location }}</span>
                      </div>
                      <div class="keyword-action" v-else>
                        <a href="#" @click.prevent="showCulturalTips(value)">
                          How to include this value →
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TipsModal
    :is-open="showTipsModal"
    :title="tipsData.title"
    :importance="tipsData.importance"
    :tips="tipsData.tips"
    :resources="tipsData.resources"
    :type="tipsData.type"
    @close="closeTipsModal"
  />
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import BreadcrumbNav from "@/components/BreadcrumbNav.vue";
import Gauge from "@/components/Gauge.vue";
import TipsModal from "@/components/TipsModal.vue";
import { ApplicationApiService } from "@/services/ApplicationApiService";
import store from "@/store";

// Interfaces
interface Resource {
  title: string;
  url: string;
  type: string;
}

interface TipsData {
  title: string;
  tips: string[];
  type: string;
  resources: Resource[];
  importance?: string;
}

interface BaseMatchData {
  term: string;
  included: boolean;
  tips: TipsData;
}

interface EducationData extends BaseMatchData {
  term: string;
  included: boolean;
  tips: TipsData;
}

interface KeywordData extends BaseMatchData {
  location?: string;
}

interface SkillData extends BaseMatchData {
  location?: string;
}

interface ExperienceData extends BaseMatchData {
  location?: string;
}

interface CulturalData extends BaseMatchData {
  location?: string;
}

interface MatchData {
  skillsMatch: number;
  experienceMatch: number;
  keywordMatch: number;
  educationMatch: number;
  culturalFitMatch: number;
}

interface ApplicationData {
  id: number | null;
  userId: string;
  companyName: string;
  roleTitle: string;
  jobDescription: string;
  companyDescription: string;
  roleComments: string;
  applicationStatus: number;
  index: number;
  location: string;
  postedDate: string;
  salary: string;
  maxSalary: string;
  sourcedFrom: string;
  jobUrl: string;
  isRemote: boolean;
}

// Interfaces
interface ExpandedSections {
  skills: boolean;
  experience: boolean;
  keywords: boolean;
  education: boolean;
  cultural: boolean;
}

// State
const analyzed = ref(false);
const expandedSections = ref<ExpandedSections>({
  experience: false,
  skills: false,
  keywords: true,
  education: false,
  cultural: false,
});

const application = ref<ApplicationData>({} as ApplicationData);
const route = useRoute();
const router = useRouter();

const matchData = ref({
  keywordMatch: 0,
  skillsMatch: 0,
  experienceMatch: 0,
  educationMatch: 0,
  culturalFitMatch: 0,
});

const keywordMatchData = ref<KeywordData[]>([]);
const skillMatchData = ref<SkillData[]>([]);
const experienceMatchData = ref<ExperienceData[]>([]);
const educationMatchData = ref<EducationData[]>([]);
const culturalMatchData = ref<CulturalData[]>([]);

const isLoading = ref(false);

// Add new state for initial loading
const isInitialLoading = ref(true);

// Helper functions
const calculateMatchPercentage = (included: number, total: number) => {
  if (total === 0) return 0;
  return Math.max(0, Math.round((included / total) * 100));
};

const processAnalysisData = (data: any) => {
  // Update match data with calculated percentages
  matchData.value = {
    keywordMatch:
      !data.keywordMatchData ||
      !Array.isArray(data.keywordMatchData) ||
      data.keywordMatchData.length === 0
        ? -1
        : calculateMatchPercentage(
            data.keywordMatchData.filter((k: KeywordData) => k.included).length,
            data.keywordMatchData.length
          ),
    skillsMatch:
      !data.skillMatchData ||
      !Array.isArray(data.skillMatchData) ||
      data.skillMatchData.length === 0
        ? -1
        : calculateMatchPercentage(
            data.skillMatchData.filter((s: SkillData) => s.included).length,
            data.skillMatchData.length
          ),
    experienceMatch:
      !data.experienceMatchData ||
      !Array.isArray(data.experienceMatchData) ||
      data.experienceMatchData.length === 0
        ? -1
        : calculateMatchPercentage(
            data.experienceMatchData.filter((e: ExperienceData) => e.included).length,
            data.experienceMatchData.length
          ),
    educationMatch:
      !data.educationMatchData ||
      !data.educationMatchData.term ||
      data.educationMatchData.term === ""
        ? -1
        : data.educationMatchData.included
        ? 100
        : 0,
    culturalFitMatch:
      !data.culturalMatchData ||
      !Array.isArray(data.culturalMatchData) ||
      data.culturalMatchData.length === 0
        ? -1
        : calculateMatchPercentage(
            data.culturalMatchData.filter((c: CulturalData) => c.included).length,
            data.culturalMatchData.length
          ),
  };

  // Store the raw data for tips display
  keywordMatchData.value = Array.isArray(data.keywordMatchData) ? data.keywordMatchData : [];
  skillMatchData.value = Array.isArray(data.skillMatchData) ? data.skillMatchData : [];
  experienceMatchData.value = Array.isArray(data.experienceMatchData)
    ? data.experienceMatchData
    : [];
  culturalMatchData.value = Array.isArray(data.culturalMatchData) ? data.culturalMatchData : [];
  educationMatchData.value = data.educationMatchData || {
    term: "",
    included: false,
    tips: {
      title: "",
      tips: [],
      type: "education",
      resources: [],
    },
  };

  analyzed.value = true;
};

async function analyzeResume() {
  try {
    isLoading.value = true;
    const applicationId = route.params.id as string;
    const response = await ApplicationApiService.generateApplicationAnalysis(applicationId);

    if (response.status === 200) {
      processAnalysisData(response.data);
    }
  } catch (error) {
    console.error("Failed to analyze resume:", error);
  } finally {
    isLoading.value = false;
  }
}

// Computed properties to determine if charts should be shown
const shouldShowSkillsChart = computed(() => {
  return matchData.value.skillsMatch > 0 && skillMatchData.value.length > 0;
});

const shouldShowExperienceChart = computed(() => {
  return matchData.value.experienceMatch > 0 && experienceMatchData.value.length > 0;
});

const matchedKeywordsCount = computed(
  () => keywordMatchData.value.filter((k) => k.included).length || 0
);
const totalKeywords = computed(() => keywordMatchData.value.length || 0);

const matchedSkillsCount = computed(
  () => skillMatchData.value.filter((s) => s.included).length || 0
);
const totalSkills = computed(() => skillMatchData.value.length || 0);

const matchedExperienceCount = computed(
  () => experienceMatchData.value.filter((e) => e.included).length || 0
);
const totalExperience = computed(() => experienceMatchData.value.length || 0);

const matchedEducationCount = computed(() => {
  if (!educationMatchData.value.term) return 0;
  return educationMatchData.value?.included ? 1 : 0;
});

const totalEducation = computed(() => {
  return educationMatchData.value.term ? 1 : 0;
});

const matchedCulturalCount = computed(
  () => culturalMatchData.value.filter((c) => c.included).length || 0
);
const totalCultural = computed(() => culturalMatchData.value.length || 0);

const hasKeywordRequirements = computed(() => true);
const hasSkillRequirements = computed(() => true);
const hasExperienceRequirements = computed(() => true);
const hasEducationRequirements = computed(() => true);
const hasCulturalRequirements = computed(() => true);

const overallScore = computed(() => {
  const scores = [];

  if (matchData.value.keywordMatch !== -1) {
    scores.push(matchData.value.keywordMatch);
  }
  if (matchData.value.skillsMatch !== -1) {
    scores.push(matchData.value.skillsMatch);
  }
  if (matchData.value.experienceMatch !== -1) {
    scores.push(matchData.value.experienceMatch);
  }
  if (matchData.value.educationMatch !== -1) {
    scores.push(matchData.value.educationMatch);
  }
  if (matchData.value.culturalFitMatch !== -1) {
    scores.push(matchData.value.culturalFitMatch);
  }

  return scores.length > 0 ? Math.round(scores.reduce((a, b) => a + b, 0) / scores.length) : -1;
});

// Computed Properties
const getSkillsSummary = computed(() => {
  if (skillMatchData.value.length === 0) return "No skill requirements specified";
  const score = matchData.value.skillsMatch;
  if (score >= 80) return "Excellent match with required skills";
  if (score >= 60) return "Good match with most key skills";
  if (score >= 40) return "Some matching skills, room for improvement";
  return "Limited skill match, consider upskilling";
});

const getExperienceSummary = computed(() => {
  if (experienceMatchData.value.length === 0) return "No experience requirements specified";
  const score = matchData.value.experienceMatch;
  if (score >= 80) return `Excellent match with relevant experience`;
  if (score >= 60) return "Good match with relevant experience";
  if (score >= 40) return "Some relevant experience";
  return "More experience needed for this role";
});

const getKeywordSummary = computed(() => {
  if (keywordMatchData.value.length === 0) return "No keyword requirements specified";
  const found = matchedKeywordsCount.value;
  const total = totalKeywords.value;
  return `${found} of ${total} key terms found in your profile`;
});

const getEducationSummary = computed(() => {
  if (!educationMatchData.value.term) {
    return "No education requirements specified";
  }
  return educationMatchData.value.included
    ? "Education requirements met"
    : "Additional education may be needed";
});

const getCulturalSummary = computed(() => {
  if (culturalMatchData.value.length === 0) return "No cultural requirements specified";
  const score = matchData.value.culturalFitMatch;
  if (score >= 80) return "Strong cultural alignment";
  if (score >= 60) return "Good cultural fit";
  if (score >= 40) return "Some cultural differences";
  return "Cultural fit needs evaluation";
});

const getOverallMatchLabel = computed(() => {
  const score = overallScore.value;
  if (score === -1) return "Not Available";
  if (score >= 80) return "Excellent Match";
  if (score >= 60) return "Good Match";
  if (score >= 40) return "Fair Match";
  return "Low Match";
});

const getOverallSummary = computed(() => {
  const score = overallScore.value;
  if (score === -1) return "Not enough data to calculate overall match score.";
  if (score >= 80)
    return "You are an excellent fit for this role. Your skills and experience align very well with the requirements.";
  if (score >= 60)
    return "You are a good match for this role, with some areas that could be strengthened.";
  if (score >= 40)
    return "You meet some of the requirements, but there are several areas that need development.";
  return "Consider developing more relevant skills and experience before applying for similar roles.";
});

// Methods
const toggleSection = (section: keyof ExpandedSections) => {
  expandedSections.value[section] = !expandedSections.value[section];
};

const getScoreClass = (score: number) => {
  if (score === -1) return "na";
  if (score >= 80) return "score-high";
  if (score >= 60) return "score-medium";
  if (score >= 40) return "score-fair";
  return "score-needs-improvement";
};

// Utility function to convert string to Title Case with preserved spaces
const toTitleCase = (str: string | undefined | null) => {
  if (!str) return "";
  return str
    .split(/[-_]+/) // Only split on hyphens and underscores
    .join(" ") // Join with spaces
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" "); // Preserve spaces between words
};

// Tips modal state
const showTipsModal = ref(false);
const tipsData = ref<TipsData>({
  title: "",
  tips: [],
  resources: [],
  type: "",
});

const closeTipsModal = () => {
  showTipsModal.value = false;
};

const getTipsForSkill = (skill: SkillData): TipsData => {
  return {
    title: skill.tips.title,
    tips: skill.tips.tips,
    resources: skill.tips.resources,
    importance: skill.tips.importance,
    type: "skill",
  };
};

const getTipsForExperience = (exp: ExperienceData): TipsData => {
  return {
    title: exp.tips.title,
    tips: exp.tips.tips,
    resources: exp.tips.resources,
    type: "experience",
    importance: exp.tips.importance,
  };
};

const getTipsForEducation = (edu: EducationData): TipsData => {
  return {
    title: edu.tips.title,
    tips: edu.tips.tips,
    resources: edu.tips.resources,
    type: "education",
    importance: edu.tips.importance,
  };
};

const getTipsForKeyword = (keyword: KeywordData): TipsData => {
  return {
    title: keyword.tips.title,
    tips: keyword.tips.tips,
    resources: keyword.tips.resources,
    type: "keyword",
    importance: keyword.tips.importance,
  };
};

const getTipsForCulturalValue = (value: CulturalData): TipsData => {
  return {
    title: value.tips.title,
    tips: value.tips.tips,
    resources: value.tips.resources,
    type: "cultural",
    importance: value.tips.importance,
  };
};

const showSkillTips = (skill: SkillData): void => {
  tipsData.value = getTipsForSkill(skill);
  showTipsModal.value = true;
};

const showExperienceTips = (exp: ExperienceData): void => {
  tipsData.value = getTipsForExperience(exp);
  showTipsModal.value = true;
};

const showEducationTips = (edu: EducationData): void => {
  tipsData.value = getTipsForEducation(edu);
  showTipsModal.value = true;
};

const showCulturalTips = (value: CulturalData): void => {
  tipsData.value = getTipsForCulturalValue(value);
  showTipsModal.value = true;
};

const showKeywordTips = (keyword: KeywordData): void => {
  tipsData.value = getTipsForKeyword(keyword);
  showTipsModal.value = true;
};

// Function to handle resource click
const handleResourceClick = (resourceType: string) => {
  router.push(`/resources/${resourceType.toLowerCase().replace(/\s+/g, "-")}`);
};

// Load existing analysis data when component mounts
onMounted(async () => {
  try {
    isInitialLoading.value = true;
    store.dispatch("toggleLoader", true);
    isLoading.value = true;

    const applicationId = route.params.id as string;
    const [appResponse, analysisResponse] = await Promise.all([
      ApplicationApiService.getApplicationById(applicationId),
      ApplicationApiService.getApplicationAnalysisById(applicationId),
    ]);

    application.value = {
      id: appResponse.data.id || null,
      userId: appResponse.data.userId || "",
      companyName: appResponse.data.companyName || "",
      roleTitle: appResponse.data.roleTitle || "",
      jobDescription: appResponse.data.jobDescription || "",
      companyDescription: appResponse.data.companyDescription || "",
      roleComments: appResponse.data.roleComments || "",
      applicationStatus: appResponse.data.applicationStatus || 0,
      index: appResponse.data.index || 0,
      location: appResponse.data.location || "",
      postedDate: appResponse.data.postedDate || "",
      salary: appResponse.data.salary || "",
      maxSalary: appResponse.data.maxSalary || "",
      sourcedFrom: appResponse.data.sourcedFrom || "",
      jobUrl: appResponse.data.jobUrl || "",
      isRemote: appResponse.data.isRemote || false,
    };

    if (analysisResponse.status === 200 && analysisResponse.data) {
      processAnalysisData(analysisResponse.data);
    }
  } catch (error) {
    console.error("Failed to load existing analysis:", error);
  } finally {
    // Add a small delay before removing loading states to ensure smooth transition
    setTimeout(() => {
      store.dispatch("toggleLoader", false);
      isLoading.value = false;
      isInitialLoading.value = false;
    }, 300);
  }
});
</script>

<style scoped>
.match-analysis-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  min-height: 100vh;
  contain: content;
}

/* Add smooth transitions for loading states */
.empty-state,
.analysis-content {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.empty-state.loading,
.analysis-content.loading {
  opacity: 0;
}

/* Prevent content jumps during loading */
.content-wrapper {
  min-height: 400px;
  position: relative;
}

/* Loading overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.loading-overlay.visible {
  opacity: 1;
  visibility: visible;
}

.header {
  text-align: center;
  margin-bottom: 4rem;
  contain: content;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 0.75rem;
}

.subtitle {
  font-size: 1.25rem;
  color: #64748b;
  margin-bottom: 2rem;
}

.overall-score-card {
  background: white;
  border-radius: 1.5rem;
  padding: 2.5rem;
  max-width: 100%;
  box-shadow: 0 4px 20px -5px rgb(0 0 0 / 0.1);
  border: 1px solid #e2e8f0;
  min-height: 200px;
  contain: content;
}

.score-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  min-height: 150px;
}

.score-details {
  flex: 1;
}

.score-details h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1.5rem;
}

.score-stats {
  display: grid;
  gap: 1.25rem;
}

.stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.25rem;
  background: #f8fafc;
  border-radius: 0.75rem;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
}

.stat:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px -2px rgb(0 0 0 / 0.1);
}

.stat-label {
  font-size: 1rem;
  color: #64748b;
  font-weight: 500;
}

.stat-value {
  font-size: 1.25rem;
  font-weight: 600;
}

.stat-value.score-high {
  color: #16a34a;
}

.stat-value.score-medium {
  color: #ca8a04;
}

.stat-value.score-fair {
  color: #ea580c;
}

.stat-value.score-needs-improvement {
  color: #dc2626;
}

.stat-value.na {
  color: #64748b;
}

.score-gauge {
  text-align: center;
}

.match-label {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1rem;
}

.match-label.score-high {
  color: #16a34a;
}

.match-label.score-medium {
  color: #ca8a04;
}

.match-label.score-fair {
  color: #ea580c;
}

.match-label.score-needs-improvement {
  color: #dc2626;
}

.score-summary {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #e2e8f0;
  color: #64748b;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  line-height: 1.6;
}

.score-summary i {
  color: #0ea5e9;
  font-size: 1.25rem;
  flex-shrink: 0;
}

.analysis-sections {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
  contain: content;
}

.analysis-section {
  background: white;
  border-radius: 1.25rem;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  contain: content;
  will-change: transform, box-shadow;
}

.analysis-section.expanded {
  box-shadow: 0 4px 20px -5px rgb(0 0 0 / 0.1);
}

.section-header {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: #f8fafc;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease;
}

.analysis-section.expanded .section-header {
  border-bottom-color: #e2e8f0;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.header-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.header-content h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.summary {
  font-size: 1rem;
  color: #64748b;
  margin: 0;
  max-width: 500px;
}

.expand-icon {
  font-size: 1.5rem;
  color: #64748b;
  transition: transform 0.3s ease;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}

.section-content {
  padding: 2rem;
  background: white;
  transform-origin: top;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  contain: content;
}

/* Insights Grid */
.insights-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin: 2rem 0;
}

.insight-card {
  background: #f8fafc;
  border-radius: 1rem;
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
}

.insight-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px -2px rgb(0 0 0 / 0.1);
}

.insight-card .icon {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #0ea5e9;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.insight-card .content h4 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 0.75rem;
}

.insight-card .content p {
  color: #64748b;
  line-height: 1.6;
}

.insight-card.positive .icon {
  color: #16a34a;
}

.insight-card.improvement .icon {
  color: #eab308;
}

/* Keyword Analysis */
.keyword-stats {
  margin-bottom: 2rem;
  text-align: center;
}

.stat-card {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  background: #f8fafc;
  border-radius: 1rem;
  padding: 1.25rem 2rem;
  border: 1px solid #e2e8f0;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: #0ea5e9;
  line-height: 1;
}

.stat-label {
  font-size: 1.125rem;
  color: #64748b;
  font-weight: 500;
  text-align: left;
}

.keyword-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
  contain: content;
}

.keyword-card {
  background: #f8fafc;
  border-radius: 1rem;
  padding: 1.2rem;
  border: 1px solid #e2e8f0;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  transition: all 0.2s ease;
  height: 100px; /* Set fixed height */
  min-height: 120px;
  contain: content;
  will-change: transform;
}

.keyword-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px -2px rgb(0 0 0 / 0.1);
}

.keyword-card.missing {
  background: #fff7ed;
  border-color: #fdba74;
}

.keyword-status {
  font-size: 1.25rem;
  padding-top: 0.25rem;
}

.keyword-card:not(.missing) .keyword-status i {
  color: #16a34a;
}

.keyword-card.missing .keyword-status i {
  color: #f97316;
}

.keyword-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.keyword-term {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 0.5rem;
  min-height: 1.75rem; /* Ensure consistent height for term */
}

.keyword-location {
  color: #64748b;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  gap: 0.5rem;
  margin-top: auto; /* Push to bottom of container */
}

.keyword-location span {
  display: inline-block;
  text-align: center;
}

.keyword-action {
  margin-top: auto; /* Push to bottom when location is not present */
}

.keyword-action a {
  color: #0ea5e9;
  font-weight: 500;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.keyword-action a:hover {
  color: #0284c7;
  text-decoration: underline;
}

/* Recommendations */
.recommendations {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #e2e8f0;
}

.recommendations h4 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.recommendations h4 i {
  color: #0ea5e9;
}

.recommendation-card {
  background: #f8fafc;
  border-radius: 1rem;
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
}

.recommendation-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px -2px rgb(0 0 0 / 0.1);
}

.recommendation-card p {
  color: #475569;
  line-height: 1.6;
  margin-bottom: 1.25rem;
}

.action-link {
  color: #0ea5e9;
  font-weight: 500;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.action-link:hover {
  color: #0284c7;
  text-decoration: underline;
}

/* Education Details */
.education-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin: 2rem 0;
}

.education-stat {
  background: #f8fafc;
  border-radius: 1rem;
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  text-align: center;
  transition: all 0.2s ease;
}

.education-stat:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px -2px rgb(0 0 0 / 0.1);
}

.education-stat .stat-label {
  font-size: 1rem;
  color: #64748b;
  font-weight: 500;
  margin-bottom: 0.75rem;
  text-align: center;
}

.education-stat .stat-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .match-analysis-container {
    padding: 1rem;
  }

  .header-left {
    gap: 1rem;
  }

  .header-content h2 {
    font-size: 1.25rem;
  }

  .summary {
    font-size: 0.875rem;
  }

  .section-header,
  .section-content {
    padding: 1.5rem;
  }

  .insights-grid,
  .education-details {
    grid-template-columns: 1fr;
  }

  .keyword-grid {
    grid-template-columns: 1fr;
  }

  .overall-score-card {
    padding: 1.5rem;
  }

  .score-content {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .score-gauge {
    margin-bottom: 1rem;
  }

  .score-summary {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
}

.analyze-button {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.btn-primary {
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
}

.resource-links {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.resource-link {
  padding: 4px 12px;
  background-color: #e5e7eb;
  border-radius: 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.resource-link:hover {
  background-color: #d1d5db;
}

.loading-spinner {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid #0d6efd;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.analyze-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Empty state styles */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  padding: 2rem;
}

.empty-state-icon {
  color: #94a3b8;
}

.empty-state h4 {
  font-weight: 600;
  color: #1e293b;
}

.empty-state p {
  max-width: 400px;
  color: #64748b;
}
</style>
