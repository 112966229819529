<template>
  <Modal class="modal-xl" ref="jobDetailsModal" hideCloseButton>
    <template #body v-if="job">
      <div class="container p-5">
        <div class="m-2 row position-relative">
          <div class="col text-center">
            <p class="display-6 text-inline">{{ job.title }}</p>
            <p class="display-6 text-secondary" style="font-size: 2rem">
              {{ job.company }}
            </p>
          </div>
          <gauge class="gauge" :value="job.match"></gauge>
        </div>

        <div
          class="row d-flex m-2 p-2 text-secondary text-center align-items-center justify-content-center"
        >
          <div class="col-3 d-flex justify-content-center">
            <div>{{ job.location }}<i class="ps-2 bi bi-pin"></i></div>
          </div>

          <div class="col-3 d-flex justify-content-center">
            <div>
              {{ job.min_amount }} - {{ job.max_amount }}
              {{ job.currency }}
              <i class="ps-2 bi bi-cash-stack"></i>
            </div>
          </div>
          <div class="col-3 d-flex justify-content-center">
            <div>{{ formatDate(job.date_posted) }}<i class="ps-2 bi bi-calendar"></i></div>
          </div>
          <div class="col-3 d-flex justify-content-center">
            <div>
              {{ job.is_remote ? "Remote" : "Not Remote" }}<i class="ps-2 bi bi-laptop"></i>
            </div>
          </div>
        </div>

        <div class="m-2 text-secondary border rounded-3">
          <div style="max-height: 40vh" class="m-2 overflow-y-auto">
            {{ job.description }}
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="row">
        <div class="col">
          <div class="btn btn-outline-primary mx-2 text-nowrap" @click="emitOpen(job?.job_url)">
            View on {{ toPascaleCase(job?.site) }}
          </div>
        </div>
        <div class="col">
          <div class="btn btn-outline-primary mx-2" @click="createApplication(job)">
            Create Application
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref, computed, defineProps, defineEmits, watch } from "vue";
import Modal from "@/components/Modal.vue";
import Gauge from "@/components/Gauge.vue";
import router from "../router";

const props = defineProps({
  job: Object,
});

const emits = defineEmits(["open"]);

const jobDetailsModal = ref(null);

watch(
  () => props.job,
  (newJob) => {
    if (newJob) {
      jobDetailsModal.value.show();
    }
  }
);

function toPascaleCase(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function formatDate(timestamp: number) {
  const date = new Date(timestamp);
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
}

function createApplication(job: any) {
  jobDetailsModal.value.hide();
  router.push(`Application/${job.id}`);
}

function emitOpen(url: string) {
  emits("open", url);
}
</script>

<style scoped>
.jobsite-icon {
  height: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #d1d5db;
}

.gauge {
  width: 100px;
  height: 100px;
}
</style>
