<template>
  <div v-if="isOpen" class="tips-modal-overlay" @click="closeModal">
    <div class="tips-modal" @click.stop>
      <div class="tips-modal-header">
        <h3>{{ title }}</h3>
        <button class="close-button" @click="closeModal">
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
      <div class="tips-modal-content">
        <div class="tips-section text-muted">
          <h4><i class="bi bi-lightbulb"></i> Why It's Important</h4>
          <p class="text-start">{{ importance }}</p>
        </div>
        <div class="tips-section">
          <h4><i class="bi bi-check2-circle"></i> How to Add It</h4>
          <ul>
            <li class="tip-item text-muted" v-for="(tip, index) in tips" :key="index">{{ tip }}</li>
          </ul>
          <div class="action-section mt-4">
            <template v-if="props.resources?.length > 0">
              <h5 class="courses-title"><i class="bi bi-book"></i> Relevant Free Courses</h5>
              <p class="courses-description">Explore hand-picked educational resources curated by JobBuddy to enhance your skills.</p>
              <div class="action-box p-3 mt-2 border rounded">
                <router-link 
                  :to="{ 
                    path: '/courses',
                    query: { category: props.resources?.[0]?.type || title }
                  }"
                  class="text-decoration-none d-flex justify-content-between align-items-center"
                >
                  <span>View Free {{ title }} Courses</span>
                  <i class="bi bi-arrow-right"></i>
                </router-link>
              </div>
            </template>
            <template v-else>
              <h5 class="resume-title"><i class="bi bi-file-earmark-text"></i> Update Your Resume</h5>
              <p class="resume-description">Let's optimize your resume to better highlight your {{ props.type === 'keyword' ? 'relevant keywords' : 'experience' }}.</p>
              <div class="action-box p-3 mt-2 border rounded">
                <router-link 
                  :to="{ 
                    name: 'NewDocument',
                    params: { id: route.params.id },
                    query: { type: 'resume', highlight: title }
                  }"
                  class="text-decoration-none d-flex justify-content-between align-items-center"
                >
                  <span>Optimize Your Resume</span>
                  <i class="bi bi-arrow-right"></i>
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="tips-modal-footer">
        <button class="primary-button" @click="closeModal">Got it!</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';

interface Resource {
  title: string;
  url: string;
  suggestedCourses?: string;
}

interface Props {
  isOpen: boolean;
  title: string;
  importance: string;
  tips: string[];
  resources: Resource[];
  type: string;
  user?: {
    id?: number;
    name?: string;
    email?: string;
  };
}

const route = useRoute();
const props = defineProps<Props>();
const emit = defineEmits(['close']);

const closeModal = () => {
  emit('close');
};

// Declare the Jivo callback function globally
declare global {
  interface Window {
    jivo_onMessageSent: () => void;
    jivo_api: {
      setCustomData: (data: Array<{key: string; content: string}>) => void;
    };
  }
}

// Get user data from props or store
const sendUserDataToJivo = () => {
  const userData = [
    { key: 'name', content: props.user?.name || '' },
    { key: 'email', content: props.user?.email || '' },
    { key: 'id', content: props.user?.id?.toString() || '' }
  ];
  
  window.jivo_api.setCustomData(userData);
};

// Set up the Jivo callback
window.jivo_onMessageSent = () => {
  console.log('Client sent the first message');
  if (props.user) {
    sendUserDataToJivo();
  }
};
</script>

<style scoped>
.tips-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.tips-modal {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  position: relative;
  overflow: hidden;
}

.tips-modal-header {
  background-color: #f8f9fa;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tips-modal-header h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.25rem;
  color: #6b7280;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.2s;
}

.close-button:hover {
  background-color: #e5e7eb;
  color: #1a1a1a;
}

.tips-modal-content {
  padding: 2rem;
  max-height: calc(90vh - 200px);
  overflow-y: auto;
}

.tips-section {
  padding: 1rem;
}

.tips-section h4 {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.25rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.tips-section h4 i {
  color: #0d6efd;
}

.tips-section ul {
  padding-left: 1.5rem;
  margin: 1rem 0;
}

.tips-section li {
  margin-bottom: 0.75rem;
  line-height: 1.6;
  color: #4b5563;
}

.action-section {
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 1.5rem;
  margin-top: 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.courses-title,
.resume-title {
  color: #2c3e50;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.courses-description,
.resume-description {
  color: #64748b;
  font-size: 0.95rem;
  margin-bottom: 1rem;
}

.action-box {
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.action-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
}

.action-box a {
  color: #3b82f6;
  font-weight: 500;
}

.action-box a:hover {
  color: #2563eb;
}

.action-box i {
  font-size: 1.1rem;
}

.tips-modal-footer {
  padding: 1.5rem 2rem;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: flex-end;
  background-color: #f8f9fa;
}

.primary-button {
  background-color: #0d6efd;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.primary-button:hover {
  background-color: #0b5ed7;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Scrollbar styling */
.tips-modal-content::-webkit-scrollbar {
  width: 8px;
}

.tips-modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.tips-modal-content::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.tips-modal-content::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.tip-item {
  list-style-type: lower-latin;
}
</style>
