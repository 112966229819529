<template>
  <div class="gauge-container" :class="size">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :viewBox="viewBox"
      preserveAspectRatio="xMidYMid"
      class="gauge"
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style="stop-color: #dc2626; stop-opacity: 1" />
          <stop offset="50%" style="stop-color: #eab308; stop-opacity: 1" />
          <stop offset="100%" style="stop-color: #16a34a; stop-opacity: 1" />
        </linearGradient>
      </defs>
      <!-- Background circle -->
      <circle
        :cx="center"
        :cy="center"
        :r="radius"
        fill="none"
        stroke="#e2e8f0"
        :stroke-width="strokeWidth"
        stroke-linecap="round"
        transform="rotate(-90 60 60)"
      />
      <!-- Progress arc -->
      <circle
        v-if="props.value !== null"
        :cx="center"
        :cy="center"
        :r="radius"
        fill="none"
        :stroke="strokeColor"
        :stroke-width="strokeWidth"
        stroke-linecap="round"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="dashOffset"
        transform="rotate(-90 60 60)"
      />
      <!-- Center text -->
      <text
        :x="center"
        :y="center"
        text-anchor="middle"
        class="percentage"
        :class="size"
        :fill="textColor"
        dominant-baseline="middle"
      >
        {{ displayValue }}
      </text>
    </svg>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import gsap from "gsap";

type GaugeSize = "small" | "medium" | "large";

interface Props {
  value: number | null;
  threshold?: number;
  size?: GaugeSize;
}

const props = withDefaults(defineProps<Props>(), {
  threshold: 75,
  size: "medium",
});

const tweenedValue = ref(0);

const displayValue = computed(() => {
  if (props.value === null) return "N/A";
  return `${tweenedValue.value.toFixed(0)}%`;
});

const dimensions = computed(() => {
  switch (props.size) {
    case "small":
      return { size: 100, stroke: 8 };
    case "large":
      return { size: 180, stroke: 12 };
    default:
      return { size: 120, stroke: 10 };
  }
});

const viewBox = computed(() => {
  const size = dimensions.value.size;
  return `0 0 ${size} ${size}`;
});

const center = computed(() => dimensions.value.size / 2);
const radius = computed(() => dimensions.value.size / 2 - dimensions.value.stroke / 2);
const strokeWidth = computed(() => dimensions.value.stroke);
const circumference = computed(() => 2 * Math.PI * radius.value);

const dashOffset = computed(() => {
  const progress = tweenedValue.value / 100;
  return circumference.value * (1 - progress);
});

const strokeColor = computed(() => {
  const value = tweenedValue.value;
  if (value >= 80) return "#16a34a";
  if (value >= 60) return "#eab308";
  if (value >= 40) return "#ea580c";
  return "#dc2626";
});

const textColor = computed(() => {
  const value = tweenedValue.value;
  if (value >= 80) return "#166534";
  if (value >= 60) return "#854d0e";
  if (value >= 40) return "#9a3412";
  return "#991b1b";
});

watch(
  () => props.value,
  (newValue) => {
    if (newValue === null) {
      tweenedValue.value = 0;
      return;
    }
    gsap.to(tweenedValue, {
      duration: 0.75,
      value: Number(newValue) || 0,
      ease: "power2.out",
    });
  }
);

onMounted(() => {
  if (props.value === null) {
    tweenedValue.value = 0;
    return;
  }
  gsap.to(tweenedValue, {
    duration: 0.75,
    value: Number(props.value) || 0,
    ease: "power2.out",
  });
});
</script>

<style scoped>
.gauge-container {
  margin: 0 auto;
}

.gauge-container.small {
  width: 80px;
  height: 80px;
}

.gauge-container.medium {
  width: 100px;
  height: 100px;
}

.gauge-container.large {
  width: 160px;
  height: 160px;
}

.gauge {
  width: 100%;
  height: 100%;
}

.percentage {
  font-family: system-ui, -apple-system, sans-serif;
  font-weight: 600;
}

.percentage.small {
  font-size: 16px;
}

.percentage.medium {
  font-size: 20px;
}

.percentage.large {
  font-size: 32px;
}

circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
