import { Store } from "vuex";
import documentModule from "./modules/documentsModule";
import userModule from "./modules/userModule";

export interface State {
  showLoader: boolean;
}

export default new Store<State>({
  state: {
    showLoader: false,
  },
  mutations: {
    setShowLoader(state, payload) {
      state.showLoader = payload;
    },
  },
  actions: {
    toggleLoader({ commit }, status) {
      commit("setShowLoader", status);
    },
  },
  modules: {
    documents: documentModule,
    user: userModule,
  },
});
