<template>
  <Modal title="There's been an error!" ref="errorModal">
    <template #body>There was an error processing this request. Please try again later!</template>
  </Modal>
  <Modal title="Are you sure you want to delete this application?" ref="appDeleteModal">
    <template #body>This action cannot be undone!</template>
    <template #footer>
      <button @click="deleteApplication()" class="btn btn-danger">Delete</button>
    </template>
  </Modal>
  <Modal title="Are you sure you want to delete this document?" ref="docDeleteModal">
    <template #body>This action cannot be undone</template>
    <template #footer>
      <button @click="deleteDocument()" class="btn btn-danger">Delete</button>
    </template>
  </Modal>
  <Modal title="Filters" ref="filtersModal">
    <template #body>
      <div class="filters-container">
        <div class="mb-3">
          <label class="form-label">Date Range</label>
          <select v-model="filters.dateRange" class="form-select">
            <option value="all">All Time</option>
            <option value="today">Today</option>
            <option value="week">Last 7 Days</option>
            <option value="month">Last 30 Days</option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label">Sort By</label>
          <select v-model="sortField" class="form-select">
            <option value="dateCreated">Date Created</option>
            <option value="roleTitle">Job Title</option>
            <option value="companyName">Company Name</option>
            <option value="applicationStatus">Status</option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label">Sort Direction</label>
          <select v-model="sortDirection" class="form-select">
            <option value="desc">
              {{ sortField === "dateCreated" ? "Newest First" : "Z to A" }}
            </option>
            <option value="asc">
              {{ sortField === "dateCreated" ? "Oldest First" : "A to Z" }}
            </option>
          </select>
        </div>
      </div>
    </template>
    <template #footer>
      <button @click="applyFilters" class="btn btn-primary">Apply Filters</button>
    </template>
  </Modal>
  <Modal ref="onboardingModal" hide-footer>
    <template #body>
      <div class="onboarding-content text-center">
        <img src="/media/jobbuddymascot.png" alt="JobBuddy Mascot" class="onboarding-mascot mb-3" />
        <h4 class="welcome-message mb-2">Ready to land your dream job?</h4>
        <p class="text-muted small mb-3">Follow these simple steps to get started with JobBuddy</p>
        <div class="onboarding-steps mb-4">
          <div class="step">
            <div class="step-icon">
              <i class="bi bi-plus-circle-fill"></i>
            </div>
            <div class="step-content">
              <h5>Create an Application</h5>
              <p class="text-muted">Start here to unlock all features</p>
            </div>
          </div>
          <div class="step">
            <div class="step-icon">
              <i class="bi bi-file-earmark-text-fill"></i>
            </div>
            <div class="step-content">
              <h5>Generate Documents</h5>
              <p class="text-muted">Get AI-powered cover letters and resumes</p>
            </div>
          </div>
          <div class="step">
            <div class="step-icon">
              <i class="bi bi-person-workspace"></i>
            </div>
            <div class="step-content">
              <h5>Practice Interviews</h5>
              <p class="text-muted">Prepare with AI interview simulations</p>
            </div>
          </div>
          <div class="step">
            <div class="step-icon">
              <i class="bi bi-graph-up"></i>
            </div>
            <div class="step-content">
              <h5>Track Progress</h5>
              <p class="text-muted">Monitor your application journey</p>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <button @click="createFirstApplication" class="btn btn-primary btn-lg px-4">
            Create Your First Application
          </button>
        </div>
      </div>
    </template>
  </Modal>

  <div class="dashboard-container">
    <!-- Mobile Notice -->
    <div class="mobile-notice d-md-none mb-4">
      <div class="alert alert-info mb-0">
        <i class="bi bi-info-circle me-2"></i>
        JobBuddy works best on a desktop. We're working on a mobile version!
      </div>
    </div>

    <!-- Header Section -->
    <div class="dashboard-header mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <div class="status-pills">
          <button
            class="status-pill"
            :class="{ active: activeStatus === 'all' }"
            @click="activeStatus = 'all'"
          >
            All <span class="count">{{ applicationsList.length }}</span>
          </button>
          <button
            v-for="status in statusValues"
            :key="status"
            class="status-pill"
            :class="{ active: activeStatus === status }"
            @click="activeStatus = status"
          >
            {{ formatStatus(status) }}
            <span class="count">{{ getStatusCount(status) }}</span>
          </button>
        </div>
        <button class="btn btn-primary btn-md" @click="router.push('CreateApplication')">
          <i class="bi bi-plus-lg"></i> New Application
        </button>
      </div>
    </div>

    <!-- Search and Filters -->
    <div class="d-flex gap-2 mb-4">
      <div class="search-box flex-grow-1">
        <i class="bi bi-search"></i>
        <input
          type="text"
          class="form-control"
          placeholder="Search applications..."
          v-model="searchQuery"
        />
      </div>
      <button
        class="btn"
        :class="{
          'btn-outline-secondary': !isFilterActive,
          'btn-primary': isFilterActive,
        }"
        @click="isFilterActive ? clearFilters() : filtersModal?.show()"
      >
        <i
          class="bi"
          :class="{ 'bi-funnel-fill': isFilterActive, 'bi-funnel': !isFilterActive }"
        ></i>
        {{ isFilterActive ? "Clear Filters" : "Filters" }}
      </button>
    </div>

    <!-- Applications Table or Empty State -->
    <div
      v-if="applicationsList.length > 0 || loading"
      class="card border-0 shadow-sm applications-table"
    >
      <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead class="sticky-top bg-white">
            <tr :class="{ 'text-secondary': !loading, 'text-gray-300': loading }">
              <th
                class="sortable-header"
                @click="toggleSort('roleTitle')"
                :class="{ 'pointer-events-none': loading }"
              >
                Job Title
                <i
                  :class="[getSortIcon('roleTitle'), { 'opacity-25': loading }]"
                  class="sort-icon"
                ></i>
              </th>
              <th
                class="sortable-header"
                @click="toggleSort('companyName')"
                :class="{ 'pointer-events-none': loading }"
              >
                Company
                <i
                  :class="[getSortIcon('companyName'), { 'opacity-25': loading }]"
                  class="sort-icon"
                ></i>
              </th>
              <th
                class="sortable-header"
                @click="toggleSort('applicationStatus')"
                :class="{ 'pointer-events-none': loading }"
              >
                Status
                <i
                  :class="[getSortIcon('applicationStatus'), { 'opacity-25': loading }]"
                  class="sort-icon"
                ></i>
              </th>
              <th
                class="sortable-header"
                @click="toggleSort('dateCreated')"
                :class="{ 'pointer-events-none': loading }"
              >
                Created
                <i
                  :class="[getSortIcon('dateCreated'), { 'opacity-25': loading }]"
                  class="sort-icon"
                ></i>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="app in filteredApplications" :key="app.applicationId">
              <td>
                <router-link
                  :to="{ name: 'Application', params: { id: app.applicationId } }"
                  class="text-decoration-none text-body cell-link"
                  style="display: block; width: 100%"
                >
                  {{ app.roleTitle }}
                </router-link>
              </td>
              <td>{{ app.companyName }}</td>
              <td>
                <span
                  class="status-badge"
                  :class="getStatusClass(app.applicationStatus)"
                  @click="
                    showStatusDropdown[app.applicationId] = !showStatusDropdown[app.applicationId]
                  "
                >
                  {{ formatStatus(app.applicationStatus) }}

                  <!-- Status Dropdown -->
                  <div v-if="showStatusDropdown[app.applicationId]" class="status-dropdown">
                    <div
                      v-for="status in statusValues"
                      :key="status"
                      class="status-option"
                      :class="{ active: status === app.applicationStatus }"
                      @click.stop="updateApplicationStatus(app.applicationId, status)"
                    >
                      {{ formatStatus(status) }}
                    </div>
                  </div>
                </span>
              </td>
              <td>{{ formatDate(app.dateCreated) }}</td>
              <td class="text-end pe-4">
                <div class="dropdown">
                  <button
                    class="btn btn-icon"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-three-dots-vertical"></i>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li class="w-100">
                      <button
                        class="dropdown-item text-danger w-100"
                        @click="confirmDeleteApplication(app.applicationId)"
                      >
                        <i class="bi bi-trash me-2"></i>Delete
                      </button>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Empty State -->
    <div v-else class="empty-state text-center py-5 h-100">
      <div class="empty-state-icon mb-4">
        <i class="bi bi-clipboard-plus display-1 text-muted"></i>
      </div>
      <h4 class="mb-3">No Applications Yet</h4>
      <p class="text-muted mb-4">Start by creating your first application.</p>
      <button class="btn btn-primary" @click="router.push('CreateApplication')">
        <i class="bi bi-plus"></i> Create Your First Application
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import FileUpload from "@/components/FileUpload.vue";
import store from "@/store";
import { UserApplicationListItem } from "@models/dtos/UserApplicationList";
import { ApplicationApiService } from "@/services/ApplicationApiService";
import Document from "@models/Document";
import Modal from "@/components/Modal.vue";
import { toast } from "vue3-toastify";
import router from "../router";
import { ApplicationStatus } from "@models/SystemEnums";
import confetti from "canvas-confetti";

const documentsList = ref<Document[]>();
const user = ref({});
user.value = useAuth0().user;
const applicationsList = ref(new Array<UserApplicationListItem>());

const errorModal = ref(null);
const appDeleteModal = ref(null);
const docDeleteModal = ref(null);
const filtersModal = ref(null);
const onboardingModal = ref(null);
const loading = ref(true);

let applicationIdToDelete = "";
let documentIdToDelete = "";
onMounted(async () => {
  store.dispatch("toggleLoader", true);
  await loadUserApplications();
  await loadUserDocuments();
  store.dispatch("toggleLoader", false);
  if (user.value.value.email_verified === false) {
    router.push("/verifyEmail");
    return;
  }
  if (applicationsList.value.length === 0) {
    onboardingModal.value?.show();
  }
  if (documentsList.value?.length == 0 && applicationsList.value.length == 0) {
    console.log("No documents or applications");
  }
});

async function loadUserDocuments() {
  //Don't load documents uneccessarily
  await store.dispatch("documents/loadDocuments");
  documentsList.value = store.getters["documents/getDocuments"];
  loading.value = false;
}

function confirmDeleteApplication(applicationId: number) {
  applicationIdToDelete = applicationId;
  appDeleteModal.value.show();
}

function createFirstApplication() {
  onboardingModal.value.hide();
  router.push("CreateApplication");
}

async function deleteApplication() {
  appDeleteModal.value.hide();
  if (!applicationIdToDelete) return;
  const result = await ApplicationApiService.delete(applicationIdToDelete);
  if (result.status == 200) {
    applicationsList.value = applicationsList.value.filter(
      (app) => app.applicationId != applicationIdToDelete
    );
    toast.success("Application deleted successfully");
  } else {
    errorModal.value.show();
    toast.error("Application could not be deleted");
  }
  applicationIdToDelete = "";
}

async function loadUserApplications() {
  const response = await ApplicationApiService.getUserApplicationList();
  if (response.status == 200) {
    applicationsList.value = response.data.applications;
  }
}

async function handleCVUpload(event) {
  console.log(event);
  await postDocuments(event);
  toast.success("Document uploaded successfully");
}

async function postDocuments(file) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("userId", user.value.value.sub);
  await store.dispatch("documents/uploadDocument", formData);
  await loadUserDocuments();
}

function goToTaskboard() {
  router.push("/taskboard");
}
function confirmDocDelete(documentId: string) {
  documentIdToDelete = documentId;
  docDeleteModal.value.show();
}

async function deleteDocument() {
  if (!documentIdToDelete) return;
  await store.dispatch("documents/deleteDocument", documentIdToDelete);
  await loadUserDocuments();
  documentIdToDelete = "";
  docDeleteModal.value.hide();
  toast.success("Document deleted successfully");
}

const activeStatus = ref<"all" | ApplicationStatus>("all");
const searchQuery = ref("");
const filters = ref({
  dateRange: "all",
});

const isFilterActive = computed(() => {
  return filters.value.dateRange !== "all";
});

function applyFilters() {
  filtersModal.value.hide();
}

function clearFilters() {
  filters.value.dateRange = "all";
  sortField.value = "dateCreated";
  sortDirection.value = "desc";
}

const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const getStatusCount = (status: ApplicationStatus) => {
  return applicationsList.value.filter((app) => app.applicationStatus === status).length;
};

const formatStatus = (status: ApplicationStatus | string) => {
  if (typeof status === "string") {
    return status
      ?.split("_")
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(" ");
  }
  return ApplicationStatus[status]
    ?.split("_")
    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(" ");
};

const getStatusClass = (status: ApplicationStatus) => {
  const statusClasses = {
    [ApplicationStatus.TO_APPLY]: "to-apply",
    [ApplicationStatus.APPLIED]: "applied",
    [ApplicationStatus.INTERVIEWING]: "interviewing",
    [ApplicationStatus.REJECTED]: "rejected",
    [ApplicationStatus.OFFERED]: "offered",
  };
  return statusClasses[status];
};

type SortableField = "roleTitle" | "companyName" | "applicationStatus" | "dateCreated";

const sortField = ref<SortableField>("dateCreated");
const sortDirection = ref<"asc" | "desc">("desc");

function toggleSort(field: SortableField) {
  if (sortField.value === field) {
    sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
  } else {
    sortField.value = field;
    sortDirection.value = "desc";
  }
}

function getSortIcon(field: SortableField) {
  if (sortField.value !== field) return "bi bi-arrow-down-up text-muted";
  return sortDirection.value === "asc" ? "bi bi-arrow-up-short" : "bi bi-arrow-down-short";
}

const filteredApplications = computed(() => {
  return applicationsList.value
    .filter((app) => {
      const matchesStatus =
        activeStatus.value === "all" || app.applicationStatus === activeStatus.value;
      const matchesSearch =
        app.roleTitle?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        app.companyName?.toLowerCase().includes(searchQuery.value.toLowerCase());

      // Add date range filtering
      const matchesDateRange = (() => {
        if (filters.value.dateRange === "all") return true;
        const appDate = new Date(app.dateCreated);
        const now = new Date();
        let weekAgo, monthAgo;

        switch (filters.value.dateRange) {
          case "today":
            return appDate.toDateString() === now.toDateString();
          case "week":
            weekAgo = new Date(now.setDate(now.getDate() - 7));
            return appDate >= weekAgo;
          case "month":
            monthAgo = new Date(now.setDate(now.getDate() - 30));
            return appDate >= monthAgo;
          default:
            return true;
        }
      })();

      return matchesStatus && matchesSearch && matchesDateRange;
    })
    .sort((a, b) => {
      const aValue = a[sortField.value];
      const bValue = b[sortField.value];

      if (sortField.value === "dateCreated") {
        const dateA = new Date(aValue as string).getTime();
        const dateB = new Date(bValue as string).getTime();
        return sortDirection.value === "asc" ? dateA - dateB : dateB - dateA;
      }

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortDirection.value === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return 0;
    });
});

const statusValues = computed(() => [
  ApplicationStatus.TO_APPLY,
  ApplicationStatus.APPLIED,
  ApplicationStatus.INTERVIEWING,
  ApplicationStatus.REJECTED,
  ApplicationStatus.OFFERED,
]);

const showStatusDropdown = ref<{ [key: string]: boolean }>({});

async function updateApplicationStatus(applicationId: string, newStatus: ApplicationStatus) {
  try {
    const response = await ApplicationApiService.updateApplicationStatus(
      applicationId,
      newStatus,
      0
    );
    if (response.status === 200) {
      // Update the application status in the local list
      const app = applicationsList.value.find((app) => app.applicationId === applicationId);
      if (app) {
        app.applicationStatus = newStatus;
      }
      showStatusDropdown.value[applicationId] = false;

      // Trigger confetti if status is changed to offered
      if (newStatus === ApplicationStatus.OFFERED) {
        confetti({
          particleCount: 150,
          spread: 100,
          origin: { y: 1 },
        });
      }
    } else {
      throw new Error("Failed to update status");
    }
  } catch (error) {
    console.error("Error updating application status:", error);
    toast.error("Failed to update status");
  }
}

onMounted(() => {
  document.addEventListener("click", (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target?.closest(".status-badge")) {
      Object.keys(showStatusDropdown.value).forEach((key) => {
        showStatusDropdown.value[key] = false;
      });
    }
  });
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
}
a {
  color: #0275d8;
}

.table-wrapper {
  max-height: 300px;
  overflow: auto;
}
.taskboard {
  filter: drop-shadow(0 0 0.5rem #9c8ff45c);
}

.dashboard-container {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.status-pills {
  display: flex;
  gap: 1rem;
}

.status-pill {
  border: none;
  background: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: #666;
  font-weight: 500;
}

.status-pill.active {
  background: #f3f4f6;
  color: #2563eb;
}

.count {
  background: #e5e7eb;
  padding: 0.2rem 0.6rem;
  border-radius: 20px;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.search-box {
  position: relative;
}

.search-box i {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.search-box input {
  padding-left: 2.5rem;
  border-color: #e5e7eb;
}

.status-badge {
  position: relative;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}

.status-display {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
}

.status-display:hover {
  opacity: 0.9;
}

.status-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.5rem;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 1000;
  min-width: 160px;
}

.status-option {
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
  color: #495057;
}

.status-option:hover {
  background-color: #f8f9fa;
  transform: translateX(4px);
}

.status-option.active {
  background-color: #f8f9fa;
  font-weight: 500;
}

.status-badge.to-apply {
  background: #e6e6fa; /* Lavender */
  color: #6a5acd;
}

.status-badge.applied {
  background: #e0ffff; /* Light Cyan */
  color: #40808c;
}

.status-badge.interviewing {
  background: #ffe4e1; /* Misty Rose */
  color: #cd5c5c;
}

.status-badge.rejected {
  background: #ffe4e8; /* Light Pink */
  color: #db7093;
}

.status-badge.offered {
  background: #e0ffe0; /* Light Mint */
  color: #3cb371;
}

.avatar {
  width: 28px;
  height: 28px;
  background: #e5e7eb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 500;
}

.btn-icon {
  padding: 0.25rem;
  border: none;
  background: none;
  color: #6c757d;
}

.btn-icon:hover {
  background: #f8f9fa;
  border-radius: 4px;
}

.empty-state {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-state-icon {
  color: #dee2e6;
}

.empty-state h4 {
  color: #495057;
  font-weight: 500;
}

.empty-state p {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.applications-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.table-responsive {
  flex: 1;
  overflow: auto;
  border-radius: 20px;
}

thead.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

.sortable-header {
  cursor: pointer;
  user-select: none;
}

.sortable-header:hover {
  background-color: #f8f9fa;
}

.sort-icon {
  font-size: 0.75rem;
  vertical-align: middle;
}

.text-gray-300 {
  color: #cbd5e1 !important;
}

.pointer-events-none {
  pointer-events: none;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-item.text-danger {
  color: #dc3545 !important;
}

.dropdown-item.text-danger:hover {
  background-color: #dc35451a;
  color: #dc3545 !important;
}

.onboarding-content {
  padding: 1.5rem 1rem;
  padding-top: 0;
}

.onboarding-mascot {
  width: 52px;
  height: auto;
  margin: 0 auto;
}

.welcome-message {
  color: #2c3e50;
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 0.25rem;
}

.onboarding-steps {
  padding: 0.75rem;
  background: #f8f9fa;
  border-radius: 8px;
  max-width: 480px;
  margin: 0 auto;
}

.step {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  padding: 0.75rem;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.step:hover {
  background-color: #fff;
}

.step-icon {
  background: #e8f0fe;
  color: #1a73e8;
  width: 34px;
  height: 34px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.step-icon i {
  font-size: 1.1rem;
}

.step-content {
  text-align: left;
}

.step-content h5 {
  margin: 0;
  font-size: 0.95rem;
  font-weight: 600;
  color: #2c3e50;
  line-height: 1.2;
}

.step-content p {
  margin: 0.2rem 0 0;
  font-size: 0.8rem;
  line-height: 1.2;
}

/* Override Modal max-width for better display */
:deep(.modal-dialog) {
  max-width: 520px;
}

.support-section {
  border-top: 1px solid #e5e7eb;
}

.support-icon {
  background: #e8f0fe;
  color: #1a73e8;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.support-icon i {
  font-size: 0.9rem;
}

.support-text {
  font-size: 0.75rem;
  color: #6b7280;
  margin: 0;
  line-height: 1.3;
}

.support-item {
  flex: 0 0 auto;
}

.small {
  font-size: 0.85rem;
}

.status-badge.to-apply {
  background: #e6e6fa; /* Lavender */
  color: #6a5acd;
}

.status-badge.applied {
  background: #e0ffff; /* Light Cyan */
  color: #40808c;
}

.status-badge.interviewing {
  background: #ffe4e1; /* Misty Rose */
  color: #cd5c5c;
}

.status-badge.rejected {
  background: #ffe4e8; /* Light Pink */
  color: #db7093;
}

.status-badge.offered {
  background: #e0ffe0; /* Light Mint */
  color: #3cb371;
}

.mobile-notice {
  margin: -1rem -1rem 1rem -1rem;
}

.mobile-notice .alert {
  border-radius: 0;
  text-align: center;
  font-size: 0.875rem;
  padding: 0.75rem;
  margin: 0;
  background-color: #e8f0fe;
  border: none;
  color: #1a73e8;
}
</style>
