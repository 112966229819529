import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/Homepage.vue";
import SetUpProfile from "../views/SetUpProfile.vue";
import AboutJb from "../views/About.vue";
import DashboardView from "../views/DashboardView.vue";
import CreateApplication from "../views/CreateApplication.vue";
import TermsOfService from "../views/TermsOfService.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import CoverLetterBlog from "../views/blogs/CoverLetterBlog.vue";
import ChatGPTBlog from "../views/blogs/ChatGPTBlog.vue";
import UserProfilePopout from "../components/UserProfilePopout.vue";
import { authGuard } from "@auth0/auth0-vue";
import store from "@/store";
import { loginProtectedRoutes } from "@/auth/loginProtectedRoutes";
import SubscribeVue from "@/views/Subscribe.vue";
import PaymentVue from "@/views/Payment.vue";
import VerifyEmail from "@/views/VerifyEmail.vue";
import TaskBoard from "@/views/TaskBoard.vue";
import EditResponseVue from "@/views/EditResponse.vue";
import { coverLetterExamples } from "@/views/blogs/CoverLetterExamples";
import CoverLetterBrowser from "@/views/CoverLetterBrowser.vue";
import CoverLetterDetail from "@/views/CoverLetterDetail.vue";
import ResumeBrowser from "@/views/ResumeBrowser.vue";
import ApplicationView from "@/views/ApplicationView.vue";
import ResumeDetail from "@/views/ResumeDetail.vue";
import JobSearchViewVue from "@/views/JobSearchView.vue";
import ApplicantDetails from "@/views/ApplicantDetailsView.vue";
import InterviewPrepView from "../views/InterviewPrepView.vue";
import EmailGenerator from "@/views/EmailGenerator.vue";
import MatchAnalysis from "@/views/MatchAnalysis.vue";
import CoursesView from '@/views/CoursesView.vue';
import StatsView from '@/views/Stats.vue';
import FeedbackView from "@/views/FeedbackView.vue";
import PaymentSuccess from "@/views/PaymentSuccess.vue";
import HumeVoice from "@/views/InterviewPractise.vue";
import InterviewAnalysis from "@/views/InterviewAnalysis.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: SubscribeVue,
    meta: { toTop: true, smoothScroll: true },
  },
  {
    path: "/payment/:plan/:priceId",
    name: "Payment",
    component: PaymentVue,
  },
  {
    path: "/payment-success",
    name: "PaymentSuccess",
    component: PaymentSuccess,
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardView,
    beforeEnter: authGuard,
  },
  {
    path: "/myJobs",
    name: "MyJobs",
    component: JobSearchViewVue,
  },
  {
    path: "/application/:id",
    name: "Application",
    component: ApplicationView,
  },
  {
    path: "/about",
    name: "About",
    component: AboutJb,
  },
  {
    path: "/profile",
    name: "Profile",
    component: UserProfilePopout,
  },
  {
    path: "/setupprofile",
    name: "SetUpProfile",
    component: SetUpProfile,
  },
  {
    path: "/createapplication",
    name: "CreateApplication",
    component: CreateApplication,
  },
  {
    path: "/termsofservice",
    name: "TermsOfService",
    component: TermsOfService,
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/blog/CoverLetterBlog",
    name: "CoverLetterBlog",
    component: CoverLetterBlog,
  },
  {
    path: "/blog/ChatGPTBlog",
    name: "ChatGPTBlog",
    component: ChatGPTBlog,
  },
  {
    path: "/taskboard",
    name: "TaskBoard",
    component: TaskBoard,
  },
  {
    path: "/verifyEmail",
    name: "VerifyEmail",
    component: VerifyEmail,
  },
  {
    path: "/cover-letter-examples",
    name: "CoverLetterExamples",
    meta: { toTop: true, smoothScroll: true },
    component: CoverLetterBrowser,
  },
  {
    path: "/cover-letter-examples/:role",
    name: "CoverLetterDetail",
    meta: { toTop: true, smoothScroll: true },
    component: CoverLetterDetail,
  },
  {
    path: "/resume-examples",
    name: "ResumeExamples",
    meta: { toTop: true, smoothScroll: true },

    component: ResumeBrowser,
  },
  {
    path: "/resume-examples/:role",
    name: "ResumeDetail",
    meta: { toTop: true, smoothScroll: true },
    component: ResumeDetail,
  },
  {
    path: "/application/:id/documents",
    name: "NewDocument",
    component: () => import("@/views/DocumentGenerator.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/application/:id/documents/:documentId",
    name: "ExistingDocument",
    component: () => import("@/views/DocumentGenerator.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/applicantDetails",
    name: "ApplicantDetails",
    component: ApplicantDetails,
  },
  {
    path: "/upload-resume",
    name: "UploadResume",
    component: () => import("../views/UploadResume.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/application/:id/interview-prep",
    name: "InterviewPrep",
    component: InterviewPrepView,
  },
  {
    path: "/application/:id/interview-practice",
    name: "InterviewPractice",
    component: HumeVoice,
    meta: { requiresAuth: true },
  },
  {
    path: "/application/:id",
    name: "Application",
    component: () => import("@/views/ApplicationView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/application/:id/match-analysis",
    name: "MatchAnalysis",
    component: MatchAnalysis,
    meta: { requiresAuth: true },
  },
  {
    path: "/application/:id/email-generator",
    name: "EmailGenerator",
    component: () => import("@/views/EmailGenerator.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: '/courses',
    name: 'Courses',
    component: CoursesView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/stats',
    name: 'Stats',
    component: StatsView,
    beforeEnter: authGuard,
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: FeedbackView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/feedback/:section*',
    name: 'Feedback',
    component: FeedbackView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/voice-assistant",
    name: "VoiceAssistant",
    component: HumeVoice,
    meta: { requiresAuth: true },
  },
  {
    path: "/application/:id/interview-analysis",
    name: "InterviewAnalysis",
    component: InterviewAnalysis,
    meta: { requiresAuth: true },
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    const scroll = {} as any;
    if (to.meta.toTop) scroll.top = 0;
    if (to.meta.smoothScroll) scroll.behavior = "smooth";
    return scroll;
  },
});

router.beforeEach(async (to, from, next) => {
  if (loginProtectedRoutes.some((routeName) => routeName == to.name)) {
    await checkUserIsLoggedIn();
  }

  // Google Analytics Page View
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: "vue-pageview",
    routeName: to.name,
    routePath: to.path,
    routeFullPath: to.fullPath,
    routeParams: JSON.stringify(to.params),
    routeQuery: JSON.stringify(to.query),
  });
  next();
});

async function checkUserIsLoggedIn() {
  const user = await store.getters["user/getLoggedInUser"];
  if (!user) {
    await store.dispatch("user/loadLoggedInUser");
  }
}

export default router;
