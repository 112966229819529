import posthog from 'posthog-js'

export const initPostHog = () => {
  posthog.init('phc_jJRSdeTdSXmAq5doDZ1nR4oXPzRzyfd6L3Fx1KF6rHj', {
    api_host: 'https://us.i.posthog.com',
    capture_pageview: false, // We handle this with router
    persistence: 'localStorage',
    autocapture: true
  })
}

export const identifyUser = (userId: string, email?: string, name?: string) => {
  posthog.identify(userId, {
    email,
    name
  })
}

export default posthog 