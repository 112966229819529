<template>
  <div class="payment-container">
    <div class="payment-form">
      <div class="payment-header">
        <h1>Complete Your Purchase</h1>
        <p class="payment-description">You'll be redirected to Stripe's secure checkout</p>
      </div>

      <div class="payment-details">
        <div class="plan-summary">
          <h2>Selected Plan</h2>
          <div class="plan-info">
            <span class="plan-name">{{ selectedPlan.plan }}</span>
          </div>
        </div>

        <button 
          class="payment-button" 
          :class="{ 'processing': isProcessing }"
          :disabled="isProcessing"
          @click="redirectToCheckout"
        >
          <span class="button-text">
            {{ isProcessing ? 'Redirecting...' : 'Proceed to Checkout' }}
          </span>
        </button>

        <div class="security-badge">
          <i class="bi bi-shield-lock-fill"></i>
          <span>Secure checkout powered by Stripe</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { SubscriptionsApiService } from "@/services/SubscriptionsApiService";
import { toast } from "vue3-toastify";
import router from "@/router";
import store from "../store";
import { useRoute } from "vue-router";

const publicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
const isProcessing = ref(false);
const selectedPlan = ref({
  priceId: useRoute().params.priceId,
  plan: useRoute().params.plan,
});

async function redirectToCheckout() {
  isProcessing.value = true;
  try {
    store.dispatch("toggleLoader", true);
    
    // Create a Checkout Session
    const response = await SubscriptionsApiService.createCheckoutSession({
      priceId: selectedPlan.value.priceId,
      successUrl: `${window.location.origin}/payment-success`,
      cancelUrl: `${window.location.origin}/payment`,
    });

    if (response.error) {
      throw new Error(response.error.message);
    }

    // Redirect to Checkout
    const stripe = await loadStripe(publicKey);
    const { error } = await stripe.redirectToCheckout({
      sessionId: response.data.sessionId
    });

    if (error) {
      throw new Error(error.message);
    }
  } catch (error) {
    console.error(error);
    toast.error(error.message || "Something went wrong. Please try again.");
    isProcessing.value = false;
  } finally {
    store.dispatch("toggleLoader", false);
  }
}
</script>

<style scoped>
.payment-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8fafc;
  padding: 2rem;
}

.payment-form {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2.5rem;
  width: 100%;
  max-width: 550px;
}

.payment-header {
  text-align: center;
  margin-bottom: 2rem;
}

.payment-header h1 {
  font-size: 1.8rem;
  color: #1a1f36;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.payment-description {
  color: #697386;
  font-size: 1rem;
}

.payment-details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.plan-summary {
  background: #f8fafc;
  border-radius: 8px;
  padding: 1rem;
}

.plan-summary h2 {
  font-size: 1rem;
  color: #1a1f36;
  margin-bottom: 0.5rem;
}

.plan-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan-name {
  font-weight: 500;
  color: #1a1f36;
}

.payment-button {
  background: #635bff;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.875rem;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.15s ease;
}

.payment-button:hover:not(:disabled) {
  background: #5851e5;
}

.payment-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.payment-button.processing {
  background: #5851e5;
}

.security-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #697386;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.security-badge i {
  color: #697386;
}

@media (max-width: 640px) {
  .payment-container {
    padding: 1rem;
  }

  .payment-form {
    padding: 1.5rem;
  }
}
</style>
