import User from "@models/User";
import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";

export class UserApiService {
  public static async createNewUser(user: User): Promise<AxiosResponse<User>> {
    const axios = await instance();
    const response = await axios.post("/createNewUser", user);

    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async resendVerificationEmail(): Promise<AxiosResponse<User>> {
    const axios = await instance();
    const response = await axios.post("/resendVerificationEmail");

    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async updateUser(user: User): Promise<AxiosResponse<User>> {
    const axios = await instance();
    const response = await axios.put("/updateUser", user);
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async deleteUser(): Promise<AxiosResponse<User>> {
    const axios = await instance();
    const response = await axios.post("/deleteUser");
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async getLoggedInUser(): Promise<AxiosResponse<User>> {
    try {
      const axios = await instance();
      const response = await axios.get("/getLoggedInUser");
      if (response.status !== 200) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      return response;
    } catch (error) {
      console.error(error);
      throw error; // Propagate the error to be caught in the calling function
    }
  }

  public static async getCannyToken(): Promise<AxiosResponse<{ token: string }>> {
    const axios = await instance();
    const response = await axios.get("/getCannyToken");
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
}
