<template>
  <div>
    <Modal title="Are you sure you want to cancel your subscription?" ref="cancelSubscriptionModal">
      <template #body>
        Sorry to hear you're cancelling your subscription! If there's anything JobBuddy could do
        keep you as a customer, why not <a href="mailto:hello@jobbuddytech.com">let us know</a>. We
        always strive to improve, and we'd love to hear your feedback.
        <br />
        <br />
        Please note, cancelling your subscription will take immediate effect and you will lose all
        premium benefits.
      </template>
      <template #footer>
        <button @click="cancelSubscription(true)" class="btn btn-danger">Cancel</button>
      </template>
    </Modal>

    <div class="container my-4">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="card border-0 rounded-4 shadow">
            <div class="card-body p-4">
              <!-- Personal Details -->
              <div class="mb-3">
                <label class="form-label small">Name</label>
                <input type="text" class="form-control" v-model="user.name" @input="checkChanges" />
              </div>
              <div class="mb-3">
                <label class="form-label small">Email</label>
                <input type="text" class="form-control" v-model="user.email" disabled />
              </div>
              <div class="mb-3">
                <label class="form-label small">Occupation</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="user.occupation"
                  @input="checkChanges"
                />
              </div>
              <div class="mb-3">
                <label class="form-label small">Gender</label>
                <select v-model="user.gender" class="form-select" @change="checkChanges">
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label small">Employment Status</label>
                <select v-model="user.employmentStatus" class="form-select" @change="checkChanges">
                  <option>Unemployed - Casually browsing</option>
                  <option>Unemployed - Long term unemployed</option>
                  <option>Employed - Looking at new companies</option>
                  <option>Employed - Looking to change career/role</option>
                  <option>Graduate</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label small">Country</label>
                <select v-model="user.country" class="form-select" @change="checkChanges">
                  <option value="" disabled>Select your country</option>
                  <option v-for="country in countries" :key="country.code" :value="country.name">
                    {{ country.name }}
                  </option>
                </select>
              </div>

              <!-- Save Changes -->
              <div
                v-if="hasChanges"
                class="d-flex justify-content-between align-items-center border-top pt-4 mb-4"
              >
                <span class="text-muted small">Unsaved changes</span>
                <button class="btn btn-primary" @click="updateUser">Save Changes</button>
              </div>

              <!-- Subscription Section -->
              <div class="card bg-light border-0 rounded-3 mt-4">
                <div class="card-body">
                  <div class="d-flex flex-column">
                    <div class="mb-3">
                      <div class="text-muted mb-2">Current Plan</div>
                      <h5 class="mb-1">
                        <span class="badge text-white bg-success">
                          {{ formatPlanName(user.subscriptionPlan ?? SubscriptionPlan.BASIC) }}
                        </span>
                      </h5>
                      <div class="small text-muted mt-2">
                        <i class="bi bi-info-circle me-1"></i>
                        {{ getPlanDescription }}
                      </div>
                    </div>
                    <div class="d-flex gap-2 justify-content-center">
                      <button
                        v-if="user.subscriptionPlan !== SubscriptionPlan.GOLD"
                        class="btn btn-sm btn-primary"
                        @click="showSubscribeModal = true"
                      >
                        <i class="bi bi-arrow-up-circle me-1"></i>
                        Upgrade Plan
                      </button>
                      <button
                        class="btn btn-sm btn-outline-secondary"
                        @click="openSubscriptionPortal()"
                      >
                        <i class="bi bi-gear me-1"></i>
                        Manage Subscription
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Subscribe Modal -->
              <div
                v-if="showSubscribeModal"
                class="modal fade show d-block"
                tabindex="-1"
                style="background: rgba(0, 0, 0, 0.5)"
              >
                <Subscribe :is-modal="true" @close="showSubscribeModal = false" />
                <button
                  type="button"
                  class="btn-close position-absolute top-0 end-0 m-4"
                  @click="showSubscribeModal = false"
                  style="z-index: 1050"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import Modal from "@/components/Modal.vue";
import { countries, Country } from "../components/CountriesList";
import { UserApiService } from "@/services/UserApiService";
import { SubscriptionsApiService } from "@/services/SubscriptionsApiService";
import router from "../router";
import { SubscriptionPlan } from "@models/SystemEnums";
import store from "../store";
import { toast } from "vue3-toastify";
import Subscribe from "@/views/Subscribe.vue";

const cancelSubscriptionModal = ref();
const hasChanges = ref(false);
const user = ref<{
  name: string;
  email: string;
  occupation: string;
  gender: string;
  employmentStatus: string;
  country: Country | string;
  subscriptionPlan: SubscriptionPlan | null;
  subscriptionStatus: string | null;
}>({
  name: "",
  email: "",
  occupation: "",
  gender: "",
  employmentStatus: "",
  country: "",
  subscriptionPlan: null,
  subscriptionStatus: null,
});

let originalUserData: typeof user.value | null = null;

async function loadUser() {
  try {
    user.value = await store.getters["user/getLoggedInUser"];
    originalUserData = JSON.parse(JSON.stringify(user.value));
  } catch (error) {
    console.error("Failed to load user:", error);
    toast.error("Failed to load user data");
  }
}

function checkChanges() {
  if (!originalUserData) return;
  hasChanges.value = JSON.stringify(originalUserData) !== JSON.stringify(user.value);
}

async function updateUser() {
  try {
    await store.dispatch("user/updateUser", user.value);
    originalUserData = JSON.parse(JSON.stringify(user.value));
    hasChanges.value = false;
    toast.success("Profile updated successfully");
  } catch (error) {
    console.error("Failed to update user:", error);
    toast.error("Failed to update profile");
  }
}

async function cancelSubscription(force = false) {
  if (!force) {
    cancelSubscriptionModal.value.show();
    return;
  }
  const result = await SubscriptionsApiService.cancelSubscription();
  if (result.status == 200) {
    toast.success("Subscription cancelled successfully");
    await store.dispatch("user/loadLoggedInUser");
    user.value = await store.getters["user/getLoggedInUser"];
  } else {
    toast.error("Failed to cancel subscription");
  }
  cancelSubscriptionModal.value.hide();
}

async function openSubscriptionPortal() {
  window.location.href = process.env.VUE_APP_STRIPE_CUSTOMER_MANAGEMENT_PORTAL;
}

const formatPlanName = (plan: string) => {
  return plan
    .split(/(?=[A-Z])/)
    .join(" ")
    .toUpperCase();
};

const getPlanDescription = computed(() => {
  switch (user.value.subscriptionPlan) {
    case SubscriptionPlan.BASIC:
      return "Create up to 3 job applications | 10 responses per month | Access to Basic Models";
    case SubscriptionPlan.SILVER:
      return "Create up to 125 applications | Unlimited responses per month | Priority Support | Most Advanced AI";
    case SubscriptionPlan.JOB_SEEKER:
      return "Create up to 75 applications | 75 responses per month | Priority Support | Most Advanced AI";
    case SubscriptionPlan.GOLD:
      return "Create unlimited applications | Unlimited responses per month | Priority Support | Most Advanced AI";
    case SubscriptionPlan.BUSINESS:
      return "Create up to 300 applications | 300 responses per month | 24/7 Priority Support | Advanced Business Tools";
    default:
      return "Create up to 3 job applications | 5 responses per month | Access to Basic Models";
  }
});

onMounted(() => {
  loadUser();
});
</script>

<style scoped>
.form-control,
.form-select {
  background-color: #fff;
  border: 1px solid #dee2e6;
  font-size: 0.9rem;
  padding: 0.5rem 0.75rem;
}

.form-control:focus,
.form-select:focus {
  border-color: var(--bs-primary);
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.15);
}

.form-label {
  color: #6c757d;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.badge {
  font-size: 0.8rem;
  padding: 0.5em 0.85em;
  color: #fff;
}

.bi {
  font-size: 0.9rem;
}
</style>
