<template>
  <section class="faqs-section">
    <div class="container">
      <h2 class="section-title text-center">Frequently Asked Questions</h2>
      <p class="section-subtitle text-center mb-5">Everything you need to know about JobBuddy</p>

      <div class="faqs-container">
        <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
          <button
            class="faq-question"
            :class="{ active: activeIndex === index }"
            @click="toggleFaq(index)"
          >
            {{ faq.question }}
            <i class="bi" :class="activeIndex === index ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
          </button>
          <div class="faq-answer" :class="{ active: activeIndex === index }">
            <p>{{ faq.answer }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ref } from "vue";

export default {
  name: "FAQs",
  setup() {
    const activeIndex = ref<number | null>(null);

    const faqs = [
      {
        question: "Is JobBuddy secure?",
        answer:
          "Absolutely. JobBuddy follows strict security protocols to protect your data and ensures your privacy. Your information is never shared with third parties without your consent.",
      },
      {
        question: "Can employers tell I've used JobBuddy or AI?",
        answer:
          "No, the documents JobBuddy generates are designed to look natural, professional, and uniquely yours. They present your skills and experiences seamlessly, leaving no trace of AI involvement.",
      },
      {
        question: "What can JobBuddy help me do?",
        answer:
          "JobBuddy is your all-in-one career assistant. It helps you craft personalized cover letters, optimize your resume for applicant tracking systems, prepare for interviews with tailored questions, and efficiently organize your career documents.",
      },
      {
        question: "How is JobBuddy different from ChatGPT and other AI tools?",
        answer:
          "JobBuddy leverages advanced models like ChatGPT 4 and integrates insights from successful job applicants. This ensures top-notch quality, professional templates, intuitive document management, and real-world success data, all at an unbeatable value. JobBuddy also intelligenltly combines data about you, your skills, and your experiences with data about the job and company to create completely tailored responses. Finally, JobBuddy has been designed to produce documents that are not obviously AI-generated, ensuring your documents are professional and not obviously AI-generated.",
      },
    ];

    const toggleFaq = (index: number) => {
      activeIndex.value = activeIndex.value === index ? null : index;
    };

    return {
      activeIndex,
      faqs,
      toggleFaq,
    };
  },
};
</script>

<style scoped>
.faqs-section {
  padding: 80px 0;
  background: linear-gradient(180deg, #ffffff 0%, #f8fafc 100%);
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.section-subtitle {
  font-size: 1.1rem;
  color: #6b7280;
}

.faqs-container {
  max-width: 800px;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 1rem;
  border-radius: 12px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.faq-question {
  width: 100%;
  text-align: left;
  padding: 1.5rem;
  background: white;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
  color: #1a1a1a;
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-question:hover {
  background: #f8fafc;
}

.faq-question.active {
  background: #f8fafc;
  color: #0d6efd;
}

.faq-question i {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.faq-question.active i {
  transform: rotate(-180deg);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background: #f8fafc;
}

.faq-answer.active {
  max-height: 300px;
}

.faq-answer p {
  padding: 0 1.5rem 1.5rem;
  margin: 0;
  color: #4b5563;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 2rem;
  }

  .faq-question {
    font-size: 1rem;
    padding: 1.25rem;
  }
}
</style>
