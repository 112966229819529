<template>
  <div
    :class="{
      'modal-dialog modal-dialog-centered modal-fullscreen m-0': isModal,
      'container my-5': !isModal,
    }"
    :style="!isModal ? 'max-width: 1024px' : ''"
  >
    <div :class="{ 'modal-content rounded-0': isModal }">
      <div :class="{ 'modal-body': isModal }">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-12" :class="{ 'col-xxl-8': isModal }">
              <div class="text-center mb-5">
                <div v-if="isModal">
                  <button
                    type="button"
                    class="btn-close position-absolute top-0 end-0 m-4"
                    @click="$emit('close')"
                    aria-label="Close"
                  ></button>
                  <h2 class="display-6 pt-3 text-muted">Supercharge Your Job Search</h2>
                </div>
                <div v-else>
                  <h2 class="display-5 fw-bold">Land Your Dream Job Fast</h2>
                  <p class="lead text-muted">
                    Thousands of Professionals Already Found Success with Our AI Tools
                  </p>
                </div>
              </div>

              <div class="d-flex flex-column flex-lg-row justify-content-center gap-4">
                <!-- Basic -->
                <div class="card border-0 rounded-4 shadow-sm" style="width: 320px">
                  <div class="card-body d-flex flex-column p-4">
                    <h4 class="fw-bold mb-1">Basic</h4>
                    <p class="text-muted small mb-4">Kickstart Your Search for Free</p>

                    <h2 class="display-5 fw-bold mb-0">$0</h2>
                    <p class="text-muted mb-4">per month</p>

                    <ul class="list-unstyled mb-4">
                      <li class="mb-3">
                        <i class="bi bi-check2-circle text-primary me-2"></i>5 Cover Letters
                      </li>
                      <li class="mb-3">
                        <i class="bi bi-check2-circle text-primary me-2"></i>5 Resumes
                      </li>
                      <li class="mb-3">
                        <i class="bi bi-check2-circle text-primary me-2"></i>5 Interview Prep
                      </li>

                      <li class="mb-3">
                        <i class="bi bi-check2-circle text-primary me-2"></i>30 Min AI Interview
                        Practice
                      </li>
                      <li class="mb-3">
                        <i class="bi bi-check2-circle text-primary me-2"></i>3 Applications
                      </li>
                    </ul>

                    <router-link
                      :to="{ name: 'Dashboard' }"
                      class="text-decoration-none w-100 mt-auto"
                      @click="$emit('close')"
                    >
                      <button class="btn btn-outline-primary w-100 py-2 rounded-3">
                        Get Started—It's Free!
                      </button>
                    </router-link>
                  </div>
                </div>

                <!-- Silver -->
                <div
                  class="card border-0 rounded-4 shadow"
                  style="
                    width: 340px;
                    transform: scale(1.05);
                    background: linear-gradient(to bottom right, #f8f9ff, #fff);
                    z-index: 1;
                    box-shadow: 0 0 25px rgba(13, 110, 253, 0.15);
                  "
                >
                  <div
                    class="position-absolute"
                    style="top: -12px; left: 50%; transform: translateX(-50%)"
                  >
                    <span class="badge bg-primary px-3 py-2 rounded-pill">
                      <i class="bi bi-star-fill me-1"></i> MOST POPULAR
                    </span>
                  </div>
                  <div class="card-body d-flex flex-column justify-content-between p-4">
                    <div>
                      <h4 class="fw-bold mb-1">Silver</h4>
                      <p class="text-muted small mb-4">Land Jobs 4x Faster</p>

                      <div class="mb-1">
                        <span class="text-primary text-decoration-line-through me-2">$29.99</span>
                        <span class="badge bg-primary-subtle text-primary rounded-pill"
                          >Limited Time 50% Discount</span
                        >
                      </div>
                      <h2 class="display-5 fw-bold mb-0">$14.99</h2>
                      <p class="text-muted mb-4">per month</p>
                    </div>

                    <div>
                      <ul class="list-unstyled mb-4">
                        <li class="mb-3">
                          <i class="bi bi-check2-circle text-primary me-2"></i>Unlimited Cover
                          Letters
                        </li>
                        <li class="mb-3">
                          <i class="bi bi-check2-circle text-primary me-2"></i>Unlimited Resumes
                        </li>
                        <li class="mb-3">
                          <i class="bi bi-check2-circle text-primary me-2"></i>Unlimited Interview
                          Prep
                        </li>
                        <li class="mb-3">
                          <i class="bi bi-check2-circle text-primary me-2"></i>Unlimited Interview
                          Practice
                        </li>
                        <li class="mb-3">
                          <i class="bi bi-check2-circle text-primary me-2"></i>125 Applications Per
                          Month
                        </li>
                      </ul>
                    </div>

                    <button
                      @click="handlePayment('silver')"
                      class="btn btn-primary w-100 py-2 rounded-3 mt-auto"
                    >
                      Get Hired <i>Fast</i>
                    </button>
                  </div>
                </div>

                <!-- Gold -->
                <div class="card border-0 rounded-4 shadow-sm" style="width: 320px">
                  <div class="card-body d-flex flex-column p-4">
                    <h4 class="fw-bold mb-1">Gold</h4>
                    <p class="text-muted small mb-4">Maximize Your Opportunities</p>

                    <h2 class="display-5 fw-bold mb-0">$49.99</h2>
                    <p class="text-muted mb-4">per month</p>

                    <ul class="list-unstyled mb-4">
                      <li class="mb-3">
                        <i class="bi bi-check2-circle text-primary me-2"></i>Unlimited Cover Letters
                      </li>
                      <li class="mb-3">
                        <i class="bi bi-check2-circle text-primary me-2"></i>Unlimited Resumes
                      </li>
                      <li class="mb-3">
                        <i class="bi bi-check2-circle text-primary me-2"></i>Unlimited Interview
                        Prep
                      </li>
                      <li class="mb-3">
                        <i class="bi bi-check2-circle text-primary me-2"></i>Unlimited Interview
                        Practice
                      </li>
                      <li class="mb-3">
                        <i class="bi bi-check2-circle text-primary me-2"></i>24/7 Priority Support
                      </li>
                      <li class="mb-3">
                        <i class="bi bi-check2-circle text-primary me-2"></i>Unlimited Applications
                      </li>
                    </ul>

                    <button
                      @click="handlePayment('gold')"
                      class="btn btn-outline-primary w-100 py-2 rounded-3 mt-auto"
                    >
                      Supercharge Your Job Search
                    </button>
                  </div>
                </div>
              </div>

              <TestimonialsMini
                darkMode="false"
                description="Loved by 10,000+ job seekers 👇"
                class="mt-3"
              />

              <Testimonials class="p-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store";
import Testimonials from "../components/Testimonials.vue";
import TestimonialsMini from "../components/TestimonialsMini.vue";
export default {
  name: "Subscribe",
  components: {
    Testimonials,
    TestimonialsMini,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      silverPaymentLink: process.env.VUE_APP_STRIPE_SILVER_PAYMENT_LINK || "",
      goldPaymentLink: process.env.VUE_APP_STRIPE_GOLD_PAYMENT_LINK || "",
    };
  },
  methods: {
    async handlePayment(type) {
      const user = await store.getters["user/getLoggedInUser"];
      const link = type === "silver" ? this.silverPaymentLink : this.goldPaymentLink;

      // Get First Promoter tracking ID
      const tid = window.FPROM && window.FPROM.data.tid;

      // Build the URL with all parameters
      const url = new URL(link);
      url.searchParams.set("prefilled_email", user.email);
      if (tid) {
        url.searchParams.set("client_reference_id", tid);
      }

      // Redirect to the payment page
      window.location.href = url.toString();
    },
  },
  mounted() {
    console.log("JobSeeker link:", this.silverPaymentLink);
    console.log("Business link:", this.goldPaymentLink);
  },
};
</script>

<style scoped>
.modal-body {
  padding: 0 !important;
}

.card {
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.badge {
  font-weight: 500;
}

/* Add styles for TestimonialsMini text color */
:deep(.testimonials-mini h3) {
  color: #000 !important;
}

.testimonial-card {
  background: #fff;
  border-radius: 0.75rem;
  padding: 1.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease-in-out;
}

.testimonial-card:hover {
  transform: translateY(-2px);
}

.testimonial-content {
  position: relative;
}

.testimonial-content .fa-quote-left {
  font-size: 1.5rem;
  opacity: 0.5;
}

.testimonial-author {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
  margin-top: 1rem;
}

.rating {
  letter-spacing: 2px;
}

.highlight {
  background: linear-gradient(
    120deg,
    rgba(var(--bs-primary-rgb), 0.1) 0%,
    rgba(var(--bs-primary-rgb), 0.1) 100%
  );
  padding: 0.1rem 0.3rem;
  border-radius: 0.25rem;
  font-weight: 500;
}
</style>
