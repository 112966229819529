<template>
  <div class="layout-container">
    <Sidebar v-if="showSidebar" />
    <main
      :class="{
        'with-sidebar': showSidebar,
        'height-full-sidebar': route.name !== 'NewDocument' && route.name !== 'ExistingDocument',
        loading: showLoaderDelayed,
      }"
    >
      <slot></slot>
      <transition name="fade">
        <div v-if="showLoaderDelayed" class="atom-spinner">
          <div class="spinner-inner">
            <div class="spinner-line"></div>
            <div class="spinner-line"></div>
            <div class="spinner-line"></div>
            <!--Chrome renders little circles malformed :(-->
            <div class="spinner-circle">&#9679;</div>
          </div>
        </div>
      </transition>
    </main>
  </div>
</template>

<script setup lang="ts">
import Sidebar from "@/components/Sidebar.vue";
import User from "@models/User";
import { computed, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const route = useRoute();
const store = useStore();
const showLoaderDelayed = ref(false);
let loaderTimeout: NodeJS.Timeout | null = null;

// Watch the store's showLoader state
watch(
  () => store.state.showLoader,
  (newValue) => {
    if (newValue) {
      // Set a timeout before showing the loader
      loaderTimeout = setTimeout(() => {
        showLoaderDelayed.value = true;
      }, 250); // Only show loader if loading takes more than 250ms
    } else {
      // Clear timeout if loading finishes quickly
      if (loaderTimeout) {
        clearTimeout(loaderTimeout);
        loaderTimeout = null;
      }
      showLoaderDelayed.value = false;
    }
  }
);

const sidebarRoutes = [
  "Dashboard",
  "MyJobs",
  "TaskBoard",
  "Profile",
  "CreateApplication",
  "Application",
  "NewDocument",
  "ExistingDocument",
  "ApplicantDetails",
  "InterviewPrep",
  "EmailGenerator",
  "MatchAnalysis",
  "Courses",
  "Stats",
  "Feedback",
  "VoiceAssistant",
  "InterviewPractice",
  "InterviewAnalysis",
];

const showSidebar = computed(() => {
  return sidebarRoutes.includes(route.name as string);
});

// Declare the Jivo callback function globally
declare global {
  interface Window {
    jivo_onMessageSent: () => void;
    jivo_api: {
      setCustomData: (data: Array<{ key: string; content: string }>) => void;
    };
  }
}

// Get user data from store
const sendUserDataToJivo = () => {
  const user = store.state.user.user as User;
  if (!user) {
    console.log("No user found");
    return;
  }
  const userData = [
    { key: "name", content: `${user.name}` },
    { key: "email", content: user.email || "" },
    { key: "id", content: user._id?.toString() || "" },
    { key: "plan", content: user.subscriptionPlan },
    { key: "customerId", content: user.customerId },
  ];

  window.jivo_api.setContactInfo({
    name: `${user.name}`,
    email: user.email || "",
  });
  window.jivo_api.setCustomData(userData);
};

// Set up the Jivo callback
onMounted(() => {
  window.jivo_onMessageSent = () => {
    console.log("Client sent the first message");
    sendUserDataToJivo();
  };
});

function jivo_onMessageSent() {
  console.log("Message sent");
}

function jivo_onOpen() {
  console.log("Jivo chat opened");
}
</script>

<style scoped>
.layout-container {
  min-height: 100vh;
  display: flex;
}

main {
  min-height: 100vh;
  background: white;
  position: relative;
  flex: 1;
  overflow-x: hidden;
}

.with-sidebar {
  margin-left: 240px;
  width: calc(100% - 240px);
  background: #fafafa !important;
}

.atom-spinner,
.atom-spinner * {
  box-sizing: border-box;
}

.atom-spinner {
  height: 60px;
  width: 60px;
  overflow: hidden;
}

.atom-spinner .spinner-inner {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}

.atom-spinner .spinner-circle {
  display: block;
  position: absolute;
  color: #1976d2;
  font-size: calc(60px * 0.24);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.atom-spinner .spinner-line {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-left-width: calc(60px / 25);
  border-top-width: calc(60px / 25);
  border-left-color: #1976d2;
  border-left-style: solid;
  border-top-style: solid;
  border-top-color: transparent;
}

.atom-spinner .spinner-line:nth-child(1) {
  animation: atom-spinner-animation-1 1s linear infinite;
  transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
}

.atom-spinner .spinner-line:nth-child(2) {
  animation: atom-spinner-animation-2 1s linear infinite;
  transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
}

.atom-spinner .spinner-line:nth-child(3) {
  animation: atom-spinner-animation-3 1s linear infinite;
  transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
}

@keyframes atom-spinner-animation-1 {
  100% {
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes atom-spinner-animation-2 {
  100% {
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes atom-spinner-animation-3 {
  100% {
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
  }
}

.atom-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

/* Add a backdrop for the spinner */
main::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

main.loading::after {
  opacity: 1;
  visibility: visible;
}
</style>
