<template>
  <div class="payment-success d-flex flex-column align-items-center justify-content-center">
    <div class="text-center">
      <h1 class="display-4 mb-4">🎉 Payment Sucessful!</h1>
      <p class="lead mb-4">Thank you for your support. Get ready to supercharge your job search!</p>
      <router-link to="/dashboard" class="btn btn-primary btn-lg">
        Continue to Dashboard
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import confetti from 'canvas-confetti';

onMounted(() => {
  // Trigger confetti animation
  confetti({
    particleCount: 100,
    spread: 70,
    origin: { y: 0.6 },
  });
});
</script>

<style scoped>
.payment-success {
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 2rem;
}

.display-4 {
  color: #2c3e50;
  font-weight: 600;
}

.lead {
  color: #6c757d;
  font-size: 1.25rem;
}

.btn-primary {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  transition: transform 0.2s;
}

.btn-primary:hover {
  transform: translateY(-2px);
}
</style>
