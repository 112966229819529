<template>
  <div class="homepage">
    <!-- Hero Section -->
    <section class="hero-section" ref="homepage">
      <!-- Cloud Elements -->
      <div class="cloud cloud-1"></div>
      <div class="cloud cloud-2"></div>
      <div class="cloud cloud-3"></div>
      <div class="cloud cloud-4"></div>
      <div class="cloud cloud-5"></div>
      <div class="cloud cloud-6"></div>
      <div class="cloud cloud-7"></div>
      <div class="cloud cloud-8"></div>

      <div class="container">
        <div class="hero-content text-center">
          <div class="laurel-wrapper">
            <img src="/media/laurel-and-stars.webp" alt="5 star rating" class="laurel-stars mb-4" />
            <h2 class="laurel-title">#1 AI Tool for Getting Hired</h2>
          </div>
          <h1 class="hero-title">AI tools that get you <span class="highlight">hired</span></h1>
          <p class="hero-subtitle">
            Track your applications, apply for jobs, prepare for interviews, and land your dream job
            quickly with JobBuddy's AI tools.
          </p>
          <div class="cta-button">
            <button class="btn btn-white btn-lg" @click="signup">
              Get Hired
              <i class="bi bi-arrow-right ms-2"></i>
            </button>
          </div>

          <!-- Social Proof Section -->
          <TestimonialsMini />
        </div>

        <!-- Dashboard Preview -->
        <div class="dashboard-preview">
          <div class="dashboard-card">
            <img src="/media/Dashboard.webp" alt="JobBuddy Dashboard" class="dashboard-image" />
          </div>
        </div>

        <!-- Featured On Section -->
        <div class="featured-section">
          <p class="featured-text">Featured on</p>
          <div class="featured-logos-container">
            <div class="featured-logos">
              <div class="featured-logo">
                <img src="/media/hackernews.svg" alt="Hacker News" class="logo hn-logo" />
              </div>
              <div class="featured-logo">
                <img src="/media/producthunt.svg" alt="Product Hunt" class="logo ph-logo" />
              </div>
              <div class="featured-logo">
                <svg class="logo x-logo" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path
                    d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div class="featured-logo">
                <img src="/media/reddit.svg" alt="Reddit" class="logo reddit-logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Features Section -->
    <section class="features-section">
      <div class="container">
        <h2 class="section-title text-center mb-2">
          AI tools for <i>every</i> step of your job search
        </h2>
        <p class="section-subtitle text-center mb-3">
          Create applications quicker, interview better, keep organized, and get hired
        </p>

        <div class="features-nav-container">
          <div class="features-nav">
            <button
              v-for="(feature, key) in features"
              :key="key"
              class="feature-button"
              :class="{ active: activeFeature === key }"
              @click="activeFeature = key"
            >
              <i :class="feature.icon"></i>
              {{ feature.title }}
            </button>
          </div>
        </div>

        <div class="feature-content-area" v-if="activeFeature">
          <div class="feature-content-wrapper">
            <div class="feature-text">
              <div class="feature-header">
                <div class="feature-icon">
                  <i :class="features[activeFeature].icon"></i>
                </div>
                <h3>{{ features[activeFeature].title }}</h3>
              </div>
              <p class="feature-benefit">{{ features[activeFeature].benefit }}</p>
              <ul class="feature-description">
                <li v-for="(point, index) in features[activeFeature].description" :key="index">
                  {{ point }}
                </li>
              </ul>
              <button class="btn btn-white mt-4" @click="signup">
                {{ features[activeFeature].cta || "Try Now" }}
                <i class="bi bi-arrow-right ms-2"></i>
              </button>
            </div>
            <div class="feature-image">
              <img
                :src="features[activeFeature].image"
                :alt="features[activeFeature].title"
                class="feature-image-single"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Pricing Section -->
    <section class="pricing-section">
      <div class="container">
        <Subscribe />
      </div>
    </section>

    <!-- FAQs Section -->
    <FAQs />
    <!-- Final CTA Section -->
    <section class="final-cta-section py-5">
      <div class="container text-center">
        <h2 class="mb-4">Ready to Land Your Dream Job?</h2>
        <p class="lead mb-4">
          Join thousands of professionals who've already found success with JobBuddy
        </p>
        <button class="btn btn-primary btn-lg" @click="signup">
          Get Started Now
          <i class="bi bi-arrow-right ms-2"></i>
        </button>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import { ref, onMounted, onUnmounted } from "vue";
import Subscribe from "../views/Subscribe.vue";
import FAQs from "../components/FAQs.vue";
import TestimonialsMini from "../components/TestimonialsMini.vue";
interface Feature {
  title: string;
  icon: string;
  benefit: string;
  description: string[];
  image: string;
  cta?: string;
}

interface Features {
  [key: string]: Feature;
}

export default {
  name: "Homepage",
  components: {
    Subscribe,
    FAQs,
    TestimonialsMini,
  },
  setup() {
    const { loginWithRedirect } = useAuth0();
    const homepage = ref<HTMLElement | null>(null);
    const activeFeature = ref<keyof Features>("resume");

    const features: Features = {
      resume: {
        title: "Resume Builder",
        icon: "bi bi-file-person",
        benefit: "Beat ATS systems every time",
        description: [
          "Auto-match skills to job descriptions",
          "Export to PDF, Word, or plain text",
          "Keyword optimized resumes",
          "Get seen by real humans",
          "Recruiter approved templates",
        ],
        image: "/media/ResumeExample.webp",
        cta: "Beat ATS Systems",
      },
      analysis: {
        title: "Resume Analysis",
        icon: "bi bi-graph-up",
        benefit: "Match what employers want",
        description: [
          "Instant resume scoring",
          "Skills gap identification",
          "Keyword optimization tips",
          "Match rate to job requirements",
        ],
        image: "/media/ResumeAnalysis.webp",
        cta: "Land More Interviews",
      },
      coverLetters: {
        title: "Cover Letter",
        icon: "bi bi-file-earmark-text",
        benefit: "Save 40 minutes per application",
        description: [
          "Generate in 10 seconds or less",
          "Perfectly matched to job requirements",
          "Natural, human-like writing",
          "Personalized to your experience",
          "Keyword optimized",
        ],
        image: "/media/CoverLetterExample1.webp",
        cta: "Apply 10x Faster",
      },
      prep: {
        title: "Interview Prep",
        icon: "bi bi-journal-check",
        benefit: "Research like an insider",
        description: [
          "AI-predicted interview questions",
          "Personalized STAR answers",
          "Smart questions to ask back",
          "Company-specific insights",
        ],
        image: "/media/InterviewPrep.webp",
        cta: "Be Better Prepared",
      },
      practice: {
        title: "Interview Practice",
        icon: "bi bi-camera-video",
        benefit: "Build unshakeable confidence",
        description: [
          "Practice with AI interviewer",
          "Real-time feedback on answers",
          "Industry-specific questions",
          "Master tough questions",
        ],
        image: "/media/InterviewPractice.webp",
        cta: "Ace Your Next Interview",
      },
      email: {
        title: "Email Generator",
        icon: "bi bi-envelope",
        benefit: "Never miss a follow-up",
        description: [
          "Perfect follow-up emails",
          "Professional acceptance letters",
          "Tactful rejection responses",
          "Perfect timing and tone",
        ],
        image: "/media/EmailGenerator.webp",
        cta: "Stand Out From Others",
      },
    };

    const handleScroll = () => {
      if (!homepage.value) return;
      const scrolled = window.scrollY;
      const clouds = Array.from(homepage.value.querySelectorAll<HTMLElement>(".cloud"));
      const featureArrow = document.querySelector<SVGPathElement>(".feature-flow-arrow path");

      // Animate clouds
      clouds.forEach((cloud, index) => {
        const speed = (index + 1) * 0.2;
        const yPos = scrolled * speed;
        cloud.style.transform = `translateY(${yPos}px)`;
      });

      // Animate feature arrow
      if (featureArrow) {
        const featureSection = document.querySelector(".features-section");
        if (featureSection) {
          const rect = featureSection.getBoundingClientRect();
          const sectionTop = rect.top;
          const viewportHeight = window.innerHeight;

          // Calculate progress as section comes into view
          const progress = Math.max(
            0,
            Math.min(1, 1 - (sectionTop - viewportHeight * 0.5) / (viewportHeight * 0.5))
          );

          // Update arrow path
          const arrowLength = featureArrow.getTotalLength();
          featureArrow.style.strokeDasharray = `${arrowLength}`;
          featureArrow.style.strokeDashoffset = `${arrowLength * (1 - progress)}`;
        }
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const signup = () => {
      loginWithRedirect({
        screen: "signup",
        screen_hint: "signup",
        appState: { target: "/dashboard" },
      });
    };

    return {
      homepage,
      activeFeature,
      features,
      signup,
    };
  },
};
</script>

<style scoped>
.homepage {
  text-align: center;
}

.hero-section {
  background: linear-gradient(180deg, #0d6dfd 0%, #60a5fa 40%, #ffffff 100%);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

/* Cloud Styles */
.cloud {
  position: absolute;
  background: #fff;
  border-radius: 100px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
  will-change: transform;
}

.cloud::before,
.cloud::after {
  content: "";
  position: absolute;
  background: inherit;
  border-radius: 50%;
}

.cloud-1 {
  width: 500px;
  height: 100px;
  bottom: 75%;
  right: -100px;
  opacity: 0.3;
  animation: float1 30s ease-in-out infinite;
}

.cloud-2 {
  width: 400px;
  height: 80px;
  bottom: 80%;
  left: -150px;
  opacity: 0.2;
  animation: float2 25s ease-in-out infinite;
}

.cloud-3 {
  width: 300px;
  height: 60px;
  bottom: 65%;
  left: 30%;
  opacity: 0.25;
  animation: float3 20s ease-in-out infinite;
}

.cloud-4 {
  width: 450px;
  height: 90px;
  bottom: 55%;
  right: 20%;
  opacity: 0.4;
  animation: float1 28s ease-in-out infinite;
}

.cloud-5 {
  width: 350px;
  height: 70px;
  bottom: 45%;
  left: 10%;
  opacity: 0.5;
  animation: float2 32s ease-in-out infinite;
}

.cloud-6 {
  width: 400px;
  height: 80px;
  bottom: 35%;
  right: 35%;
  opacity: 0.6;
  animation: float3 27s ease-in-out infinite;
}

.cloud-7 {
  width: 500px;
  height: 100px;
  bottom: 25%;
  left: -50px;
  opacity: 0.7;
  animation: float1 35s ease-in-out infinite;
}

.cloud-8 {
  width: 450px;
  height: 90px;
  bottom: 15%;
  right: -80px;
  opacity: 0.8;
  animation: float2 29s ease-in-out infinite;
}

/* Cloud shapes */
.cloud-1::before,
.cloud-2::before,
.cloud-3::before,
.cloud-4::before,
.cloud-5::before,
.cloud-6::before,
.cloud-7::before,
.cloud-8::before {
  width: 160px;
  height: 160px;
  top: -80px;
  right: 100px;
}

.cloud-1::after,
.cloud-2::after,
.cloud-3::after,
.cloud-4::after,
.cloud-5::after,
.cloud-6::after,
.cloud-7::after,
.cloud-8::after {
  width: 120px;
  height: 120px;
  top: -50px;
  right: 200px;
}

@keyframes float1 {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100px);
  }
}

@keyframes float2 {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
}

@keyframes float3 {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-60px);
  }
}

/* Remove old cloud styles */
.homepage::before,
.homepage::after,
.hero-section::before,
.hero-section::after,
.extra-cloud {
  content: none;
}

.hero-section {
  padding: 80px 0;
  color: white;
  position: relative;
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero-title {
  font-size: 4rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: white;
  /* Remove gradient text effect since we have better contrast now */
  -webkit-text-fill-color: initial;
  background: none;
}

.hero-subtitle {
  font-size: 1.25rem;
  opacity: 0.9;
  margin-bottom: 2.5rem;
  line-height: 1.6;
}

.cta-button {
  margin-bottom: 2.5rem;
}

.btn-white {
  background: #fff;
  color: #4361ee;
  border: none;
  padding: 0.8rem 1.5rem;
  font-weight: 600;
  border-radius: 100px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-white:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.dashboard-preview {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 1;
}

.dashboard-card {
  background: white;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 50px 100px -20px rgba(0, 0, 0, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
  transform: perspective(1000px) rotateX(2deg);
  transition: transform 0.3s ease;
}

.dashboard-card:hover {
  transform: perspective(1000px) rotateX(0deg);
}

.dashboard-image {
  width: 100%;
  height: auto;
  display: block;
}

.chat-bubble {
  position: absolute;
  background: white;
  border-radius: 16px;
  padding: 16px 24px;
  box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.15), 0 10px 20px -10px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.chat-left {
  left: -30px;
  top: 25%;
  transform: translateX(-20px);
  animation: floatLeft 3s ease-in-out infinite;
}

.chat-right {
  right: -30px;
  bottom: 25%;
  transform: translateX(20px);
  animation: floatRight 3s ease-in-out infinite;
}

@keyframes floatLeft {
  0%,
  100% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(-10px);
  }
}

@keyframes floatRight {
  0%,
  100% {
    transform: translateX(20px);
  }
  50% {
    transform: translateX(10px);
  }
}

.chat-content {
  font-size: 0.9rem;
  color: #1a1a1a;
  font-weight: 500;
}

.chat-label {
  display: inline-block;
  background: #22c55e;
  color: white;
  padding: 6px 16px;
  border-radius: 100px;
  font-size: 0.85rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.stats-section {
  background-color: #f8fafc;
  padding: 80px 0;
}

.stat-card {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.stat-card h3 {
  color: #4361ee;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.stat-card p {
  color: #64748b;
  margin: 0;
  font-weight: 500;
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
    padding: 0 1rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
    padding: 0 1.5rem;
  }

  .dashboard-preview {
    padding: 0 10px;
    margin-top: 2rem;
  }

  .chat-bubble {
    display: none;
  }

  .dashboard-card {
    transform: none;
    margin: 0 1rem;
  }

  .hero-content {
    padding: 0 1rem;
  }

  .laurel-stars {
    width: 150px;
  }

  .laurel-title {
    font-size: 0.85rem;
  }

  .proof-text {
    font-size: 0.95rem;
    padding: 0 1rem;
  }

  .featured-logos-container {
    margin: 0 1rem;
    padding: 1rem;
  }

  .featured-logos {
    gap: 2rem;
  }

  .logo {
    height: 20px;
  }

  .section-title {
    font-size: 1.75rem;
    padding: 0 1rem;
  }

  .section-subtitle {
    font-size: 1rem;
    padding: 0 1.5rem;
  }

  .feature-content-area {
    padding: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    margin: 1rem;
    margin-top: 2rem;
  }

  .feature-content-wrapper {
    grid-template-columns: 1fr;
    gap: 1rem;
    min-height: auto;
  }

  .feature-text {
    padding: 0.5rem 0;
    gap: 1rem;
    text-align: center;
  }

  .feature-description {
    text-align: left;
    font-size: 1rem;
    margin-bottom: 0;
  }

  .feature-description li {
    margin-bottom: 0.5rem;
  }

  .feature-image-single {
    width: 100% !important;
    max-width: 320px;
    margin: 0 auto;
  }

  .feature-header h3 {
    font-size: 1.5rem;
  }

  .feature-icon {
    width: 48px;
    height: 48px;
    font-size: 1.5rem;
  }

  .feature-header {
    gap: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .feature-benefit {
    padding: 0.35rem 0.75rem;
    font-size: 1.1rem;
    text-align: center;
    width: 100%;
  }

  .features-nav {
    margin: 0;
    padding: 0.5rem 1rem;
    justify-content: flex-start;
    gap: 0.5rem;
  }

  .feature-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.85rem;
  }

  .feature-image {
    padding: 0.5rem;
  }

  .features-section {
    padding: 30px 0;
  }
}

/* Add new styles for better mobile experience */
.hero-section {
  padding: 60px 0;
  overflow-x: hidden;
}

.container {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
}

.features-section {
  padding: 50px 0;
  overflow: hidden;
}

.pricing-section {
  padding: 30px 0;
  overflow-x: hidden;
}

/* Add smooth scrolling for mobile */
.features-nav {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 0.75rem;
  margin-bottom: 2rem;
  width: 100%;
  overflow-x: scroll;
  padding: 0.5rem 1rem;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

/* Container to prevent overflow issues */
.features-nav-container {
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

@media (max-width: 768px) {
  .features-nav {
    margin: 0;
    padding: 0.5rem 1rem;
    justify-content: flex-start;
    gap: 0.75rem;
    overflow-x: scroll;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    left: 0;
    right: 0;
  }

  .feature-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
    white-space: nowrap;
    flex: 0 0 auto;
  }
}

/* Improve touch targets for mobile */
.btn {
  min-height: 44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Ensure images don't overflow on mobile */
img {
  max-width: 100%;
  height: auto;
}

.highlight {
  background: white;
  color: #0d6efd;
  padding: 0 15px;
  display: inline-block;
  margin: 0 5px;
  transform: rotate(-2deg);
  position: relative;
  top: -2px;
}

.highlight:hover {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.laurel-wrapper {
  position: relative;
  display: inline-block;
}

.laurel-stars {
  width: 200px;
  height: auto;
  opacity: 0.95;
  margin-bottom: 0;
}

.laurel-title {
  color: #173d51;
  font-size: 1rem;
  font-weight: 600;
  opacity: 0.9;
  letter-spacing: 0.5px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: 0;
  padding: 0 20px;
}

.featured-section {
  margin-top: 3rem;
  text-align: center;
}

.featured-text {
  font-size: 1rem;
  color: #6b7280;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  display: inline-block;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.5rem 1.5rem;
  border-radius: 100px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.featured-logos-container {
  background: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  max-width: 600px;
}

.featured-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  flex-wrap: nowrap;
}

.featured-logo {
  opacity: 0.8;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
}

.featured-logo:hover {
  opacity: 1;
}

.logo {
  height: 25px;
  width: auto;
}

.hn-logo,
.ph-logo,
.x-logo,
.reddit-logo {
  color: #1a1a1a;
}

@media (max-width: 768px) {
  .featured-logos-container {
    margin: 0 1rem;
    padding: 1rem;
  }

  .featured-logos {
    gap: 1.5rem;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .featured-logos::-webkit-scrollbar {
    display: none;
  }

  .logo {
    height: 18px;
  }
}

/* Pricing Section Styles */
.pricing-section {
  position: relative;
  z-index: 1;
  background: white;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
}

.section-subtitle {
  font-size: 1.1rem;
  color: #6b7280;
}

.pricing-card {
  border: 1px solid #e5e7eb;
  border-radius: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.pricing-card.featured {
  border: 2px solid #0d6efd;
  transform: scale(1.05);
}

.pricing-card.featured:hover {
  transform: scale(1.05) translateY(-5px);
}

.popular-badge {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: #0d6efd;
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
}

.price {
  font-size: 3rem;
  font-weight: 700;
  color: #0d6efd;
  line-height: 1;
}

.price span {
  font-size: 1rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 2rem;
  }

  .pricing-card.featured {
    transform: none;
  }

  .pricing-card.featured:hover {
    transform: translateY(-5px);
  }
}

/* Features Section Styles */
.features-section {
  padding: 50px 0;
}

.features-nav {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 0.75rem;
  margin-bottom: 2rem;
  width: 100%;
  overflow-x: scroll;
  padding: 0.5rem 1rem;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

.features-nav::-webkit-scrollbar {
  display: none;
}

.feature-button {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  color: #6b7280;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
}

.feature-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.feature-button.active {
  background: #0d6efd;
  color: white;
  border-color: #0d6efd;
}

.feature-button i {
  font-size: 1.25rem;
}

.feature-content-area {
  background: white;
  border-radius: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 2rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  animation: fadeIn 0.3s ease-out;
}

.feature-content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
  min-height: 75vh;
}

.feature-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.feature-text .btn-white {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  background: white;
  border: 2px solid #4361ee;
  color: #4361ee;
  transition: all 0.3s ease;
  width: auto;
  min-width: 200px;
}

.feature-text .btn-white:hover {
  background: #4361ee;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(67, 97, 238, 0.15);
}

.feature-text .btn-white i {
  transition: transform 0.3s ease;
}

.feature-text .btn-white:hover i {
  transform: translateX(4px);
}

.feature-description {
  color: #6b7280;
  line-height: 1.7;
  font-size: 1.1rem;
  margin: 0;
  padding-left: 1.5rem;
  list-style-type: none;
  text-align: left;
  width: 100%;
}

.feature-description li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
}

.feature-description li:last-child {
  margin-bottom: 0;
}

.feature-description li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.7rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #0d6efd;
  border-radius: 50%;
}

.feature-benefit {
  font-size: 1.25rem;
  font-weight: 600;
  color: #0d6efd;
  margin: 0;
  padding: 0.5rem 1rem;
  background: rgba(13, 110, 253, 0.1);
  border-radius: 8px;
  display: inline-block;
}

@media (max-width: 768px) {
  .feature-text {
    align-items: center;
  }

  .feature-header {
    flex-direction: column;
    text-align: center;
  }

  .feature-icon {
    margin: 0 auto;
  }

  .feature-benefit {
    text-align: center;
  }

  .feature-text .btn-white {
    width: 100%;
    max-width: 300px;
  }
}

.feature-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.feature-icon {
  font-size: 2rem;
  color: #0d6efd;
  background: rgba(13, 110, 253, 0.1);
  width: 64px;
  height: 64px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.feature-header h3 {
  font-size: 1.75rem;
  color: #1a1a1a;
  font-weight: 600;
  margin: 0;
}

.feature-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.feature-image-single {
  width: 450px;
  height: auto;
  object-fit: contain;
  transition: all 0.3s ease;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  border-radius: 24px;
}

.feature-image-single[src*="Generator"],
.feature-image-single[src*="Prep"],
.feature-image-single[src*="Practice"],
.feature-image-single[src*="Analysis"] {
  width: 600px;
}

.feature-image:hover .feature-image-single {
  transform: scale(1.02);
  filter: drop-shadow(0 8px 12px rgba(0, 0, 0, 0.15));
}

@media (max-width: 1024px) {
  .feature-image-single {
    width: 400px;
  }

  .feature-image-single[src*="Generator"],
  .feature-image-single[src*="Prep"],
  .feature-image-single[src*="Practice"],
  .feature-image-single[src*="Analysis"] {
    width: 500px;
  }
}

@media (max-width: 768px) {
  .feature-image-single {
    width: 320px;
  }

  .feature-image-single[src*="Generator"],
  .feature-image-single[src*="Prep"],
  .feature-image-single[src*="Practice"],
  .feature-image-single[src*="Analysis"] {
    width: 400px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .feature-content-wrapper {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .feature-content-area {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .features-nav {
    margin: 0;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: calc(1rem + 8px);
    justify-content: flex-start;
    gap: 0.75rem;
    overflow-x: auto;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    width: 100%;
    min-width: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .feature-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
    white-space: nowrap;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: 2px;
  }

  .feature-button i {
    display: inline-block;
    font-size: 1.1rem;
  }

  .features-nav {
    flex-wrap: nowrap !important;
  }
}

/* Add styles for better feature button layout */
.features-nav {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 0.75rem;
  margin-bottom: 2rem;
  width: 100%;
  overflow-x: scroll;
  padding: 0.5rem 1rem;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

.feature-button {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  color: #6b7280;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
  position: relative;
  z-index: 1;
}

.supercharge-text {
  color: #0d6efd;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 4px;
}

.features-cta {
  padding: 4rem 0;
  background: linear-gradient(180deg, #f8fafc 0%, #ffffff 100%);
}

.features-cta h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 2rem;
}

.features-cta .btn-primary {
  padding: 1rem 2rem;
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 100px;
  transition: all 0.3s ease;
}

.features-cta .btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(13, 110, 253, 0.15);
}

@media (max-width: 768px) {
  .features-cta h2 {
    font-size: 2rem;
  }
}

.final-cta-section {
  padding: 4rem 0;
  background: linear-gradient(180deg, #f8fafc 0%, #ffffff 100%);
}

.final-cta-section h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
}

.final-cta-section .lead {
  color: #6b7280;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.final-cta-section .btn-primary {
  padding: 1rem 2rem;
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 100px;
  transition: all 0.3s ease;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.final-cta-section .btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(13, 110, 253, 0.15);
}

@media (max-width: 768px) {
  .final-cta-section h2 {
    font-size: 2rem;
  }
}

.feature-flow-arrow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #0d6efd;
  opacity: 0.3;
  padding: 0 2rem;
}

.feature-flow-arrow path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  transition: stroke-dashoffset 0.1s ease;
}

@media (max-width: 768px) {
  .feature-flow-arrow {
    display: none;
  }
}

@media (max-width: 768px) {
  /* Hide some clouds on mobile */
  .cloud-3,
  .cloud-4,
  .cloud-5,
  .cloud-6,
  .cloud-7,
  .cloud-8 {
    display: none;
  }

  /* Adjust remaining clouds */
  .cloud-1 {
    width: 300px;
    height: 60px;
    bottom: 85%;
    opacity: 0.2;
  }

  .cloud-2 {
    width: 250px;
    height: 50px;
    bottom: 70%;
    opacity: 0.15;
  }

  /* Rest of mobile styles */
  .hero-title {
    font-size: 2.5rem;
    padding: 0 1rem;
  }
}

@media (max-width: 768px) {
  .pricing-section {
    padding: 2rem 1rem;
  }

  .pricing-card {
    margin: 1rem;
    transform: none !important;
  }

  .pricing-card.featured {
    transform: none !important;
    margin: 2rem 1rem;
  }

  .pricing-card:hover {
    transform: translateY(-5px) !important;
  }

  .price {
    font-size: 2.5rem;
  }

  .popular-badge {
    font-size: 0.8rem;
    padding: 3px 10px;
    top: -10px;
  }

  /* Add spacing between pricing cards */
  :deep(.pricing-cards-container) {
    gap: 1rem !important;
    padding: 1rem;
  }

  /* Ensure pricing cards stack properly */
  :deep(.pricing-card-wrapper) {
    width: 100% !important;
    max-width: none !important;
  }
}

@media (max-width: 768px) {
  .features-section {
    padding: 30px 0;
  }

  .feature-content-area {
    padding: 0.5rem;
    margin: 0.5rem;
    margin-top: 1rem;
    box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.1);
  }

  .feature-content-wrapper {
    grid-template-columns: 1fr;
    gap: 1rem;
    min-height: auto;
  }

  .feature-text {
    padding: 0.5rem 0;
    gap: 1rem;
  }

  .feature-description {
    margin-bottom: 0;
  }

  .feature-description li {
    margin-bottom: 0.5rem;
  }

  .feature-icon {
    width: 48px;
    height: 48px;
    font-size: 1.5rem;
  }

  .feature-header {
    gap: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .feature-benefit {
    padding: 0.35rem 0.75rem;
    font-size: 1.1rem;
  }

  .feature-image {
    padding: 0.5rem;
  }
}
</style>
