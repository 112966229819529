<template>
  <div class="task-board-view">
    <div class="task-board">
      <div class="task-columns" group="task-columns">
        <div
          class="board-column"
          :class="{ 'ms-3': columnIndex === 0 }"
          v-for="(column, columnIndex) in taskColumns"
          :key="columnIndex"
        >
          <div class="column-header">
            <span class="column-title">{{ column.title }}</span>
            <span class="column-count">{{ column.cards.length }}</span>
          </div>
          <VueDraggableNext
            class="board-card-list"
            v-model="column.cards"
            group="task-cards"
            @end="updateApplicationStatus"
            :data-column-index="columnIndex"
          >
            <div class="board-card" v-for="(card, cardIndex) in column.cards" :key="cardIndex">
              <div class="card-header">
                <i class="bi bi-building me-2"></i>
                <span class="company-name">{{ card.companyName }}</span>
              </div>
              <router-link
                :to="{
                  name: 'Application',
                  params: { id: card.applicationId },
                }"
                class="card-link"
              >
                <div class="card-body">
                  <p class="role-title">{{ card.title }}</p>
                </div>
              </router-link>
            </div>
          </VueDraggableNext>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ApplicationApiService } from "@/services/ApplicationApiService";
import { ApplicationStatus } from "@models/SystemEnums";
import { UserApplicationListItem } from "@models/dtos/UserApplicationList";
import { onMounted, ref } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import store from "../store";

interface Card {
  title: string;
  companyName: string;
  applicationId: string;
}

interface Column {
  title: string;
  cards: Card[];
  applicationStatus: ApplicationStatus;
}

const taskColumns = ref<Column[]>([
  {
    title: "To Apply",
    cards: [],
    applicationStatus: ApplicationStatus.TO_APPLY,
  },
  {
    title: "Applied",
    cards: [],
    applicationStatus: ApplicationStatus.APPLIED,
  },
  {
    title: "Interviewing",
    cards: [],
    applicationStatus: ApplicationStatus.INTERVIEWING,
  },
  {
    title: "Rejected",
    cards: [],
    applicationStatus: ApplicationStatus.REJECTED,
  },
  {
    title: "Offer",
    cards: [],
    applicationStatus: ApplicationStatus.OFFERED,
  },
]);

const applicationsList = ref([] as UserApplicationListItem[]);

onMounted(async () => {
  store.dispatch("toggleLoader", true);
  await loadUserApplications();
  store.dispatch("toggleLoader", false);
});

async function loadUserApplications() {
  // Fetch applications
  const response = await ApplicationApiService.getUserApplicationList();
  if (response.status !== 200) {
    console.error("Problem loading user applications");
    return;
  }

  applicationsList.value = response.data.applications;

  // Populate and sort the cards for each column
  taskColumns.value.forEach((column) => {
    const filteredApps = applicationsList.value.filter(
      (app) => app?.applicationStatus === column.applicationStatus
    );

    if (column.applicationStatus === ApplicationStatus.TO_APPLY) {
      const unassignedApps = applicationsList.value.filter((app) => app?.applicationStatus == null);
      unassignedApps.forEach((app) => {
        filteredApps.push(app);
      });
    }

    // Sort the applications by their index property
    filteredApps.sort((a, b) => a.index - b.index);

    // Map the sorted applications to cards
    column.cards = filteredApps.map((app) => ({
      title: app.roleTitle,
      companyName: app.companyName,
      applicationId: app.applicationId,
    }));
  });
}

async function updateApplicationStatus(event: any) {
  const newColumnIndex = parseInt(event.to.getAttribute("data-column-index"));
  const oldColumnIndex = parseInt(event.from.getAttribute("data-column-index"));
  const newColumn = taskColumns.value[newColumnIndex];
  const columnStatus = newColumn.applicationStatus;
  const movedCard = newColumn.cards[event.newIndex];

  if (event.newIndex === event.oldIndex && newColumnIndex === oldColumnIndex) return;
  try {
    const response = await ApplicationApiService.updateApplicationStatus(
      movedCard.applicationId,
      columnStatus,
      event.newIndex
    );

    if (response.status !== 200) {
      throw new Error("Problem updating application status");
    }

    if (event.oldIndex !== event.newIndex) {
      // If needed, update cards below the one moved
      for (let index = event.newIndex; index < newColumn.cards.length; index++) {
        const card = newColumn.cards[index];
        if (card === movedCard) continue;

        const response = await ApplicationApiService.updateApplicationStatus(
          card.applicationId,
          columnStatus,
          index
        );

        if (response.status !== 200) {
          throw new Error("Problem updating application status for other cards");
        }
      }
    }
  } catch (error) {
    console.error(error);
    // Handle rollback or UI update
  }
}
</script>

<style scoped>
.task-board-view {
  min-height: calc(100vh - 2rem);
  background-color: #f8f9fa;
}

.board-title {
  color: #344767;
  font-weight: 600;
  font-size: 1.5rem;
}

.task-board {
  overflow-x: auto;
  padding-bottom: 1rem;
  height: 100vh;
}

.task-columns {
  display: flex;
  gap: 1.5rem;
  min-height: 100vh;
  padding: 2rem 0;
}

.board-column {
  flex: 1;
  min-width: 300px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  border-bottom: 2px solid #f1f3f5;
  margin-bottom: 1rem;
}

.column-title {
  font-weight: 600;
  color: #344767;
  font-size: 1rem;
}

.column-count {
  background: #e9ecef;
  color: #495057;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 500;
}

.board-card-list {
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.board-card-list::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

.board-card {
  background: white;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: all 0.2s ease;
  cursor: grab;
  position: relative;
  overflow: hidden;
}

.board-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-color: #dee2e6;
}

.board-card:active {
  transform: translateY(0);
  transition: all 0.1s ease;
}

.card-link {
  text-decoration: none;
  color: inherit;
  display: block;
  position: relative;
}

.card-link::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.04);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.card-link:active::after {
  opacity: 1;
}

.card-header {
  padding: 0.75rem;
  border-bottom: 1px solid #f1f3f5;
  display: flex;
  align-items: center;
}

.company-name {
  font-weight: 500;
  color: #344767;
  font-size: 0.875rem;
}

.card-body {
  padding: 0.75rem;
}

.role-title {
  color: #495057;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.4;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .task-columns {
    flex-direction: column;
    gap: 1rem;
  }

  .board-column {
    min-width: 100%;
  }
}
</style>
