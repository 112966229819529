<template>
  <div class="modal-backdrop" v-if="showModal">
    <div class="modal fade show d-block" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="interview-types">
              <div
                v-for="type in interviewTypes"
                :key="type.id"
                class="interview-type-option"
                :class="{ selected: selectedType === type.id }"
                @click="selectedType = type.id"
              >
                <div class="type-header">
                  <div class="icon-container">
                    <i :class="type.icon"></i>
                  </div>
                  <h6>{{ type.name }}</h6>
                </div>
                <p class="type-description">{{ type.description }}</p>
              </div>
            </div>
            <p class="duration-notice text-center mt-3">
              <i class="bi bi-stopwatch"></i> 30 Mins Max Duration | Or just disconnect when you're
              done
            </p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" @click="$emit('close')">Cancel</button>
            <button class="btn btn-primary" @click="selectType" :disabled="!selectedType">
              Start Interview
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const props = defineProps<{
  showModal: boolean;
}>();

const selectedType = ref("comprehensive");

const interviewTypes = [
  {
    id: "comprehensive",
    name: "Comprehensive Interview",
    description:
      "A complete interview covering all aspects including technical, behavioral, competency, and cultural questions.",
    icon: "bi bi-stars",
    config:
      "Conduct a comprehensive interview that covers multiple aspects. Start with general questions about the candidate's background, then move to behavioral questions about past experiences. Include technical questions relevant to the role, and assess cultural fit through questions about work style and values. Balance the types of questions and adapt based on the candidate's responses.",
  },
  {
    id: "behavioral",
    name: "Behavioral Interview",
    description: "Focus on past experiences and how you handled specific work situations.",
    icon: "bi bi-person-workspace",
    config:
      "Focus exclusively on behavioral interview questions. Ask about specific past situations and experiences, using the STAR method format. Probe for detailed examples of how the candidate handled challenges, conflicts, and successes. Follow up on answers to understand the candidate's decision-making process and lessons learned.",
  },
  {
    id: "technical",
    name: "Technical Interview",
    description: "Technical questions specific to your role and coding challenges.",
    icon: "bi bi-code-square",
    config:
      "Focus on technical skills and knowledge relevant to the role. Ask detailed questions about technologies mentioned in the job description. Include problem-solving scenarios and technical challenges. Evaluate both theoretical knowledge and practical application skills. Ask for specific examples of technical projects or challenges the candidate has worked on.",
  },
  {
    id: "competency",
    name: "Competency & Situational",
    description:
      "Questions about specific skills and how you would handle hypothetical situations.",
    icon: "bi bi-diagram-3",
    config:
      "Focus on competency-based and situational questions. Present hypothetical scenarios relevant to the role and ask how the candidate would handle them. Assess key competencies like problem-solving, leadership, teamwork, and communication. Use follow-up questions to understand their thought process and approach to different situations.",
  },
  {
    id: "cultural",
    name: "Cultural Fit",
    description: "Questions to assess your alignment with company values and work culture.",
    icon: "bi bi-people",
    config:
      "Focus on assessing cultural alignment and work style. Ask questions about preferred work environment, collaboration style, and values. Explore how the candidate approaches teamwork, handles conflicts, and aligns with company values. Include questions about diversity, inclusion, and workplace relationships. Assess their motivation and long-term career goals.",
  },
];

const selectType = () => {
  const selectedConfig = interviewTypes.find((type) => type.id === selectedType.value)?.config;
  emit("select", { type: selectedType.value, config: selectedConfig });
};

const emit = defineEmits(["close", "select"]);
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modal-dialog {
  max-width: 800px;
  width: 90%;
  margin: 1.75rem auto;
}

.modal-content {
  border: none;
  border-radius: 1rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.modal-header {
  border-bottom: 1px solid #eee;
  padding: 1.5rem;
}

.modal-body {
  padding: 1.5rem;
}

.modal-footer {
  border-top: 1px solid #eee;
  padding: 1.25rem 1.5rem;
}

.interview-types {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.interview-type-option {
  padding: 1.25rem;
  border: 2px solid #e9ecef;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
}

.interview-type-option:hover {
  border-color: #0d6efd;
  background: #f8f9fa;
  transform: translateY(-2px);
}

.interview-type-option.selected {
  border-color: #0d6efd;
  background: #e6f0ff;
  box-shadow: 0 4px 12px rgba(13, 110, 253, 0.1);
}

.type-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.75rem;
}

.icon-container {
  width: 40px;
  height: 40px;
  background: #e6f0ff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.type-header i {
  font-size: 1.25rem;
  color: #0d6efd;
}

.type-header h6 {
  margin: 0;
  font-weight: 600;
  font-size: 1.1rem;
  color: #2c3e50;
}

.type-description {
  margin: 0;
  font-size: 0.95rem;
  color: #6c757d;
  line-height: 1.5;
}

.duration-notice {
  margin: 0.5rem 0 0;
  font-size: 0.85rem;
  color: #6c757d;
  font-style: italic;
}

.btn {
  padding: 0.5rem 1.5rem;
  font-weight: 500;
}

.btn-primary {
  background: linear-gradient(to right, #0d6efd, #0a58ca);
  border: none;
  padding: 0.5rem 2rem;
}

.btn-primary:hover {
  background: linear-gradient(to right, #0a58ca, #084298);
}
</style>
