<template>
  <BreadcrumbNav class="mb-4" />

  <div class="interview-prep-container">
    <div v-if="!interview.company" class="text-center py-5">
      <div class="empty-state mb-4">
        <i class="bi bi-person-video display-1 text-muted"></i>
        <h2 class="mt-4 mb-2">No Interview Prep Generated Yet</h2>
        <p class="text-muted">
          Select generate to create your personalized interview preparation materials
        </p>
      </div>
      <button @click="generatePrep" class="btn btn-primary btn-lg" :disabled="loading">
        <span v-if="!loading">
          <i class="bi bi-magic me-2"></i>
          Generate Interview Prep
        </span>
        <div v-else class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
    <div v-else class="content-wrapper">
      <!-- Company Overview Card -->
      <div class="card main-card glass-morphism mb-4">
        <div class="company-header">
          <div class="company-title-wrapper">
            <h1 class="company-name">{{ interview.company }}</h1>
            <div class="position-badge">{{ interview.jobTitle }}</div>
          </div>
          <p class="company-description">{{ interview.companySummary }}</p>
        </div>
      </div>

      <!-- Key Responsibilities Card -->
      <div class="card glass-morphism mb-4">
        <div class="card-header">
          <div class="header-content">
            <i class="bi bi-check2-square text-primary"></i>
            <h2>Key Responsibilities</h2>
          </div>
        </div>
        <div class="card-body">
          <ul class="responsibilities-list">
            <li
              v-for="(item, index) in interview.keyResponsibilities"
              :key="index"
              class="responsibility-item"
            >
              <i class="bi bi-arrow-right-short text-primary"></i>
              {{ item.responsibility }}
            </li>
          </ul>
        </div>
      </div>

      <!-- Required Skills Card -->
      <div class="card glass-morphism mb-4">
        <div class="card-header clickable" @click="toggleSection('skills')">
          <div class="header-content">
            <i class="bi bi-trophy-fill text-primary"></i>
            <h2>Required Skills</h2>
          </div>
          <i :class="['bi', isExpanded.skills ? 'bi-chevron-up' : 'bi-chevron-down']"></i>
        </div>
        <div class="card-body" v-show="isExpanded.skills">
          <div class="skills-grid">
            <div
              v-for="(skill, index) in interview.skillsAndQualifications"
              :key="index"
              class="skill-item"
              :class="skill.required.toLowerCase()"
            >
              <i
                :class="[
                  'bi',
                  skill.required === 'Required'
                    ? 'bi-check-circle-fill text-success'
                    : 'bi-star-fill text-warning',
                ]"
              ></i>
              <span>{{ skill.item }}</span>
              <div class="badge" :class="skill.required.toLowerCase()">
                {{ skill.required }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Interview Questions Card -->
      <div class="card glass-morphism mb-4">
        <div class="card-header clickable" @click="toggleSection('questions')">
          <div class="header-content">
            <i class="bi bi-chat-left-text-fill text-primary"></i>
            <h2>Practice Questions</h2>
          </div>
          <i :class="['bi', isExpanded.questions ? 'bi-chevron-up' : 'bi-chevron-down']"></i>
        </div>
        <div class="card-body" v-show="isExpanded.questions">
          <div class="questions-accordion">
            <div
              v-for="questionGroup in interview.questionsAndAnswers"
              :key="questionGroup.type"
              class="category-section"
            >
              <div class="category-header clickable" @click="toggleCategory(questionGroup.type)">
                <h3>
                  {{ questionGroup.type }}
                  <span class="question-count">({{ questionGroup.questions.length }})</span>
                </h3>
                <i
                  :class="[
                    'bi',
                    expandedCategories[questionGroup.type] ? 'bi-chevron-up' : 'bi-chevron-down',
                  ]"
                ></i>
              </div>
              <div v-show="expandedCategories[questionGroup.type]" class="category-content">
                <div
                  v-for="(qa, index) in questionGroup.questions"
                  :key="index"
                  class="question-item"
                >
                  <div
                    class="question-header clickable"
                    @click="toggleQuestion(`${questionGroup.type}-${index}`)"
                  >
                    <h4>{{ qa.question }}</h4>
                    <i
                      :class="[
                        'bi',
                        questionStates[`${questionGroup.type}-${index}`]
                          ? 'bi-chevron-up'
                          : 'bi-chevron-down',
                      ]"
                    ></i>
                  </div>
                  <div
                    v-show="questionStates[`${questionGroup.type}-${index}`]"
                    class="answer-content"
                  >
                    <template v-if="qa.answer.isStarAnswer">
                      <div class="star-answer">
                        <div
                          v-for="(content, part) in qa.answer.starAnswer"
                          :key="part"
                          class="star-section"
                        >
                          <span class="star-label">{{ part }}</span>
                          <p>{{ content }}</p>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <p>{{ qa.answer.answer }}</p>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Questions for Interviewer Card -->
      <div class="card glass-morphism mb-4">
        <div class="card-header clickable" @click="toggleSection('interviewer')">
          <div class="header-content">
            <i class="bi bi-pencil-square text-primary"></i>
            <h2>Questions to Ask</h2>
          </div>
          <i :class="['bi', isExpanded.interviewer ? 'bi-chevron-up' : 'bi-chevron-down']"></i>
        </div>
        <div class="card-body" v-show="isExpanded.interviewer">
          <ul class="interviewer-questions-list">
            <li v-for="(question, index) in interview.questions" :key="index" class="question-item">
              <i class="bi bi-question-circle text-primary"></i>
              {{ question.question }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { ResponseApiService } from "@/services/ResponseApiService";
import type { InterviewPrepSchema } from "@models/interviewPrep";
import BreadcrumbNav from "@/components/BreadcrumbNav.vue";
import { toast } from "vue3-toastify";

// Initialize all sections as expanded by default
const isExpanded = ref({
  skills: true,
  questions: true,
  interviewer: true,
});

// Initialize expandedCategories as a reactive object
const expandedCategories = ref<{ [key: string]: boolean }>({});

// Initialize questionStates to track individual question expansion
const questionStates = ref<{ [key: string]: boolean }>({});

const route = useRoute();
const loading = ref(false);

// Compute required skills
const requiredSkills = computed(() => {
  return interview.value?.keyResponsibilities || [];
});

const interview = ref<InterviewPrepSchema>({
  company: "",
  jobTitle: "",
  companySummary: "",
  role: "",
  skillsAndQualifications: [],
  questionsAndAnswers: [],
  questions: [],
  keyResponsibilities: [], // Add this if it's in your schema
});

// Toggle functions
const toggleSection = (section: keyof typeof isExpanded.value) => {
  isExpanded.value[section] = !isExpanded.value[section];
};

const toggleCategory = (categoryName: string) => {
  expandedCategories.value[categoryName] = !expandedCategories.value[categoryName];
};

const toggleQuestion = (questionId: string) => {
  questionStates.value[questionId] = !questionStates.value[questionId];
};

// Rest of your existing code...
onMounted(async () => {
  loading.value = true;
  try {
    const applicationId = route.params.id as string;
    const response = await ResponseApiService.getInterviewPrepForApplication(applicationId);

    if (response.status === 200 && response.data) {
      interview.value = response.data;

      // Initialize category states
      if (response.data.questionsAndAnswers) {
        response.data.questionsAndAnswers.forEach((qa: any) => {
          const category = qa.category || "General";
          expandedCategories.value[category] = true;
          // Initialize question states
          questionStates.value[qa.id || qa.question] = false;
        });
      }
    }
  } catch (error) {
    console.error("Error fetching interview prep:", error);
    toast.error("Failed to load interview preparation materials");
  } finally {
    loading.value = false;
  }
});

async function generatePrep() {
  loading.value = true;
  try {
    const applicationId = route.params.id as string;
    const response = await ResponseApiService.generateInterviewPrep(applicationId);

    if (response.status === 200) {
      interview.value = response.data;
      toast.success("Interview prep materials generated successfully!");
    }
  } catch (error) {
    console.error("Error generating interview prep:", error);
    toast.error("Failed to generate interview preparation materials");
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped>
.interview-prep-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.card {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(226, 232, 240, 0.8);
  overflow: hidden;
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  background: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(226, 232, 240, 0.8);
}

.card-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.header-content i {
  font-size: 1.25rem;
}

.card-body {
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.7);
}

.company-header {
  padding: 2rem;
  background: linear-gradient(to right, #4f46e5, #6366f1);
  color: white;
}

.company-title-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.company-name {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
}

.position-badge {
  background: rgba(255, 255, 255, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.company-description {
  font-size: 1rem;
  opacity: 0.9;
  margin: 0;
  line-height: 1.6;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.skill-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0.5rem;
  border: 1px solid rgba(226, 232, 240, 0.8);
}

.skill-item i {
  font-size: 1.25rem;
}

.skill-item span {
  flex: 1;
  font-size: 0.875rem;
  color: #1e293b;
}

.badge {
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.badge.required {
  background: rgba(79, 70, 229, 0.1);
  color: #4f46e5;
}

.badge.preferred {
  background: rgba(245, 158, 11, 0.1);
  color: #f59e0b;
}

.questions-accordion {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.category-section {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0.5rem;
  border: 1px solid rgba(226, 232, 240, 0.8);
  overflow: hidden;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background: rgba(249, 250, 251, 0.8);
  cursor: pointer;
}

.category-header h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.question-count {
  color: #6b7280;
  font-size: 0.875rem;
  margin-left: 0.5rem;
}

.category-content {
  padding: 1rem 1.5rem;
}

.question-item {
  border-bottom: 1px solid rgba(226, 232, 240, 0.8);
  padding: 1rem 0;
}

.question-item:last-child {
  border-bottom: none;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.question-header h4 {
  font-size: 0.875rem;
  font-weight: 500;
  color: #1e293b;
  margin: 0;
}

.answer-content {
  padding: 1rem 0;
  color: #4b5563;
  font-size: 0.875rem;
  line-height: 1.6;
}

.star-answer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.star-section {
  background: rgba(249, 250, 251, 0.8);
  padding: 1rem;
  border-radius: 0.5rem;
}

.star-label {
  display: block;
  font-weight: 600;
  color: #4f46e5;
  margin-bottom: 0.5rem;
}

.interviewer-questions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.interviewer-questions-list li {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0.5rem;
  border: 1px solid rgba(226, 232, 240, 0.8);
}

.interviewer-questions-list i {
  color: #4f46e5;
  font-size: 1.25rem;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #6b7280;
}

.clickable {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickable:hover {
  background-color: rgba(249, 250, 251, 0.9);
}

/* Animations */
.card-body {
  transition: all 0.3s ease;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.category-content,
.answer-content {
  animation: slideDown 0.3s ease;
}

/* Glass morphism effect */
.glass-morphism {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
.responsibility-item {
  list-style: none;
}
</style>
