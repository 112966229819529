import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";
import Document from "@models/Document";
import DocumentDto from "@models/dtos/DocumentDto";
import { Resume } from "@models/Resume";
export class DocumentApiService {
  public static async getUserDocumentsList(): Promise<AxiosResponse<DocumentDto[]>> {
    const axios = await instance();
    const response = await axios.get("/getUserDocumentsList");
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
  public static async uploadDocument(file: FormData): Promise<AxiosResponse<Document>> {
    const axios = await instance();
    const response = await axios.post("/uploadDocumentText", file);
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
  public static async delete(documentId: string): Promise<AxiosResponse> {
    const axios = await instance();
    const response = await axios.delete("/deleteDocument", { data: { id: documentId } });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  //Create a new method to get the user resume
  //  getUserResume = this.asyncRouteHandler(async (req: e.Request, res: e.Response): Promise<void> => {
  //   const loggedInUserId = AuthUtils.getUserIdFromJwt(req.headers.authorization!)
  //   const resume = await this.documentService.getUserResume(loggedInUserId)
  //   res.status(200).send(resume)
  // })

  public static async getUserResume(userId: string): Promise<AxiosResponse<Resume>> {
    const axios = await instance();
    const response = await axios.get(`/getUserResume`);
    return response;
  }

  public static async updateUserResume(resume: Resume): Promise<AxiosResponse<Resume>> {
    const axios = await instance();
    const response = await axios.put("/updateUserResume", resume);
    return response;
  }
}
